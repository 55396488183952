import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { withTranslation } from "react-i18next";
import withRouter from "../Common/withRouter";
import { ToastContainer } from "react-toastify";
import SelectStyle from "../../common/data/SelectStyle";
import ReactSelect from "react-select";
import { BaseCurrencyContext } from "../../common/data/context";
import { useLocation } from "react-router-dom";
import { getApiData } from "../../helpers/axiosHelper";
import CustomLoader from "../Common/Spinner";

const SelectCurrency = () => {
  const [currencyIcons, setCurrencyIcons] = useState({});

  const {
    setCurrencyData,
    setSelectedContextCurrency,
    currencyOptions,
    setCurrencyOptions,
    transferCurrencies,
    setTransferCurrencies,
  } = useContext(BaseCurrencyContext);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const pathName = useLocation();
  const currentpath = pathName.pathname;
  const selectRef = useRef(null); // Reference to the ReactSelect container
  const [isLoading, setIsLoading] = useState(true);
  // This will store the tooltip position dynamically
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const fetchCurrenciesForTransfer = async () => {
      // try {
      //   const [currenciesResponse, contextCurrencyResponse] = await Promise.all(
      //     [
      //       getApiData("api/RateMaster/GetAllCurrencies"),
      //       getApiData("api/RateMaster/GetGlobalTransferRate"),
      //     ]
      //   );

      //   const icons = currenciesResponse.data.reduce((acc, currency) => {
      //     acc[
      //       currency.currencyCode.trim()
      //     ] = `data:image/png;base64,${currency.currencyFlag}`;
      //     return acc;
      //   }, {});
      //   let uniqueOptions = [];
      //   if (
      //     contextCurrencyResponse.data &&
      //     contextCurrencyResponse.data.length > 0
      //   ) {
      //     // Filter out any currencies where buyRateMainBranch is null or an empty string
      //     const validCurrencies = contextCurrencyResponse.data.filter(
      //       (currency) =>
      //         currency.buyRateMainBranch !== null &&
      //         currency.buyRateMainBranch !== ""
      //     );

      //     uniqueOptions = Array.from(
      //       new Map(
      //         validCurrencies.map((currency) => [
      //           currency.currencyCode.trim(),
      //           {
      //             value: currency.currencyCode.trim(),
      //             label: currency.currencyName,
      //           },
      //         ])
      //       ).values()
      //     );
      //   }
      //   // If there are no context currencies, manually add SGD
      //   if (uniqueOptions.length === 0) {
      //     uniqueOptions = [
      //       {
      //         value: "SGD",
      //         label: "Singapore Dollar", // You can change this label as needed
      //       },
      //     ];
      //   }
      //   setCurrencyIcons(icons);
      //   setCurrencyOptions(uniqueOptions);

      //   const sgdOption = uniqueOptions.find((opt) => opt.value === "SGD");
      //   setSelectedCurrency(sgdOption || uniqueOptions[0]);
      //   setSelectedContextCurrency(sgdOption || uniqueOptions[0]);
      //   setCurrencyData(sgdOption || uniqueOptions[0]);
      // } catch (error) {
      //   console.error("Error fetching currencies", error);
      // }
      try {
        setIsLoading(true);
        const [currenciesResponse, transferCurrenciesResponse] =
          await Promise.all([
            getApiData("api/RateMaster/GetAllCurrencies"),
            getApiData("api/RateMaster/GetGlobalTransferRate"),
          ]);

        const icons = currenciesResponse.data.reduce(
          (acc, { currencyCode, currencyFlag }) => {
            acc[currencyCode.trim()] = `data:image/png;base64,${currencyFlag}`;
            return acc;
          },
          {}
        );
        if (transferCurrenciesResponse.success === true) {
          const transferCurrency = Array.from(
            new Map(
              transferCurrenciesResponse.data.map(
                ({ currencyCode, currencyName }) => {
                  const [currencyNameShort] = currencyName.split("(");
                  return [
                    currencyCode.trim(),
                    {
                      value: currencyCode.trim(),
                      label: currencyNameShort.trim(),
                      currencyName: currencyNameShort.trim(),
                    },
                  ];
                }
              )
            ).values()
          );
          setTransferCurrencies(transferCurrency);
          const options = Array.from(
            new Map(
              transferCurrenciesResponse.data
                .filter(
                  ({ currencyCode, buyRateMainBranch }) =>
                    currencyCode.trim() !== "SGD" &&
                    buyRateMainBranch !== null &&
                    buyRateMainBranch !== ""
                )
                .map(({ currencyCode, currencyName }) => {
                  const [currencyNameShort] = currencyName.split("(");
                  return [
                    currencyCode.trim(),
                    {
                      value: currencyCode.trim(),
                      label: currencyNameShort.trim(),
                      currencyName: currencyNameShort.trim(),
                    },
                  ];
                })
            ).values()
          );

          options.unshift({
            value: "SGD",
            label: "Singapore Dollar",
            currencyName: "Singapore Dollar",
          });
          setCurrencyIcons(icons);
          setCurrencyOptions(options);
        } else {
          const options = [
            {
              value: "SGD",
              label: "Singapore Dollar",
              currencyName: "Singapore Dollar",
            },
          ];
          setTransferCurrencies(options);
          setCurrencyIcons(icons);
          setCurrencyOptions(options);
        }

        const defaultOption = { value: "SGD", label: "SGD" };
        setSelectedCurrency(defaultOption);
        setSelectedContextCurrency(defaultOption);
        setCurrencyData({
          ...defaultOption,
          currencyName: "Singapore Dollar",
        });
      } catch (error) {
        console.error("Error fetching currencies", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrenciesForTransfer();
  }, [setCurrencyData]);

  const handleCurrencySelect = (option) => {
    if (option.value !== "SGD") {
      Swal.fire({
        text:
          "You are attemping to change Currency, all the conversions will now be based on " +
          `${option.value}` +
          " currency and you will be able to do the Transfer Operation only. Do you wish to proceed?",
        title: "warning",
        icon: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setSelectedCurrency(option);
          setSelectedContextCurrency(option);
          setCurrencyData(option);
        } else {
          return;
        }
      });
    } else {
      setSelectedCurrency(option);
      setSelectedContextCurrency(option);
      setCurrencyData(option);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="d-flex" title="Select Your Currency Here">
        <div>
          {isLoading ? (
            <CustomLoader />
          ) : currentpath === "/agentdashboard" ||
            currentpath === "/Add-Transaction" ? (
            <ReactSelect
              value={selectedCurrency}
              options={currencyOptions}
              onChange={handleCurrencySelect}
              styles={{
                ...SelectStyle,
                control: (provided) => ({
                  ...provided,
                  width: "100%", // Use full width to make it responsive
                  maxWidth: "220px", // Limit max width for consistency
                  minWidth: "75px",
                  position: "relative", // Ensure proper placement
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1050, // Ensure dropdown appears above other elements
                  marginTop: "2px", // Add space between input and dropdown
                }),
              }}
              formatOptionLabel={({ value, label }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={currencyIcons[value]}
                    alt={label}
                    style={{ width: "20px", marginRight: "8px" }}
                  />
                  {value}
                </div>
              )}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
SelectCurrency.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SelectCurrency));
