import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../../images/logo.svg";
import logoLight from "../../../images/logo-light.png";
import logoLightSvg from "../../../images/logo-light.svg";
import logoDark from "../../../images/logo-dark.png";
import { withTranslation } from "react-i18next";
import SelectBranch from "./SelectBranch";
import SelectCurrency from "./SelectCurrency";
import { useLocation } from "react-router-dom";

const Header = (props) => {
  const pathName = useLocation();
  const currentpath = pathName.pathname;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isLeftMenuOpen = props.leftMenu;
      const isMobileView = window.innerWidth <= 991;
      const isInsideMenu = event.target.closest("#topnav-menu-content");
      const isMenuButton = event.target.closest(".header-item");

      if (isLeftMenuOpen && isMobileView && !isInsideMenu && !isMenuButton) {
        props.toggleLeftmenu(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [props.leftMenu]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                {/* <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span> */}
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                {/* <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span> */}
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="19" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
              aria-expanded={props.leftMenu ? "true" : "false"}
              aria-controls="topnav-menu-content"
              onClick={() => props.toggleLeftmenu(!props.leftMenu)}
            >
              <i
                className={`fa fa-fw ${
                  props.leftMenu ? "fa-times" : "fa-bars"
                }`}
              />
            </button>
          </div>

          <div className="d-flex">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <SelectCurrency />
                {/* {currentpath === "/agentdashboard" ||
                currentpath === "/Add-Transaction" ? (
                  <SelectCurrency />
                ) : null} */}
              </div>
              <div className="me-3">
                {currentpath === "/branch" ||
                currentpath === "/Manage-Assignment" ||
                currentpath === "/Manage-Beneficiary" ? null : (
                  <>
                    <SelectBranch />
                  </>
                )}
              </div>
              <ProfileMenu />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
