import React, { useEffect, useState, useMemo } from "react";
import { Badge, Button, Card, CardBody, Row, Col, Container } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faDownload, faDollar } from "@fortawesome/free-solid-svg-icons";
import { getApiData } from "../../helpers/axiosHelper";
import {
  Date,
  Branch,
  CurrencyType,
  CurrencyValue,
  AgentName,
  CompanyName,
  TransactionID,
} from "../Dashboard_Agent/Transaction/LatestTransactionCol";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { mapStatus } from "../../common/data/statusLabels";
import ViewLatestTransactionModel from "../Dashboard_Agent/Transaction/ViewLatestTransactionModel";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import ReceiptModal from "../Dashboard_Agent/Transaction/Receipt";
import Help from "../../components/Common/Help";
import { formatDateToUserTimeZone } from "../../common/date";

const ManageAssignments = ({ orders, onGetOrders }) => {
  const [modal1, setModal1] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [commentData, setCommentData] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [transactionID, setTransactionID] = useState("");
  const [fromBranchId, setFromBranchID] = useState("");
  const [branchData, setBranchData] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
  const [optime, setOptime] = useState([]);
  const { branchId } = useLatestTransactions();
  const [showModal, setShowModal] = useState(false);
  const [receiptData, setReceiptData] = useState({});
  const [triggerDownload, setTriggerDownload] = useState(false);
  //   const [agentBranchId, setAgentBranchId] = useState(null);
  const [currencyFlags, setCurrencyFlags] = useState({});
  const [isBranchActive, setIsBranchActive] = useState(true);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [buyRateforCharges, setBuyRateForCharges] = useState(null);

  const getTransaction = async () => {
    // setAgentBranchId(branchId);
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/Transaction/GetTransactionOfLoggedInUser`
      );
      console.log("Response from API");
      console.log(response);

      setIsLoading(false);

      const mapStatus = (status) =>
        statusLabels[status] || { label: "Unknown", color: "secondary" };

      const statusLabels = {
        1: { label: "Pending", color: "warning" },
        2: { label: "Waiting For Requestor", color: "primary" },
        3: { label: "Waiting For Approver", color: "primary" },
        4: { label: "Approved", color: "success" },
        5: { label: "Declined", color: "danger" },
        6: { label: "User Declined", color: "danger" },
        7: { label: "Cancelled", color: "danger" },
        8: { label: "User Acknowledged", color: "info" },
        9: { label: "User Cancelled", color: "info" },
        10: { label: "Waiting For Payment", color: "info" },
        11: { label: "Payment Received", color: "info" },
        12: { label: "Waiting For Collection", color: "info" },
        13: { label: "Cash Collected", color: "info" },
      };

      // const mappedResponse = response.data.map((item, index) => ({
      //   transactionId: item.transactionId,
      //   fromBranchId: item.fromBranchId,
      //   companyName: item.companyName,
      //   index: index + 1,
      //   name: item.name,
      //   branch: item.branchName,
      //   transactionType: item.transactionType,
      //   date: formatDateToUserTimeZone(item.transactionDate),
      //   fromCurrType: item.fromCurrType,
      //   fromCurrValue: item.fromCurrValue,
      //   status: mapStatus(item.status),
      // }));

      const mappedResponse = response.data.map((item, index) => ({
        index: index + 1,
        agentName: item.name,
        agentBranch: item.branchName,
        status: mapStatus(item.status),
        currencyType: item.fromCurrType,
        currencyValue: item.fromCurrValue,
        toCurrType: item.toCurrType,
        toCurrValue: item.toCurrValue,
        transactionID: item.transactionId,
        transactionDate: formatDateToUserTimeZone(item.transactionDate),
        transactionId: item.transactionId,
        fromBranchId: item.fromBranchId,
        companyName: item.companyName,
        transactionId: item.transactionId,
        fromBranchId: item.fromBranchId,
        companyName: item.companyName,
        index: index + 1,
        name: item.name,
        branch: item.branchName,
        transactionType: item.transactionType,
        iDandBranch: item.transactionId + "-" + item.branchName,
        date: formatDateToUserTimeZone(item.transactionDate),
        fromCurrType: item.fromCurrType,
        toCurrType: item.toCurrType,
        fromCurrValue: parseFloat(Number(item.fromCurrValue).toFixed(2)),
        toCurrValue: parseFloat(Number(item.toCurrValue).toFixed(2)),
        status: mapStatus(item.status),
        convRate: parseFloat(Number(item.exchangeRate).toFixed(8)),
        pickupDate:
          item.pickup !== null
            ? formatDateToUserTimeZone(item?.pickup)
            : "Not mentioned",
        transactingCurrency: item.toCurrType,
      }));

      setTransactionData(mappedResponse);
      console.log("Mapped Response");

      console.log(mappedResponse);
    } catch (error) {
      console.log(error);
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const fetchCurrencyFlags = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      const flags = {};
      response.data.forEach((currency) => {
        flags[currency.currencyCode.trim()] = currency.currencyFlag;
      });
      setCurrencyFlags(flags);
    } catch (error) {
      console.error("Failed to load currency flags", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };
  const transactionTypeMap = {
    2: { label: "Buy", color: "success" },
    1: { label: "Sell", color: "danger" },
    3: { label: "Transfer", color: "primary" },
  };

  const handleShow = (data, download) => {
    getTransactionbyId(data.transactionID, data.fromBranchId);
    setReceiptData(data);
    setTriggerDownload(download);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setTriggerDownload(false);
  };
  useEffect(() => {
    fetchCurrencyFlags();
    getTransaction();
  }, []);

  const Days = {
    0: "All Days",
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const meridiem = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${meridiem}`;
  };

  const ViewTransactionDetails = (cellProps) => {
    const { transactionId, fromBranchId } = cellProps;
    setTransactionID(transactionId);
    setFromBranchID(fromBranchId);
    getTransactionbyId(transactionId, fromBranchId);
    toggleViewModal();
  };

  const getTransactionbyId = async (transactionId, fromBranchId) => {
    try {
      // Fetch transaction and branch data in parallel if they are independent
      const [response, branchResponse] = await Promise.all([
        getApiData(
          `api/Transaction/GetTransaction?TransactionID=${transactionId}`
        ),
        getApiData(
          `api/BranchMaster/GetBranchWithOperation?branchId=${fromBranchId}`
        ),
      ]);

      let rateHistoryId = "";

      // Determine the rateHistoryId based on transaction type
      if (response.transactionType === 2 && response.toRateHistoryId) {
        rateHistoryId = response.toRateHistoryId;
      } else if (response.transactionType === 1 && response.fromRateHistoryId) {
        rateHistoryId = response.fromRateHistoryId;
      }

      // Fetch charges based on the transaction type
      let chargesResponse = null;
      if (response.transactionType === 1 || response.transactionType === 2) {
        chargesResponse = await getApiData(
          `api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`
        );
        setChargesDetails(chargesResponse.data);
      } else if (response.transactionType === 3) {
        const transferCharges = response.toRateHistoryId
          ? await getApiData(
              `api/RateMaster/GetTransactionCharges?rateHistoryId=${response.toRateHistoryId}`
            )
          : await getApiData(
              `api/RateMaster/GetTransactionCharges?rateHistoryId=${response.fromRateHistoryId}`
            );

        setChargesDetails(transferCharges.data);

        if (response.fromCurrType !== "SGD") {
          const rateResponse = await getApiData(
            `api/RateMaster/GetTransactionRate?fromCurr=${response.fromCurrType}`
          );
          setBuyRateForCharges(rateResponse.data.buyRate);
        } else {
          setBuyRateForCharges(null);
        }
      } else {
        setChargesDetails(""); // Handle default case
      }

      // Check if branch is active
      const isBranchActive = branchResponse.data.branch.active === 1;
      setIsBranchActive(isBranchActive);

      // Map operational times to 12-hour format
      const mappedResponse = branchResponse.data.operationalTime.map((item) => {
        const startTime12 = convertTo12HourFormat(item.startTime);
        const endTime12 = convertTo12HourFormat(item.endTime);
        return {
          day: Days[item.day],
          startTime: startTime12,
          endTime: endTime12,
        };
      });

      // Set the state for the branch and transaction details
      setBranchData(branchResponse.data.branch);
      setOptime(mappedResponse);
      setSendTransactiondetails(response);
      setCommentData(response.comments);
      setDocumentData(response.transactionDocs);
      setBeneficiaryDetails(response.beneficiaryDetails);
    } catch (error) {
      console.error("Error fetching transaction data:", error.message);
      // Optional: Add a user-friendly notification here (e.g., Toast)
    }
  };

  const reload = () => {
    getTransaction();
  };

  const toggleViewModal = () => setModal1(!modal1);
  const columns = useMemo(
    () => [
      {
        Header: "Id-Branch",
        accessor: "iDandBranch",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <TransactionID {...cellProps} />;
        },
      },

      {
        Header: "Currency",
        accessor: "transactingCurrency",
        disableFilters: true,
        Cell: (cellProps) => {
          const { transactionType, fromCurrType, toCurrType } =
            cellProps.row.original;
          const transactingCurrency =
            transactionType === 1 ? fromCurrType : toCurrType;
          const { label, color } = transactionTypeMap[transactionType] || {};
          const flagIcon1 = currencyFlags[fromCurrType.trim()];
          const flagIcon2 = currencyFlags[transactingCurrency.trim()];

          return (
            <div>
              {flagIcon1 ? (
                <img
                  src={`data:image/png;base64,${flagIcon1}`}
                  alt=""
                  style={{ width: "20px", marginLeft: "5px" }}
                />
              ) : (
                <FontAwesomeIcon icon={faDollar} />
              )}
              <span>{` ${fromCurrType}`}</span>
              <Badge className={`font-size-11 badge-soft-${color}`}>
                {label}
              </Badge>
              {flagIcon2 ? (
                <img
                  src={`data:image/png;base64,${flagIcon2}`}
                  alt=""
                  style={{ width: "20px", marginLeft: "5px" }}
                />
              ) : (
                <FontAwesomeIcon icon={faDollar} />
              )}
              <span>{` ${toCurrType}`}</span>
            </div>
          );
        },
      },
      {
        Header: "From Amount",
        accessor: "toCurrValue",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { fromCurrValue, fromCurrType } = cellProps.row.original;

          return (
            <span>
              {fromCurrValue} {fromCurrType}
            </span>
          );
        },
      },
      {
        Header: "Rate",
        accessor: "convRate",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Converted Amount",
        accessor: "fromCurrValue",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { toCurrValue, toCurrType } = cellProps.row.original;
          return (
            <span>
              {toCurrValue} {toCurrType}
            </span>
          );
        },
      },
      {
        Header: "Booked Date",
        accessor: "date",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Pickup Date",
        accessor: "pickupDate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: true,

        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" + cellProps.row.original.status.color
              }
            >
              {cellProps.row.original.status.label}
            </Badge>
          );
        },
      },
      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",
        Cell: (cellProps) => {
          const isDownloadable =
            cellProps.row.original.status.label === mapStatus(13).label;
          const {
            transactionID,
            transactionDate,
            currencyType,
            agentName,
            currencyValue,
            toCurrType,
            toCurrValue,
            agentBranch,
            convRate,
            transactionType,
            fromBranchId,
          } = cellProps.row.original;

          const receiptData = {
            transactionID,
            transactionDate,
            agentName,
            currencyType,
            agentBranch,
            currencyValue,
            toCurrType,
            toCurrValue,
            convRate,
            transactionType,
            fromBranchId,
          };
          return (
            <div className="d-flex align-items-center">
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded ms-4"
                onClick={() => {
                  setEditMode(true);
                  ViewTransactionDetails(cellProps.row.original);
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
              {isDownloadable && (
                <Button
                  type="button"
                  style={{ backgroundColor: "#34c38f", border: "none" }} // Corrected syntax
                  className="btn-sm btn-rounded me-2 ms-2"
                  onClick={() => handleShow(receiptData, true)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [currencyFlags]
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content" style={{ marginTop: "60px" }}>
        <Container fluid>
          <ViewLatestTransactionModel
            optime={optime}
            isOpen={modal1}
            branchID={fromBranchId}
            editMode={editMode}
            branchData={branchData}
            toggle={toggleViewModal}
            commentData={commentData}
            transactionID={transactionID}
            sendTransactionDetails={sendTransactionDetails}
            reload={reload}
            showAction={true}
            isBranchActive={isBranchActive}
            beneficiaryDetails={beneficiaryDetails}
            chargesDetails={chargesDetails}
            buyRateForCharges={buyRateforCharges}
            documentData={documentData}
          />
          <ReceiptModal
            show={showModal}
            handleClose={handleClose}
            receiptData={receiptData}
            triggerDownload={triggerDownload}
            chargesDetails={chargesDetails}
            buyRate={buyRateforCharges}
          />
          {isLoading ? (
            <Loader />
          ) : (
            <Row>
              <Col>
                <Card>
                  {transactionData.length > 0 ? (
                    <CardBody style={{ overflowX: "auto" }}>
                      <h4 className="card-title mb-4 d-flex justify-content-between">
                        My Transactions
                        <Help to={"HELP DOCUMENTATION FOR MY ASSIGNMENTS"} />
                      </h4>
                      <div
                        className="table-container"
                        style={{ padding: "1rem" }}
                      >
                        <TableContainer
                          columns={columns}
                          customPageSize={10 || transactionData.length}
                          data={transactionData}
                          isAddOptions={false}
                          isPageSelect={false}
                          isGlobalFilter={false}
                        />
                      </div>
                    </CardBody>
                  ) : (
                    <div
                      className="text-muted d-flex justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <h3>No Record's to Show </h3>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageAssignments;
