import React, { useMemo, useState, useEffect } from "react";
import { Card, Button, CardBody, Container, Col } from "reactstrap";
import BeneficiaryModal from "./BeneficiaryModal";
import Loader from "../../components/Common/Loader";
import { deleteApiData, getApiData } from "../../helpers/axiosHelper";
import { toast, ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableContainer from "../../components/Common/TableContainer";
import {
  faEye,
  faEdit,
  faCheck,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import Help from "../../components/Common/Help";
import { formatDateToUserTimeZone } from "../../common/date";

const ManageBeneficiary = () => {
  document.title = "Crescent Exchange | Beneficiary";

  const [modal1, setModal1] = useState(false);
  const [editMode, setEditMode] = useState(0);
  const [companyBenId, setCompanyBenId] = useState("");
  const [custRefId, setCustRefId] = useState("");
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [sendBeneficiaryDetails, setSendBeneficiaryDetails] = useState(null);
  const [sendBeneficiaryAddress, setSendBeneficiaryAddress] = useState(null);
  const [currencyFlags, setCurrencyFlags] = useState({});

  const toggleAddModal = () => setModal1(!modal1);

  const isActive = {
    1: "active",
    0: "inActive",
  };

  const status = {
    0: "draft",
    1: "inComplete",
    2: "complete",
  };

  const accType = {
    1: "personal",
    2: "bussiness",
  };

  // Fetching currency flags
  const fetchAllCurrencies = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      const flags = {};
      response.data.forEach((currency) => {
        flags[currency.currencyName.trim()] = currency.currencyFlag;
      });
      setCurrencyFlags(flags);
      const mappedResponse = response.data.map((item, index) => ({
        index: index + 1,

        currencyId: item.currencyId,
        currencyName: item.currencyName,
        currencyCode: item.currencyCode,
      }));
      setCurrency(mappedResponse);
    } catch (error) {
      toast.error("Failed to load currency flags", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const fetchAllBeneficiary = async () => {
    try {
      setLoading(true);
      const response = await getApiData(
        `api/AgentBeneficiary/GetAllByAgentBeneficiary`
      );

      const mappedResponse = response.data.map((item, index) => ({
        index: index + 1,
        swift: item.swift,
        other: item.other,
        email: item.email,
        mobile: item.mobile,
        bankName: item.bankName,
        companyBenId: item.companyBenId,
        // custRefId: item.custRefId,
        status: status[item.status],
        nationalId: item.nationalId,
        currencyId: item.currencyId !== null ? item.currencyId : "Not selected",
        dob: item.dob,
        bankAddress: item.bankAddress,
        active: isActive[item.active],
        // isMyAccount: item.isMyAccount,
        currencyName:
          item?.currencyName !== null ? item.currencyName : "Not selected",
        accountNumber: item.accountNumber,
        beneficiaryName: item.beneficiaryName,
        accountType: accType[item.accountType],
      }));
      console.log(mappedResponse);
      setBeneficiaryData(mappedResponse);
      setLoading(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const getDocument = async (companyBenId) => {
    const docResponse = await getApiData(
      `api/AgentBeneficiary/GetBeneficiaryDocByCompanyBenId?CompanyBenId=${companyBenId}`
    ).catch(() => {});
    const document = await docResponse;
    const documents = (document && document.data) || [];
    setDocumentData(documents);
  };

  const ViewBeneficiaryDetails = async (cellProps) => {
    const { companyBenId } = cellProps;
    setCompanyBenId(companyBenId);
    // setCustRefId(custRefId);

    try {
      const benResponse = await getApiData(
        `api/AgentBeneficiary/GetBeneficiaryById?BeneficiaryId=${companyBenId}`
      );
      const processedData = {
        ...benResponse.data,
        dob: benResponse.data.dob.split("T")[0],
      };
      setSendBeneficiaryDetails(processedData);

      const addResponse = {
        street: benResponse.data.street,
        city: benResponse.data.city,
        state: benResponse.data.state,
        country: benResponse.data.country,
        postalCode: benResponse.data.postalCode,
        nationality: benResponse.data.nationality,
        addressId: benResponse.data.addressId,
      };

      setSendBeneficiaryAddress(addResponse);

      getDocument(companyBenId);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
    toggleAddModal();
  };

  const BenAct_Deact = async (cellProps, active) => {
    const { companyBenId } = cellProps;
    setCompanyBenId(companyBenId);
    try {
      await deleteApiData(
        `api/AgentBeneficiary/Delete?beneficiaryId=${companyBenId}&active=${active}`
      );
      toast.success(
        `Beneficiary ${active ? "activated" : "deactivated"} successfully`,
        {
          position: "top-right",
          autoClose: 3000,
        }
      );
      fetchAllBeneficiary();
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "beneficaryName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { beneficiaryName, isMyAccount } = cellProps.row.original;
          return (
            <div>
              {beneficiaryName}
              {isMyAccount === true && (
                <span className="badge bg-success ms-2">Own</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const status = cellProps.value || "";
          let badgeColor = "";

          switch (status) {
            case "draft":
              badgeColor = "bg-primary";
              break;
            case "inComplete":
              badgeColor = "bg-danger";
              break;
            case "complete":
              badgeColor = "bg-success";
              break;
            default:
              badgeColor = "bg-secondary";
              break;
          }

          return (
            <div>
              <span className={`badge ${badgeColor}`}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </span>
            </div>
          );
        },
      },
      {
        Header: "Currency",
        accessor: "currencyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const currencyName = cellProps.value; // Get the currency name from the cell
          const flagImage = currencyFlags[currencyName]; // Get the flag image using currencyName

          return (
            <div className="d-flex align-items-center">
              {flagImage && (
                <img
                  src={`data:image/png;base64,${flagImage}`} // Use base64 string if stored in this format
                  alt={currencyName} // Use currency name for accessibility
                  style={{ width: 20, height: 20, marginRight: 5 }}
                />
              )}
              <span>{currencyName}</span>
            </div>
          );
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "action",
        Cell: (cellProps) => {
          const { active } = cellProps.row.original;

          return (
            <>
              {active === "inActive" ? (
                <Button
                  type="button"
                  color="success"
                  className="btn-sm btn-rounded me-2 ms-3"
                  onClick={(e) => {
                    e.preventDefault();
                    BenAct_Deact(cellProps.row.original, true);
                  }}
                >
                  Activate <FontAwesomeIcon className="ms-2" icon={faCheck} />
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded me-2 ms-2"
                    onClick={(e) => {
                      setEditMode(1);
                      e.preventDefault();
                      ViewBeneficiaryDetails(cellProps.row.original);
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                  <Button
                    type="button"
                    color="success"
                    className="btn-sm btn-rounded me-2"
                    onClick={(e) => {
                      setEditMode(2);
                      e.preventDefault();
                      ViewBeneficiaryDetails(cellProps.row.original);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                  <Button
                    type="button"
                    color="danger"
                    className="btn-sm btn-rounded"
                    onClick={(e) => {
                      e.preventDefault();
                      BenAct_Deact(cellProps.row.original, false);
                    }}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetchAllBeneficiary();
    fetchAllCurrencies();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container>
          {/* <Breadcrumbs title="Beneficiary" breadcrumbItem="Beneficiary" /> */}
          <BeneficiaryModal
            isOpen={modal1}
            editMode={editMode}
            toggle={toggleAddModal}
            documentData={documentData}
            sendBeneficiaryDetails={sendBeneficiaryDetails}
            sendBeneficiaryAddress={sendBeneficiaryAddress}
            fetchAllBeneficiary={fetchAllBeneficiary}
            getDocument={getDocument}
            currencies={currency}
          />
          {loading ? (
            <Loader />
          ) : (
            <Card>
              <CardBody>
                <Col>
                  <h4 className="card-title mb-4 d-flex justify-content-between">
                    Beneficiary Details
                    <Help to={"HELP DOCUMENTATION FOR BENEFICIARY"} />
                  </h4>
                </Col>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <button
                    className="btn btn-primary text-end"
                    onClick={() => {
                      setSendBeneficiaryDetails(null);
                      setSendBeneficiaryAddress(null);
                      setDocumentData([]);
                      setEditMode(3);
                      toggleAddModal();
                    }}
                  >
                    + Add Beneficiary
                  </button>
                </div>
                {beneficiaryData.length > 0 ? (
                  <TableContainer
                    data={beneficiaryData}
                    results={false}
                    columns={columns}
                    customPageSize={10}
                    isAddOptions={false}
                    isPageSelect={false}
                    isGlobalFilter={false}
                  />
                ) : (
                  <div
                    className="text-muted d-flex justify-content-center align-items-center"
                    style={{ minHeight: "100px" }}
                  >
                    <h3>No Beneficiaries to Show</h3>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageBeneficiary;
