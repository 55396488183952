import Swal from "sweetalert2";
import ReactSelect from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col, Label, Input } from "reactstrap";
import BeneficiaryModal from "../Beneficiary/BeneficiaryModal";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { SelectStyleForMenu } from "../../common/data/SelectStyleForMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { BaseCurrencyContext } from "../../common/data/context";
import {
  faMinus,
  faEquals,
  faXmark,
  faDivide,
} from "@fortawesome/free-solid-svg-icons";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";

const TransferForm = ({
  branchID,
  transferCurrency,
  baseCurrency,
  globalTransferData,
  closeModal,
}) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [currency, setCurrency] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [modal1, setModal1] = useState(false);
  const toggleAddModal = () => setModal1(!modal1);
  const { currencyData } = useContext(BaseCurrencyContext);

  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

  const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);
  const [
    fromCurrencyValueTransferWithDecimals,
    setFromCurrencyValueTransferWithDecimals,
  ] = useState(0);
  const [selectedFromCurrencyTransfer, setSelectedFromCurrencyTransfer] =
    useState([]);
  const { branchId } = useLatestTransactions();

  const toCurrencyTransfer = transferCurrency;
  const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState("");
  const [
    toCurrencyValueTransferWithDecimals,
    setToCurrencyValueTransferWithDecimals,
  ] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buyRate, setBuyRate] = useState(null);
  const fromCurrencyTransfer = currencyData?.value;
  const [totalSGD, setTotalSGD] = useState("");

  const transferData = globalTransferData?.find((x) => {
    const targetCurrency =
      toCurrencyTransfer !== "SGD"
        ? toCurrencyTransfer
        : selectedFromCurrencyTransfer?.value || fromCurrencyTransfer;

    return x.currencyCode === targetCurrency;

    // map ratehistory of transfer currency
  });

  const getDocument = async (companyBenId) => {
    try {
      const docResponse = await getApiData(
        `api/AgentBeneficiary/GetBeneficiaryDocByCompanyBenId?CompanyBenId=${companyBenId}`
      ).catch(() => {});
      const document = await docResponse;
      const documents = (document && document.data) || [];
      setDocumentData(documents);
    } catch (error) {
      console.error("Error fetching document data:", error);
    }
  };

  const transferRate = transferData ? parseFloat(transferData.rate) : 0;

  const calculateFee = (
    fee,
    fromCurrency,
    toCurrency,
    transferRate,
    buyRate
  ) => {
    if (fromCurrency === "SGD") {
      return fee;
    } else if (toCurrency === "SGD") {
      return fee;
    } else {
      return fee * buyRate;
    }
  };

  let wireFee = parseFloat(transferData ? transferData.wireTransferFee : 0);
  let commissionFee = parseFloat(
    transferData ? transferData.commissionCharges : 0
  );
  let agentCharges = parseFloat(transferData ? transferData.agentCharges : 0);

  const convertedWireFee = calculateFee(
    wireFee,
    fromCurrencyTransfer?.value,
    toCurrencyTransfer,
    transferRate,
    buyRate
  );
  const convertedCommissionFee = calculateFee(
    commissionFee,
    fromCurrencyTransfer?.value,
    toCurrencyTransfer,
    transferRate,
    buyRate
  );
  const convertedAgentCharges = calculateFee(
    agentCharges,
    fromCurrencyTransfer?.value,
    toCurrencyTransfer,
    transferRate,
    buyRate
  );

  const findRateHisId = (transferCurrency, fromCurrencyTransfer) => {
    if (fromCurrencyTransfer === "SGD") {
      const transferDataforcase1 = globalTransferData?.find(
        (data) => data.currencyCode === transferCurrency
      );
      return transferDataforcase1?.rateHistoryId;
    }

    return null;
  };

  console.log({ transferData, transferRate, wireFee, commissionFee });

  const fetchTrancfer = async (fromCurr, toCurr) => {
    setIsLoading(true);
    const resp = await getApiData(
      `api/RateMaster/GetTransactionRate?branchId=${branchID}&fromCurr=${fromCurr}&toCurr=${toCurr}`
    );
    if (
      resp.success === true &&
      resp.data.buyRate !== null &&
      resp.data.sellRate !== null
    ) {
      setBuyRate(parseFloat(resp.data.buyRate));
      // setSellRate(parseFloat(resp.data.sellRate));
    } else {
      setBuyRate(null);
      // setSellRate(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      selectedFromCurrencyTransfer?.value !== "SGD" &&
      toCurrencyTransfer !== "SGD" &&
      selectedFromCurrencyTransfer?.value !== undefined &&
      toCurrencyTransfer !== undefined
    ) {
      fetchTrancfer(selectedFromCurrencyTransfer?.value, toCurrencyTransfer);
    } else {
      console.log("not");
    }
    setFromCurrencyValueTransfer(0);
    setToCurrencyValueTransfer(0);
  }, [selectedFromCurrencyTransfer, toCurrencyTransfer]);

  const fetchCurrencies = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      if (response.success) {
        const mappedResponse = response.data.map((item, index) => ({
          index: index + 1,

          currencyId: item.currencyId,
          currencyName: item.currencyName,
          currencyCode: item.currencyCode,
        }));
        setCurrency(mappedResponse);
        setCurrencies(response.data);
      } else {
        toast.error("Failed to fetch currencies", { autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error fetching currencies", error);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const getAvaBeneficiary = async () => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/AgentBeneficiary/GetAllByAgentBeneficiary`
      );
      setIsLoading(false);

      const filteredResponse = response.data.filter(
        (item) => item.status === 2 && item.active === 1
      );
      const mappedResponse = filteredResponse.map((item) => ({
        label: item.beneficiaryName,
        value: item.companyBenId,
        currencyName: item.currencyName.split("(")[1]?.replace(")", "") || "",
      }));
      const transCurrency = mappedResponse.filter(
        (cc) => cc.currencyName === transferCurrency
      );
      setBeneficiary(transCurrency);
    } catch (error) {
      console.log("Error fetching beneficiaries", error);
    }
  };

  useEffect(() => {
    // getAvaCurrency();
    getAvaBeneficiary();
  }, []);

  const convertFromCurrency = (e) => {
    const inputValue = e.target.value;
    if (inputValue < 0) {
      setErrors((prev) => ({
        ...prev,
        fromCurrency: "Amount cannot be negative.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, toAmount: "" }));
    }
    if (inputValue === "") {
      setFromCurrencyValueTransfer("");
      setToCurrencyValueTransfer("");
      setErrors({
        fromCurrency: "",
        toCurrency: "",
      });
      return;
    }

    // if (inputValue.length > 10) return;

    const amount = parseFloat(inputValue) || 0;

    setFromCurrencyValueTransfer(inputValue);
    setFromCurrencyValueTransferWithDecimals(inputValue);

    if (amount <= 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromCurrency: `${selectedFromCurrencyTransfer?.value} cannot be 0 or less than 0.`,
        toCurrency: "",
      }));
      setIsSubmitting(true);
      setToCurrencyValueTransfer(0);
      setToCurrencyValueTransferWithDecimals(0);
      return;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromCurrency: "",
      }));
      setIsSubmitting(false);
    }

    const totalCharges = wireFee + commissionFee + agentCharges || 0;
    const netAmount = amount - totalCharges;

    const finalAmount = netAmount / transferRate;

    if (finalAmount < 10) {
      setErrors({ toCurrency: "Converted value must be greater than 10." });
      return;
    }
    if (finalAmount.toFixed(2).length > 11) {
      setErrors({
        toCurrency: "Converted amount must not exceed 10 characters.",
      });
      return;
    }

    setErrors({ fromCurrency: "", toCurrency: "" });
    setToCurrencyValueTransfer(finalAmount.toFixed(2));
    setToCurrencyValueTransferWithDecimals(finalAmount);
    setTotalSGD(netAmount.toFixed(2));
  };

  const convertToCurrency = (e) => {
    const inputValue = e.target.value;

    setToCurrencyValueTransfer(inputValue);
    // if (!inputValue || isNaN(inputValue)) {
    //     resetCurrencyValues();
    //     return;
    // }

    const amount = parseFloat(inputValue);

    if (amount <= 0) {
      setErrors({ toCurrency: "Amount must be greater than 0." });
      return;
    }

    setErrors({ toCurrency: "" });

    const baseAmount = amount * transferRate;
    const totalCharges = wireFee + commissionFee + agentCharges || 0;
    const finalAmount = baseAmount + totalCharges;

    if (finalAmount.toFixed(2).length > 11) {
      setErrors({
        fromCurrency: "Converted amount must not exceed 10 characters.",
      });
      return;
    }

    if (amount <= 10) {
      setErrors({ toCurrency: "Amount must be greater than 10." });
      return;
    } else {
      setErrors({ toCurrency: "" });
    }

    setFromCurrencyValueTransferWithDecimals(finalAmount);
    setFromCurrencyValueTransfer(finalAmount.toFixed(2));
    setTotalSGD(baseAmount.toFixed(2));
  };

  const validateForm = () => {
    const newErrors = {};
    const today = new Date();

    // Branch validation
    // if (!selectedBranch) newErrors.branch = "Branch selection is required.";

    // From Currency Amount validation

    if (selectedBeneficiary === null) {
      newErrors.ben = "Please select a beneficiary.";
    } else if (!fromCurrencyValueTransfer) {
      newErrors.fromCurrency = "Amount is required.";
    } else if (parseFloat(fromCurrencyValueTransfer) < 1) {
      newErrors.fromCurrency = "Minimum amount is 1.";
    } else if (parseFloat(fromCurrencyValueTransfer) < 0) {
      newErrors.fromCurrency = "Amount cannot be negative.";
    }

    // To Currency Amount validation
    if (!toCurrencyValueTransfer) {
      newErrors.toCurrency = "Converted amount is required.";
    } else if (parseFloat(toCurrencyValueTransfer) < 0) {
      newErrors.toCurrency =
        "Converted amount cannot be negative, please enter a higher value of SGD amount";
    }

    return newErrors;
  };
  const getCurrencyFlag = (currencyCode) => {
    if (!currencyCode) return "";
    const currency = currencies.find(
      (c) => c.currencyCode && c.currencyCode.trim() === currencyCode.trim()
    );
    return currency ? `data:image/png;base64,${currency.currencyFlag}` : "";
  };

  const handlePrecision = (value, decimals = 9) => {
    const multiplier = Math.pow(10, decimals);
    return Math.round(value * multiplier) / multiplier;
  };

  const handleSubmitTransfer = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }

    const TransferData = {
      toCompanyBenId: selectedBeneficiary?.value,

      fromCurrType: fromCurrencyTransfer,
      fromCurrValue: handlePrecision(fromCurrencyValueTransferWithDecimals),

      toCurrType: toCurrencyTransfer,
      toCurrValue: handlePrecision(toCurrencyValueTransferWithDecimals),

      exchangeRate: transferRate,

      fromBranchId: branchID,
      transactionType: 3,
      agentBranchID: branchId,
      toRateHistoryId: findRateHisId(toCurrencyTransfer, fromCurrencyTransfer),
    };

    try {
      // console.log(TransferData);
      // return;
      const response = await postApiData(
        "api/Transaction/InsertTransaction",
        TransferData
      );
      if (response.success === true) {
        toast.success("Transaction request has been completed ", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        Swal.fire({
          text: response.message + "! Click Ok to go to profile ",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#556ee6",
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate("/profile");
          } else {
            closeModal();
          }
        });
      }
      closeModal();
    } catch (error) {
      console.error("Error during transaction", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleYouGiveCurrencyChange = async (selectedOption) => {
    try {
      setIsLoading(true);
      setSelectedFromCurrencyTransfer(selectedOption);
      const response = await getApiData(
        `api/RateMaster/GetTransactionRate?fromCurr=${selectedOption}`
      );

      setIsLoading(false);
    } catch (error) {
      console.error("Error during transaction", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const filteredBeneficiaries = beneficiary.filter((b) =>
    b.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (typedBeneficiaryName) {
    filteredBeneficiaries.push({
      label: `Create "${typedBeneficiaryName}" Beneficiary`,
      value: "create-new",
    });
  }

  if (filteredBeneficiaries.length === 0) {
    filteredBeneficiaries.push({
      label: `Create New Beneficiary for ${toCurrencyTransfer} currency`,
      value: "create-new",
    });
  }

  const openADDBeneficiary = () => {
    toggleAddModal();
    setSearchTerm("");
  };

  return (
    <>
      <BeneficiaryModal
        editMode={3}
        isOpen={modal1}
        currencies={currency}
        toggle={toggleAddModal}
        createBenFor={toCurrencyTransfer}
        getAvaBeneficiary={getAvaBeneficiary}
        getDocument={getDocument}
        documentData={documentData}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <Form onSubmit={handleSubmitTransfer}>
          <ToastContainer />
          <Row>
            <Col md={12}>
              <div className="mb-2">
                <Label>Select Beneficiary</Label>{" "}
                <RequiredAsterisk></RequiredAsterisk>
                <ReactSelect
                  styles={SelectStyleForMenu}
                  value={selectedBeneficiary}
                  placeholder={
                    beneficiary.length
                      ? "Select Beneficiary"
                      : "No beneficiaries available"
                  }
                  onInputChange={(inputValue) => {
                    setSearchTerm(inputValue);
                    setTypedBeneficiaryName(inputValue);
                  }}
                  onChange={(selectedOption) => {
                    if (selectedOption?.value === "create-new") {
                      openADDBeneficiary(typedBeneficiaryName); // Pass typed name to the modal
                    } else {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        fromCurrency: "",
                        toCurrency: "",
                      }));

                      setSelectedBeneficiary(selectedOption);
                    }
                  }}
                  options={[
                    ...beneficiary.map((option) => ({
                      value: option.value,
                      label: option.label,
                      currencyName: option.currencyName,
                      icon: getCurrencyFlag(option.currencyName),
                    })),
                    ...(typedBeneficiaryName &&
                    !beneficiary.some(
                      (b) =>
                        b.label.toLowerCase() ===
                        typedBeneficiaryName.toLowerCase()
                    )
                      ? [
                          {
                            value: "create-new",
                            label: `Create New "${typedBeneficiaryName}"`,
                            currencyName: "",
                            icon: null,
                          },
                        ]
                      : []),
                  ]}
                  formatOptionLabel={({ label, icon, currencyName }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{label}</span>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {icon && (
                          <img
                            src={icon}
                            alt={`${currencyName} flag`}
                            style={{ width: 20, height: 20, marginLeft: 8 }}
                          />
                        )}
                        <span style={{ marginLeft: 5, fontSize: 12 }}>
                          {currencyName}
                        </span>
                      </div>
                    </div>
                  )}
                />
                {errors.ben && (
                  <div className="text-danger" style={{ fontSize: "0.8em" }}>
                    {errors.ben}
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Label>You Give</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#F8F8F8",
                  padding: "2px",
                }}
              >
                <img src={getCurrencyFlag(fromCurrencyTransfer)} alt="" />
                <Input
                  value={fromCurrencyTransfer}
                  disabled
                  style={{ border: "none" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Label>
                {selectedFromCurrencyTransfer?.currencyCode} Amount{" "}
              </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                value={fromCurrencyValueTransfer}
                onChange={convertFromCurrency}
                // onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
              />
              {errors.fromCurrency && (
                <div className="text-danger" style={{ fontSize: "0.8em" }}>
                  {errors.fromCurrency}
                </div>
              )}
            </Col>
          </Row>
          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Agent Charges</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faMinus} />  */}
                {isNaN(agentCharges) || agentCharges < 0
                  ? 0
                  : agentCharges.toFixed(2)}{" "}
                {selectedFromCurrencyTransfer?.value}
              </span>
            </Col>
          </Row>
          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Wire Transfer Fee</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faMinus} />  */}
                {isNaN(wireFee) || wireFee < 0 ? 0 : wireFee.toFixed(2)}{" "}
                {selectedFromCurrencyTransfer?.value}
              </span>
            </Col>
          </Row>

          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Commission Fee</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                {isNaN(commissionFee) || commissionFee < 0
                  ? 0
                  : commissionFee.toFixed(2)}{" "}
                {selectedFromCurrencyTransfer?.value}
              </span>
            </Col>
          </Row>

          <hr />

          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Total Convertible {fromCurrencyTransfer} Amount</span>
            </Col>
            <Col lg={6}>
              <span>{isNaN(totalSGD) ? 0 : totalSGD}</span>
            </Col>
          </Row>

          <Row className="mb-3 text-end">
            <Col lg={6} className="text-start">
              <span>Transfer Rate</span>
            </Col>
            <Col lg={6}>
              <span>
                {/* {toCurrencyTransfer !== "SGD" ? (
                    <FontAwesomeIcon icon={faDivide} className="me-1" />
                  ) : (
                    <FontAwesomeIcon icon={faXmark} className="me-1" />
                  )} */}
                {transferRate}
              </span>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Label>Beneficiary Receives</Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#F8F8F8",
                  padding: "2px",
                }}
              >
                <img
                  src={getCurrencyFlag(toCurrencyTransfer)}
                  alt={`${toCurrencyTransfer} flag`}
                />
                <Input
                  value={toCurrencyTransfer}
                  disabled
                  style={{ border: "none" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Label>{toCurrencyTransfer} Amount </Label>
              <Input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                value={toCurrencyValueTransfer}
                onChange={convertToCurrency}
                onFocus={(e) => (e.target.style.border = "2px solid #556ee6")}
              />
              {errors.toCurrency && (
                <div className="text-danger" style={{ fontSize: "0.8em" }}>
                  {errors.toCurrency}
                </div>
              )}
            </Col>
          </Row>

          <div className="text-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {"Submit"}
            </button>
          </div>
        </Form>
      )}
    </>
  );
};

export default TransferForm;
