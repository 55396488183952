import React, { createContext, useState } from "react";
export const BaseCurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [currencyData, setCurrencyData] = useState();
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [transferCurrencies, setTransferCurrencies] = useState([]);
  const [selectedContextCurrency, setSelectedContextCurrency] = useState();

  return (
    <BaseCurrencyContext.Provider
      value={{
        currencyData,
        setCurrencyData,
        selectedContextCurrency,
        setSelectedContextCurrency,
        currencyOptions,
        setCurrencyOptions,
        transferCurrencies,
        setTransferCurrencies,
      }}
    >
      {children}
    </BaseCurrencyContext.Provider>
  );
};

export const BaseRegisterContext = createContext();
export const RegisterProvider = ({ children }) => {
  const [singPassData, setSingPassData] = useState();

  return (
      <BaseRegisterContext.Provider value={{
          singPassData,
          setSingPassData,
      }}>
          {children}
      </BaseRegisterContext.Provider>
  );
};
