import React, { useEffect, useState } from "react";
import withRouter from "../Common/withRouter";
import { withTranslation } from "react-i18next";
import SelectStyle from "../../common/data/SelectStyle";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import ReactSelect from "react-select";
import { useAuthUser, useBranch } from "../../common/data/commonFunction";

const SelectBranch = () => {
  const { branchId, setbranchId } = useLatestTransactions();
  const [selectedBranch, setSelectedBranch] = useState();
  const { branch, fetchBranch } = useBranch();
  const { isAgentSuperAdmin } = useAuthUser();
  const { userBranchId } = useAuthUser();

  const handleBranchChange = (selectedOption) => {
    setSelectedBranch(selectedOption);
    setbranchId(
      selectedOption && selectedOption.value ? selectedOption.value : ""
    );
  };

  useEffect(() => {
    if (isAgentSuperAdmin) {
      fetchBranch();
    }
  }, [isAgentSuperAdmin]);

  useEffect(() => {
    if (isAgentSuperAdmin) {
      if (branch.length > 0) {
        const selectedBranch = branch.find((b) => b.value === userBranchId);
        setSelectedBranch(selectedBranch);

        setbranchId(selectedBranch?.value);
      }
    }
  }, [branch, isAgentSuperAdmin]);

  return (
    <React.Fragment>
      {isAgentSuperAdmin ? (
        <div title="Select Your Branch Here">
          <ReactSelect
            id="branchId"
            placeholder="Select Branch"
            styles={{
              ...SelectStyle,
              control: (provided) => ({
                ...provided,
                //   width: "190px",
                width: window.innerWidth < 768 ? "90px" : "150px",
              }),
              menu: (provided) => ({
                ...provided,
                maxWidth: "100%",
                overflowX: "auto",
              }),
            }}
            onChange={(selectedOption) => {
              handleBranchChange(selectedOption);
            }}
            value={selectedBranch}
            options={branch || []}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(SelectBranch));
