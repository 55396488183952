import { useState, useCallback, useEffect } from "react";
import config from './../../config';
import * as signalR from "@microsoft/signalr";
//import { playNotificationSound, verifyAudioFile } from '../helpers/audioUtils';

export const useNotificationService = ({ onNotificationReceived }) => {
    const [connection, setConnection] = useState(null);

    // Verify audio file on component mount
    const handleNotification = (notification) => {
        console.log("Notification received:", notification);

        // Play notification sound
        const audio = new Audio("/assets/NotificationSound.wav");
        audio.play().catch((err) => console.error("Error playing notification sound:", err));

        // Trigger callback with the notification
        if (onNotificationReceived) {
            onNotificationReceived(notification);
        }
    };


    const establishConnection = useCallback(() => {
        const authUser = JSON.parse(localStorage.getItem("authUser"));
        const token = authUser?.token;
        const role = authUser?.role;
        const group =
            role === "Agent Admin"
                ? "SystemAdminNotifications"
                : `Agent_${authUser?.uid}`;

        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(config.notificationUrl, {
                accessTokenFactory: () => token,
                skipNegotiation: false,
                transport:
                    signalR.HttpTransportType.WebSockets ||
                    signalR.HttpTransportType.LongPolling ||
                    signalR.HttpTransportType.ServerSentEvents,
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

        newConnection
            .start()
            .then(() => {
                newConnection.invoke("JoinGroup", group);
                console.log(group, "connected");
            })
            .catch((err) => console.error("Error establishing connection", err));

        // Listen for new notifications with sound
        newConnection.on("ReceiveAgentNotification", handleNotification);

        return () => {
            if (newConnection) {
                newConnection
                    .stop()
                    .then(() => {
                        console.log("Connection stopped");
                    })
                    .catch((err) => console.error(err));
            }
        };
    }, []);

    //const handleNotification = (notification) => {
    //    console.log("Notification received:", notification);

    //    // Play notification sound
    //    const audio = new Audio("/helpers/audioUtils/400697__daphne_in_wonderland__messenger-notification-sound-imitation.wav");
    //    audio.play().catch((err) => console.error("Error playing notification sound:", err));

    //    // Trigger callback with the notification
    //    if (onNotificationReceived) {
    //        onNotificationReceived(notification);
    //    }
    //};

    const restartConnection = () => {
        connection?.stop().then(() => {
            console.log("Old connection stopped. Restarting...");
            establishConnection();
        });
    };

    useEffect(() => {
        const cleanup = establishConnection();
        return () => {
            cleanup();
        };
    }, [establishConnection]);

    return { connection, restartConnection };
};
