import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Col,
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faGlobe,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";
import { ToastContainer } from "react-toastify";
import TransferForm from "./TransferForm";
import { getApiData } from "../../helpers/axiosHelper";
import { BaseCurrencyContext } from "../../common/data/context";
import Loader from "../../components/Common/Loader";
import BuyForm from "./BuyForm";
import SellForm from "./SellForm";
import DashboardTransfer from "./DashboardTransfer";
import Help from "../../components/Common/Help";
import CustomLoader from "../../components/Common/Spinner";

const CurrencyCard = ({
  rate,
  onAddClick,
  currencyIcon,
  globalTransferData,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [branchAnalysisData, setBranchAnalysisData] = useState([]);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const rates = [
    { type: "Buy", value: rate.sellRate, rateType: 2 },
    { type: "Sell", value: rate.buyRate, rateType: 1 },
  ];

  const rateTypeMapping = {
    2: { color: "success", text: "Buy" },
    1: { color: "danger", text: "Sell" },
  };

  const transferData = globalTransferData?.find(
    (item) => item.currencyCode === rate.currencyCode
  );
  const transferRate = transferData ? parseFloat(transferData.rate) : null;
  const isTransferAvailable = transferRate !== null && transferRate > 0;

  useEffect(() => {
    const fetchBranchAnalysis = async () => {
      if (rate.globalIcon && rate.currencyCode !== "SGD") {
        try {
          const response = await getApiData(
            `api/BranchMaster/GetBranchsAnalizes?currencyCode=${rate.currencyCode}`
          );
          setBranchAnalysisData(response);
        } catch (error) {
          console.error("Error fetching branch analysis data:", error);
        }
      }
    };

    fetchBranchAnalysis();
  }, [rate.globalIcon, rate.currencyCode]);

  const isBuyEnabled = branchAnalysisData.some(
    (branch) => !branch.activeRS && branch.isStock
  );
  const isSellEnabled = branchAnalysisData.some((branch) => !branch.activeRS);

  return (
    <Col md={3}>
      <Card className="shadow-sm" style={{ borderRadius: "20px" }}>
        <CardBody style={{ padding: "20px" }}>
          <h6 className="text-center">Branch - {rate.branchName}</h6>

          <div className="text-center text-muted">
            {rate.globalIcon && (
              <FontAwesomeIcon
                icon={faGlobe}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "#556ee6",
                  fontSize: "1.2em",
                }}
              />
            )}
            <img
              src={currencyIcon}
              alt="Currency"
              style={{ width: "30px", marginRight: "5px" }}
            />
            <span style={{ fontWeight: "bold" }}>{rate.currencyCode}</span>
          </div>

          <h6
            className="text-center"
            style={{ fontWeight: "500", marginTop: "10px" }}
          >
            Value
          </h6>
          {rate.currencyCode !== "SGD" ? (
            <Row className="text-center">
              {rates.map(({ value, rateType }) => {
                const { color, text } = rateTypeMapping[rateType];
                const formattedValue =
                  value !== null && !isNaN(value)
                    ? parseFloat(value) % 1 === 0
                      ? parseInt(value, 10)
                      : parseFloat(value).toFixed(4)
                    : "N/A";
                return (
                  <Col key={rateType}>
                    <Badge className={`font-size-11 badge-soft-${color}`}>
                      {text}
                    </Badge>
                    <p className="mt-2">{formattedValue}</p>
                  </Col>
                );
              })}
              <Col>
                <Badge className="font-size-11 badge-soft-info">Transfer</Badge>
                <p className="mt-2">
                  {isTransferAvailable ? transferRate : "N/A"}
                </p>
              </Col>
            </Row>
          ) : (
            <div className="text-center">
              <Badge
                className="font-size-13 badge-soft-info mb-2"
                style={{ padding: "5px 10px", fontSize: "1rem" }}
              >
                Transfer
              </Badge>
            </div>
          )}

          <div className="d-flex justify-content-center">
            {rate.currencyCode !== "SGD" ? (
              <Dropdown
                isOpen={isDropdownOpen}
                toggle={toggleDropdown}
                className="d-flex justify-content-center mb-2"
              >
                <DropdownToggle color="primary">
                  <FontAwesomeIcon icon={faDollarSign} /> Book Now{" "}
                  <span style={{ fontSize: "8px" }}>&#x25BC;</span>
                </DropdownToggle>
                <DropdownMenu style={{ textAlign: "center" }}>
                  <DropdownItem
                    onClick={() => onAddClick(rate, 2)}
                    disabled={
                      rate.globalIcon
                        ? !isBuyEnabled
                        : !(parseFloat(rate.sellRate) > 0) || !rate.sellStock
                    }
                  >
                    Buy
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onAddClick(rate, 1)}
                    disabled={
                      rate.globalIcon
                        ? !isSellEnabled
                        : !(parseFloat(rate.buyRate) > 0)
                    }
                  >
                    Sell
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onAddClick(rate, 3)}
                    disabled={!isTransferAvailable}
                  >
                    Transfer
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
              <button
                type="button"
                disabled={!isTransferAvailable}
                className="btn btn-primary mt-4 mb-3"
                onClick={() => onAddClick(rate, 3)}
              >
                Transfer
              </button>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

const AddCurrency = () => {
  const { currencyData } = useContext(BaseCurrencyContext);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [rates, setRates] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);
  const [currencyIcons, setCurrencyIcons] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [globalTransferData, setGlobalTransferData] = useState(null);
  const [ratesAvailable, setRatesAvailable] = useState(true);
  const [mainBranchId, setMainBranchId] = useState(null);
  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const handleHelpClick = () => {
    const pdfUrl = "/Help/HELP DOCUMENTATION FOR DASHBOARD.pdf";
    window.open(pdfUrl, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await getApiData("api/BranchMaster/GetAllBranch");
        const activeBranches = response.data.filter(
          (branch) => branch.active === 1
        );
        const mainBranch = response.data.filter(
          (branch) => branch.active === 1 && branch.isMainBranch === true
        );
        const branchOptions = activeBranches.map((branch) => ({
          value: branch.branchId,
          label: branch.branchName,
        }));
        branchOptions.unshift({ value: "all", label: "All Branches" });
        setMainBranchId(mainBranch[0].branchId);
        setBranches(branchOptions);
        setSelectedBranch(branchOptions[0]);
        fetchAllBranchRS();
      } catch (error) {
        console.error("Error fetching branches");
      }
    };
    const fetchGlobalTransferRates = async () => {
      try {
        const response = await getApiData(
          "api/RateMaster/GetGlobalTransferRate"
        );

        const mappedTransferRates = response.data.map((rate) => ({
          rateHistoryId: rate.rateHistoryId,
          currencyCode: rate.currencyCode,
          currencyName: rate.currencyName,
          rate: rate.value,
          worldRate: parseFloat(rate.worldRate),
          transferRate: parseFloat(rate.transferRate),
          wireTransferFee: parseFloat(rate.wireTransferFee),
          agentCharges: parseFloat(rate.agentCharges),
          commissionCharges: parseFloat(rate.commissionCharges),
          totalFeesAdmin: parseFloat(rate.totalFeesAdmin),
        }));

        setGlobalTransferData(mappedTransferRates);
      } catch (error) {
        console.error("Error fetching global transfer rates", error);
      }
    };
    fetchBranches();
    fetchGlobalTransferRates();
  }, []);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await getApiData("api/RateMaster/GetAllCurrencies");
        const icons = {};
        response.data.forEach((currency) => {
          icons[
            currency.currencyCode.trim()
          ] = `data:image/png;base64,${currency.currencyFlag}`;
        });

        setCurrencyIcons(icons);
      } catch (error) {
        console.error("Error fetching currencies");
      }
    };
    fetchCurrencies();
  }, []);

  const fetchAllBranchRS = async () => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        "api/RateMaster/GetAllActiveRatesByBranches"
      );
      if (response.data && response.data.length > 0) {
        processRatesResponseForAllBranches(response.data);
        setRatesAvailable(true);
      } else {
        setRatesAvailable(false);
      }
    } catch (error) {
      console.error("Error fetching all branch rates", error);
      setIsLoading(false);
      setRatesAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchRSByBranch = async (branchId, branchName) => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/RateMaster/GetActiveRatesByBranch?BranchID=${branchId}`
      );
      if (response.data && response.data.length > 0) {
        processRatesResponseForSingleBranch(
          response.data,
          branchName,
          branchId
        );
        setRatesAvailable(true);
      } else {
        setRatesAvailable(false);
      }
    } catch (error) {
      console.error("Error fetching branch rates", error);
      setIsLoading(false);
      setRatesAvailable(false);
    } finally {
      setIsLoading(false);
    }
  };

  const processRatesResponseForAllBranches = (data) => {
    const groupedRates = data.reduce((acc, branch) => {
      const { branchId, branchName, rateHistories = [] } = branch;

      rateHistories.forEach((rate) => {
        console.log(rate);
        const key = `${branchId}-${rate.currencyCode.trim()}`;
        acc[key] = acc[key] || {
          currencyName: rate.currencyName || "",
          currencyCode: rate.currencyCode.trim(),
          branchId,
          branchName: branchName === "All Branches" ? "Global" : branchName,
          globalIcon: branchName === "All Branches",
        };
        acc[key][["buyRate", "sellRate"][rate.rateType - 1]] = rate.value;
        acc[key][["buyStock", "sellStock"][rate.rateType - 1]] = rate.stock;
        acc[key][["buyCharge", "sellCharge"][rate.rateType - 1]] =
          rate.agentCharges;
        acc[key][["buyRateHistoryId", "sellRateHistoryId"][rate.rateType - 1]] =
          rate.rateHistoryId;
      });

      console.log(acc);
      return acc;
    }, {});

    const ratesList = Object.values(groupedRates);
    const currencyMap = ratesList
      .filter(({ currencyCode }) => currencyCode !== "SGD") // Skip SGD
      .reduce((map, { currencyCode, currencyName }) => {
        map[currencyCode] = { currencyCode, currencyName };
        return map;
      }, {});

    const availableCurrencies = Object.keys(currencyMap).map((currencyCode) => {
      const { currencyName } = currencyMap[currencyCode];
      return {
        value: currencyCode,
        label: `${currencyName} (${currencyCode})`,
      };
    });

    setRates(ratesList);
    console.log(ratesList);
    setCurrencyOptions(availableCurrencies);
  };

  // Process response from fetchRSByBranch
  const processRatesResponseForSingleBranch = (data) => {
    const groupedRates = data.reduce((acc, rate) => {
      const { branchId, branchName, currencyName, currencyCode } = rate;
      const key = `${branchId}-${currencyCode.trim()}`;
      acc[key] = acc[key] || {
        currencyName: currencyName || "",
        currencyCode: currencyCode.trim(),
        branchId,
        branchName: branchName === "All Branches" ? "Global" : branchName,
        globalIcon: branchName === "All Branches",
      };

      acc[key][["buyRate", "sellRate"][rate.rateType - 1]] = rate.value;
      acc[key][["buyStock", "sellStock"][rate.rateType - 1]] = rate.stock;
      acc[key][["buyCharge", "sellCharge"][rate.rateType - 1]] =
        rate.agentCharges;
      acc[key][["buyRateHistoryId", "sellRateHistoryId"][rate.rateType - 1]] =
        rate.rateHistoryId;
      return acc;
    }, {});

    setRates(Object.values(groupedRates));
  };

  const handleBranchChange = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
    if (selectedBranch.value === "all") fetchAllBranchRS();
    else fetchRSByBranch(selectedBranch.value, selectedBranch.label);
  };

  const handleCurrencyChange = (selectedCurrency) =>
    setSelectedCurrency(selectedCurrency);

  const handleAddClick = (rate, rateType) => {
    setSelectedRate({ ...rate, rateType });
    setModalOpen(true);
  };

  const transType = {
    Buy: 2,
    Sell: 1,
    Transfer: 3,
  };

  const renderForm = () => {
    if (!selectedRate) return null;
    console.log("Selected Rate " + selectedRate.sellCharge);
    const { branchId, currencyCode, rateType, globalIcon } = selectedRate;
    console.log(currencyData?.vlaue);
    const transferBranch =
      selectedRate?.globalIcon === true ? mainBranchId : branchId;
    switch (rateType) {
      case 2:
        return (
          <BuyForm
            branchID={branchId}
            rateType={rateType}
            buyCharge={selectedRate.sellCharge}
            rate={selectedRate.sellRate}
            buyingCurrency={currencyCode}
            stock={selectedRate.buyStock}
            closeModal={toggleModal}
          />
        );
      case 1:
        return (
          <SellForm
            branchID={branchId}
            rateType={rateType}
            sellCharge={selectedRate.buyCharge}
            rate={selectedRate.buyRate}
            sellingCurrency={currencyCode}
            closeModal={toggleModal}
            sellRateHistoryID={selectedRate.buyRateHistoryId}
          />
        );
      case 3:
        return (
          <TransferForm
            branchID={transferBranch}
            closeModal={toggleModal}
            transferCurrency={currencyCode}
            baseCurrency={currencyData?.value}
            globalTransferData={globalTransferData}
          />
        );
      default:
        return null;
    }
  };

  const filteredRates = useMemo(
    () =>
      selectedCurrency
        ? rates.filter((rate) => rate.currencyCode === selectedCurrency.value)
        : rates,
    [selectedCurrency, rates]
  );

  return (
    <>
      <ToastContainer />

      {currencyData?.value === "SGD" ? (
        <Row className="justify-content-end">
          <Col md="auto">
            <small className="text-end text-primary mb-1">
              All Currency conversions are based on SGD
            </small>
          </Col>
        </Row>
      ) : null}
      <Card
        className="shadow-sm"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <CardBody>
          {/* <Row className="justify-content-between">
            <Col>
              <h4 className="card-title mb-4 d-flex justify-content-end align-items-center">
                <Help to={"HELP DOCUMENTATION FOR DASHBOARD"} />
              </h4>
            </Col>
            <Col md={3}>
              <h4 className="text-center text-primary">Book Transaction</h4>
            </Col>
          </Row> */}
          <Row className="align-items-center">
            {/* First Column for Centered Text */}
            <Col md={10} className="text-center">
              <h4 className="text-primary">Book Transaction</h4>
            </Col>

            {/* Second Column for Help Component */}
            <Col md={2} className="text-end">
              <Help to="HELP DOCUMENTATION FOR DASHBOARD" />
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col md={3}>
              {currencyData?.value === "SGD" && (
                <ReactSelect
                  options={branches}
                  value={selectedBranch}
                  onChange={handleBranchChange}
                />
              )}
            </Col>

            <Col md={3}>
              {currencyData?.value === "SGD" && (
                <ReactSelect
                  options={currencyOptions}
                  value={selectedCurrency}
                  onChange={handleCurrencyChange}
                  placeholder="Choose Currency"
                  isClearable
                  formatOptionLabel={({ value, label }) => (
                    <div className="d-flex align-items-center">
                      <img
                        src={currencyIcons[value]}
                        alt={value}
                        style={{ width: 20, height: 20, marginRight: 8 }}
                      />
                      <span>{label}</span>
                    </div>
                  )}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row
        style={{
          // height: "400px",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "10px",
        }}
      >
        {isLoading ? (
          <div className="loader-container">
            <CustomLoader />
          </div>
        ) : currencyData?.value !== "SGD" ? (
          <DashboardTransfer
            branch={filteredRates[0]?.branchId}
            transferCurrency={filteredRates[0]?.currencyCode}
            rate={filteredRates[0]?.baseTransferRate}
            closeModal={toggleModal}
            baseCurrency={currencyData?.value}
            worldRate={filteredRates[0]?.worldRate}
            transferRate={filteredRates[0]?.transferRate}
            wireTransferFee={filteredRates[0]?.wireTransferFee}
            commissionCharges={filteredRates[0]?.commissionCharges}
            agentCharges={filteredRates[0]?.agentCharges}
          />
        ) : !ratesAvailable ? (
          <h5 className="text-center">
            No rate sheet available to initiate transaction
          </h5>
        ) : (
          // filteredRates.map((rate, idx) => {
          filteredRates
            .filter((x) => x.currencyCode !== "SGD")
            .map((rate, idx) => {
              return (
                <CurrencyCard
                  key={idx}
                  rate={rate}
                  onAddClick={handleAddClick}
                  currencyIcon={currencyIcons[rate.currencyCode]}
                  baseCurrency={currencyData?.value}
                  globalTransferData={globalTransferData}
                />
              );
            })
        )}
      </Row>

      {modalOpen && (
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
          <ModalHeader toggle={toggleModal}>
            {selectedRate?.rateType === 2
              ? "Buy Currency"
              : selectedRate?.rateType === 1
              ? "Sell Currency"
              : "Transfer Currency"}
          </ModalHeader>
          <ModalBody>{renderForm()}</ModalBody>
        </Modal>
      )}
    </>
  );
};

export default AddCurrency;
