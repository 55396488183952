import Swal from "sweetalert2";
import { path } from "../routes/Path";

export const SessionTimeout = (navigate, pathname) => {
  if (pathname !== path.login && pathname !== path.default) {
    const currentTime = new Date();
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const expiry = new Date(authUser?.expiration);

    if (expiry) {
      if (currentTime >= expiry) {
        localStorage.removeItem("authUser");
        return Swal.fire({
          title: "Session Expired, Please Login Again",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          navigate(path.login);
        });
      }
    }
  }
};
