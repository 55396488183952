import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Tooltip,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import ViewComment from "./ViewComment";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/Common/Loader";
import { faEye, faDownload, faDollar } from "@fortawesome/free-solid-svg-icons";
import SelectStyle from "../../../common/data/SelectStyle";
import withRouter from "../../../components/Common/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableContainer from "../../../components/Common/TableContainer";
import { getApiData, postApiData } from "../../../helpers/axiosHelper";
import RequiredAsterisk from "../../../components/Common/RequiredAsterisk";
import {
  useBranch,
  useCurrency,
  useTransactionBranch,
} from "../../../common/data/commonFunction";
import {
  Date,
  Branch,
  CurrencyType,
  CurrencyValue,
  AgentName,
  TransactionID,
  TransactionCurrency,
} from "./LatestTransactionCol";
import {
  isAgentSuperAdmin,
  isAgentBranchAdmin,
} from "../../../common/data/commonFunction";
import { mapStatus, statusOptions } from "../../../common/data/statusLabels";
import { useLatestTransactions } from "../../../common/data/latest-transaction-context";
import Help from "../../../components/Common/Help";
import ViewLatestTransactionModel from "./ViewLatestTransactionModel";
import ReceiptModal from "./Receipt";
import { formatDateToUserTimeZone } from "../../../common/date";
import { displayFlag, getCurrencyFlag } from "../../../common/data/currency";

const TransactionHistory = (props) => {
  document.title = "Transaction History | Crescent Exchange";
  const [showFilterApplied, setShowFilterApplied] = useState(null);
  const [error, setError] = useState();
  const { branch, fetchBranch } = useBranch();
  const { tranBranch, fetchTransactionBranch } = useTransactionBranch();
  const [modal1, setModal1] = useState(false);
  const [columnName, setColumnName] = useState();
  const { currency, getCurrency } = useCurrency();
  const [filterType, setFilterType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [commentData, setCommentData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [totalRecord, setTotalRecords] = useState(null);
  const [transactionID, setTransactionID] = useState("");
  const [modal_filter, setmodal_filter] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);
  const { branchId } = useLatestTransactions();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [optime, setOptime] = useState([]);
  const [fromBranchId, setFromBranchID] = useState("");
  const [branchData, setBranchData] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [receiptData, setReceiptData] = useState({});
  const [triggerDownload, setTriggerDownload] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [currencyFlags, setCurrencyFlags] = useState({});
  const [isBranchActive, setIsBranchActive] = useState(true);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [buyRateforCharges, setBuyRateForCharges] = useState(null);

  const [state, setState] = useState({
    pageSize: 10,
    status: null,
    currentPage: 1,
    agentName: "",
    currency: null,
    branchName: null,
    submitting: false,
    transactionDate: null,
    clearFiltersClicked: false,
    transactionId: null,
  });
  const transactionTypeMap = {
    2: { label: "Buy", color: "success" },
    1: { label: "Sell", color: "danger" },
    3: { label: "Transfer", color: "primary" },
  };

  function tog_addFilters() {
    // resetForm();
    setmodal_filter((prevState) => !prevState);
  }
  const fetchCurrencyFlags = async () => {
    try {
      const response = await getApiData("api/RateMaster/GetAllCurrencies");
      const flags = {};
      response.data.forEach((currency) => {
        flags[currency.currencyCode.trim()] = currency.currencyFlag;
      });
      setCurrencyFlags(flags);
    } catch (error) {
      console.error("Failed to load currency flags", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const clearFilter = () => {
    validation.resetForm();
    setSelectedOption(null);
    setFilterType(null);
    setColumnName(null);
    setFilterValue(null);
    setState({
      pageSize: 10,
      status: null,
      currentPage: 1,
      currency: null,
      branchName: null,
      submitting: false,
    });
    setFilterType(null);
    setColumnName(null);
    setFilterValue(null);
    setState((prevState) => ({ ...prevState, clearFiltersClicked: true }));
    // resetFormValues();
  };

  const resetFormValues = () => {
    setFilterType("");
    setColumnName(null);
    validation.resetForm();
    setSelectedOption(null);
    setState({
      pageSize: 10,
      status: null,
      currentPage: 1,
      currency: null,
      branchName: null,
      agentName: "",
      submitting: false,
      transactionDate: null,
      transactionId: null,
    });
    setFilterValue(null);
    setState((prevState) => ({ ...prevState, clearFiltersClicked: true }));
    validation.handleSubmit();
  };
  const getTransaction = async () => {
    // setAgentBranchId(branchId);
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/Transaction/GetTransactionForPendingStatus?AgentBranchId=${branchId}`
      );
      console.log("Response from API");
      console.log(response);

      setIsLoading(false);

      const mapStatus = (status) =>
        statusLabels[status] || { label: "Unknown", color: "secondary" };

      const statusLabels = {
        1: { label: "Pending", color: "warning" },
        2: { label: "Waiting For Requestor", color: "primary" },
        3: { label: "Waiting For Approver", color: "primary" },
        4: { label: "Approved", color: "success" },
        5: { label: "Declined", color: "danger" },
        6: { label: "User Declined", color: "danger" },
        7: { label: "Cancelled", color: "danger" },
        8: { label: "User Acknowledged", color: "info" },
        9: { label: "User Cancelled", color: "info" },
        10: { label: "Waiting For Payment", color: "info" },
        11: { label: "Payment Received", color: "info" },
        12: { label: "Waiting For Collection", color: "info" },
        13: { label: "Cash Collected", color: "info" },
      };

      const mappedResponse = response.data.map((item, index) => ({
        transactionId: item.transactionId,
        fromBranchId: item.fromBranchId,
        companyName: item.companyName,
        index: index + 1,
        name: item.name,
        branch: item.branchName,
        transactionType: item.transactionType,
        date: formatDateToUserTimeZone(item.transactionDate),
        fromCurrType: item.fromCurrType,
        fromCurrValue: item.fromCurrValue,
        status: mapStatus(item.status),
      }));

      setTransactionData(mappedResponse);
      console.log("Mapped Response");

      console.log(mappedResponse);
    } catch (error) {
      console.log(error);
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };
  const resetForm = () => {
    setFilterType("");
    setColumnName(null);
    validation.resetForm();
    setSelectedOption(null);
    setState({
      pageSize: 10,
      status: null,
      currentPage: 1,
      currency: null,
      branchName: null,
      submitting: false,
      transactionDate: null,
      transactionId: null,
    });
    setFilterValue(null);
    setState((prevState) => ({ ...prevState, clearFiltersClicked: true }));
  };

  const reload = () => {
    getTransaction();
  };

  const resetTransaction = () => {
    const showResetToDefault =
      validation.values.pagecount !== 10 ||
      validation.values.pagenumber !== 1 ||
      state.agentName !== "" ||
      state.currency !== null ||
      state.status !== null ||
      state.branchName !== null ||
      state.transactionDate !== null ||
      state.transactionId != null;

    return (
      <>
        {showResetToDefault && (
          <Button
            type="button"
            color="danger"
            onClick={resetToDefault}
            aria-label="reset"
          >
            Reset
          </Button>
        )}
      </>
    );
  };

  const resetToDefault = () => {
    setClearFiltersClicked(true);
    resetFormValues();
    validation.handleSubmit();
  };

  const clearFilters = () => {
    const showClear =
      state.agentName !== "" ||
      state.currency !== null ||
      state.status !== null ||
      filterValue !== null ||
      filterType !== "" ||
      state.branchName !== null ||
      state.transactionId !== null ||
      state.transactionDate !== null;
    return (
      <>
        {showClear && (
          <Button
            type="button"
            color="danger"
            onClick={clearFilter}
            aria-label="clear"
          >
            Clear Filters
          </Button>
        )}
      </>
    );
  };
  const columnList = [
    { label: "Status", value: "status" },
    { label: "Branch", value: "branchName" },
    { label: "Currency", value: "fromCurrType" },
  ];

  const mapResponse = (data) => {
    return data.map((item, key) => ({
      index: key + 1,
      agentName: item.name,
      agentBranch: item.branchName,
      status: mapStatus(item.status),
      currencyType: item.fromCurrType,
      currencyValue: item.fromCurrValue,
      toCurrType: item.toCurrType,
      toCurrValue: item.toCurrValue,
      transactionID: item.transactionId,
      transactionDate: formatDateToUserTimeZone(item.transactionDate),
      transactionId: item.transactionId,
      fromBranchId: item.fromBranchId,
      companyName: item.companyName,
      name: item.name,
      branch: item.branchName,
      transactionType: item.transactionType,
      iDandBranch: item.transactionId + "-" + item.branchName,
      date: formatDateToUserTimeZone(item.transactionDate),
      fromCurrType: item.fromCurrType,
      toCurrType: item.toCurrType,
      fromCurrValue: parseFloat(Number(item.fromCurrValue).toFixed(2)),
      toCurrValue: parseFloat(Number(item.toCurrValue).toFixed(2)),
      status: mapStatus(item.status),
      convRate: parseFloat(Number(item.exchangeRate).toFixed(8)),
      pickupDate:
        item.pickup !== null
          ? formatDateToUserTimeZone(item?.pickup)
          : "Not Mentioned",
      transactingCurrency: item.toCurrType,
    }));
  };

  const getTransactionHistory = async () => {
    setIsLoading(true);
    const response = await postApiData(
      `api/Transaction/GetTransactionByAgentPagination?agentBranchId=${branchId}`,
      JSON.stringify({ pagenumber: 1, pagecount: 10 })
    );
    if (response?.success === true) {
      if (response.model.length === 0) {
        setTransactionHistoryData([]);
        setTotalPages(0);
        setTotalRecords(0);
      } else {
        setTransactionHistoryData(mapResponse(response.model));
        setTotalPages(response.totalPage);
        setTotalRecords(response.totalRecord);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return;
    }
  };
  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const meridiem = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${meridiem}`;
  };
  const Days = {
    0: "All Days",
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
  };
  const toggleViewModal = () => setModal1(!modal1);

  const ViewTransactionDetails = (cellProps) => {
    const { transactionId, fromBranchId } = cellProps;
    setTransactionID(transactionId);
    setFromBranchID(fromBranchId);
    getTransactionbyId(transactionId, fromBranchId);

    // getTransactionbyId();
    toggleViewModal();
  };

  const getTransactionbyId = async (transactionId, fromBranchId) => {
    try {
      const response = await getApiData(
        `api/Transaction/GetTransaction?TransactionID=${transactionId}`
      );
      const branchResponse = await getApiData(
        `api/BranchMaster/GetBranchWithOperation?branchId=${fromBranchId}`
      );

      let rateHistoryId = "";
      if (response.transactionType === 2 && response.toRateHistoryId !== null) {
        rateHistoryId = response.toRateHistoryId;
      } else if (
        response.transactionType === 1 &&
        response.fromRateHistoryId !== null
      ) {
        rateHistoryId = response.fromRateHistoryId;
      }
      if (response.transactionType === 1 || response.transactionType === 2) {
        const chargesResponse = await getApiData(
          `api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`
        );
        setChargesDetails(chargesResponse.data);
      } else if (
        response.transactionType === 3 &&
        // response.toCurrType !== "SGD" &&
        response.toRateHistoryId !== null
      ) {
        const transfer2charges = await getApiData(
          `api/RateMaster/GetTransactionCharges?rateHistoryId=${response.toRateHistoryId}`
        );
        const rateResponse = await getApiData(
          `api/RateMaster/GetTransactionRate?fromCurr=${response.fromCurrType}`
        );
        setBuyRateForCharges(rateResponse.data.buyRate);
        setChargesDetails(transfer2charges.data);
      } else if (
        response.transactionType === 3 &&
        response.fromCurrType === "SGD" &&
        response.fromRateHistoryId !== null
      ) {
        const transfer1charges = await getApiData(
          `api/RateMaster/GetTransactionCharges?rateHistoryId=${response.fromRateHistoryId}`
        );
        setBuyRateForCharges(null);
        setChargesDetails(transfer1charges.data);
      } else setChargesDetails("");

      if (branchResponse.data.branch.active === 1) setIsBranchActive(true);
      else setIsBranchActive(false);

      const mappedResponse = branchResponse.data.operationalTime.map((item) => {
        const startTime12 = convertTo12HourFormat(item.startTime);
        const endTime12 = convertTo12HourFormat(item.endTime);
        return {
          day: Days[item.day],
          startTime: startTime12,
          endTime: endTime12,
        };
      });

      setBranchData(branchResponse.data.branch);
      setOptime(mappedResponse);
      setSendTransactiondetails(response);
      setCommentData(response.comments);
      setDocumentData(response.transactionDocs);
      setBeneficiaryDetails(response.beneficiaryDetails);
    } catch (error) {
      console.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleShow = (data, download) => {
    getTransactionbyId(data.transactionID, data.fromBranchId);
    setReceiptData(data);
    setTriggerDownload(download);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setTriggerDownload(false);
  };

  useEffect(() => {
    if (branchId !== null && branchId !== undefined) {
      getTransactionHistory();
      fetchCurrencyFlags();
    }
  }, [branchId]);

  useEffect(() => {
    fetchTransactionBranch();
    getCurrency();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Id-Branch",
        accessor: "iDandBranch",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <TransactionID {...cellProps} />;
        },
      },
      ...(isAgentSuperAdmin || isAgentBranchAdmin
        ? [
            {
              Header: "Agent",
              accessor: "name",
              disableFilters: true, // or filterable: false
              Cell: (cellProps) => {
                return <AgentName {...cellProps} />;
              },
            },
          ]
        : []),
      {
        Header: "Currency",
        accessor: "transactingCurrency",
        disableFilters: true,
        Cell: (cellProps) => {
          const { transactionType, fromCurrType, toCurrType, currencyFlag } =
            cellProps.row.original;
          const transactingCurrency =
            transactionType === 1 ? fromCurrType : toCurrType;
          const { label, color } = transactionTypeMap[transactionType] || {};
          const flagIcon1 = currencyFlags[fromCurrType.trim()];
          const flagIcon2 = currencyFlags[transactingCurrency.trim()];
          return (
            <div>
              {flagIcon1 ? (
                <img
                  src={`data:image/png;base64,${flagIcon1}`}
                  alt=""
                  style={{ width: "20px", marginLeft: "5px" }}
                />
              ) : (
                <FontAwesomeIcon icon={faDollar} />
              )}
              <span>{` ${fromCurrType}`}</span>
              <Badge className={`font-size-11 badge-soft-${color}`}>
                {label}
              </Badge>
              {flagIcon2 ? (
                <img
                  src={`data:image/png;base64,${flagIcon2}`}
                  alt=""
                  style={{ width: "20px", marginLeft: "5px" }}
                />
              ) : (
                <FontAwesomeIcon icon={faDollar} />
              )}
              <span>{` ${toCurrType}`}</span>
            </div>
          );
        },
      },
      {
        Header: "From Amount",
        accessor: "toCurrValue",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { fromCurrValue, fromCurrType } = cellProps.row.original;

          return (
            <span>
              {fromCurrValue} {fromCurrType}
            </span>
          );
        },
      },
      {
        Header: "Rate",
        accessor: "convRate",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Converted Amount",
        accessor: "fromCurrValue",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { toCurrValue, toCurrType } = cellProps.row.original;
          return (
            <span>
              {toCurrValue} {toCurrType}
            </span>
          );
        },
      },
      {
        Header: "Booked Date",
        accessor: "transactionDate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Pickup Date",
        accessor: "pickupDate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: true,

        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" + cellProps.row.original.status.color
              }
            >
              {cellProps.row.original.status.label}
            </Badge>
          );
        },
      },

      {
        Header: "View Details",
        disableFilters: true,
        accessor: "view",

        Cell: (cellProps) => {
          const isDownloadable =
            cellProps.row.original.status === mapStatus(13);
          const {
            transactionID,
            transactionDate,
            currencyType,
            agentName,
            currencyValue,
            toCurrType,
            toCurrValue,
            agentBranch,
            convRate,
            transactionType,
            fromBranchId,
          } = cellProps.row.original;

          const receiptData = {
            transactionID,
            transactionDate,
            agentName,
            currencyType,
            agentBranch,
            currencyValue,
            toCurrType,
            toCurrValue,
            convRate,
            transactionType,
            fromBranchId,
          };
          return (
            <div className="d-flex align-items-center">
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded ms-4"
                onClick={() => {
                  setEditMode(true);
                  ViewTransactionDetails(cellProps.row.original);
                  // viewComment(cellProps.row.original.transactionId);
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
              {isDownloadable && (
                <Button
                  type="button"
                  style={{ backgroundColor: "#34c38f", border: "none" }} // Corrected syntax
                  className="btn-sm btn-rounded me-2 ms-2"
                  onClick={() => handleShow(receiptData, true)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    [isAgentSuperAdmin, isAgentBranchAdmin, currencyFlags]
  );

  const handleChange = (pageSize, currentPage) => {
    setState((prevState) => ({
      ...prevState,
      currentPage: currentPage,
      pageSize: pageSize,
    }));
    validation.setValues((values) => ({
      ...values,
      pagecount: pageSize,
      pagenumber: currentPage,
    }));
    validation.handleSubmit();
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      pagenumber: state.currentPage,
      pagecount: state.pageSize,
    },

    onSubmit: async (values) => {
      setState((prevState) => ({ ...prevState, submitting: true }));
      if (modal_filter) {
        values.pagenumber = 1;
        values.pagecount = 10;
        setState((prevState) => ({
          ...prevState,
          currentPage: 1,
          pageSize: 10,
        }));
      }
      let combinedValues = { ...values };
      if (columnName) {
        if (filterValue === null) {
          setError("Please Enter the Value");
          return;
        } else if (columnName && filterType === "Agent Name") {
          combinedValues = {
            ...combinedValues,
            filterColumns: [
              {
                columnName: columnName,
                filterValue: state.agentName !== "" && state.agentName,
              },
            ],
          };
        } else {
          combinedValues = {
            ...combinedValues,
            filterColumns: [
              {
                columnName: columnName,
                filterValue:
                  (state.branchName !== null && state.branchName?.label) ||
                  (state.currency !== null && state.currency?.label) ||
                  (state.status !== null && `${state.status?.value}`) ||
                  (state.transactionDate !== null &&
                    `${state.transactionDate}`),
              },
            ],
          };
        }
      }
      setShowFilterApplied(combinedValues.filterColumns?.[0] || null);
      const response = await postApiData(
        `api/Transaction/GetTransactionByAgentPagination?agentBranchId=${branchId}`,
        JSON.stringify(combinedValues)
      );
      if (response.success === true) {
        setTransactionHistoryData(mapResponse(response.model));
        setTotalPages(response.totalPage);
        setTotalRecords(response.totalRecord);
        if (modal_filter) {
          tog_addFilters();
        }
        setState((prevState) => ({ ...prevState, submitting: false }));
      } else if (response.success === false) {
        toast.error(`${response.message}`, {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
          onClose: () => {
            setState((prevState) => ({ ...prevState, submitting: false }));
          },
        });
      }
    },
  });

  const handleHelpClick = () => {
    const pdfUrl = "/Help/HELP DOCUMENTATION FOR DASHBOARD.pdf";
    window.open(pdfUrl, "_blank", "noopener,noreferrer");
  };

  const getFilterLabel = (columnName) => {
    switch (columnName) {
      case "status":
        return "Status";
      case "branchName":
        return "Branch";
      case "fromCurrType":
        return "Currency";
      default:
        return "Unknown";
    }
  };

  const getFilterValue = ({ columnName, filterValue }) => {
    if (columnName === "status") {
      return mapStatus(parseFloat(filterValue)).label;
    }
    return filterValue;
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      status: null,
      currency: null,
      branchName: null,
      transactionDate: null,
    }));
  }, [filterType]);

  useEffect(() => {
    console.log(validation.values);
    console.log(validation.errors);
  }, [validation.values, validation.errors]);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <ViewLatestTransactionModel
        optime={optime}
        isOpen={modal1}
        branchID={fromBranchId}
        editMode={editMode}
        branchData={branchData}
        toggle={toggleViewModal}
        commentData={commentData}
        transactionID={transactionID}
        sendTransactionDetails={sendTransactionDetails}
        reload={reload}
        showAction={false}
        isBranchActive={isBranchActive}
        beneficiaryDetails={beneficiaryDetails}
        chargesDetails={chargesDetails}
        buyRateForCharges={buyRateforCharges}
        documentData={documentData}
      />

      <ReceiptModal
        show={showModal}
        handleClose={handleClose}
        receiptData={receiptData}
        triggerDownload={triggerDownload}
        chargesDetails={chargesDetails}
        buyRate={buyRateforCharges}
      />
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Col>
                <h4 className="card-title mb-4 d-flex justify-content-between">
                  Transaction History
                  <Help to={"HELP DOCUMENTATION FOR TRANSACTION HISTORY"} />
                </h4>
              </Col>

              <Modal
                size="md"
                isOpen={modal_filter}
                toggle={() => {
                  tog_addFilters();
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="mySmallModalLabel">
                    Select Filters
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_filter(false);
                      validation.resetForm();
                      setFilterType("");
                      setError(null);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg="12">
                      <div className="mb-2">
                        <Col lg="12">
                          <Label for="filterBy">Filter By</Label>
                        </Col>
                        <ReactSelect
                          options={columnList.map((column) => ({
                            value: column.value,
                            label: column.label,
                          }))}
                          value={selectedOption}
                          styles={SelectStyle}
                          onChange={(selected) => {
                            setSelectedOption(selected);
                            setFilterType(selected.label);
                            setColumnName(selected.value);
                          }}
                          placeholder="Select Filter"
                        />
                      </div>
                    </Col>
                    {filterType === "Transaction Date" ? (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">Select {filterType}</Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="filterValue"
                            placeholder={`Enter ${filterType}`}
                            onChange={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                transactionDate: e.target.value,
                              }));
                              setFilterValue("e");
                            }}
                            value={state.transactionDate}
                          />
                        </div>
                      </Col>
                    ) : filterType === "Agent Name" ? (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">Enter {filterType}</Label>
                          <RequiredAsterisk />
                          <Input
                            type="text"
                            className="form-control"
                            id="filterValue"
                            placeholder={`Enter ${filterType}`}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setState((prevState) => ({
                                ...prevState,
                                agentName: inputValue,
                              }));
                              setError(
                                inputValue.trim() === ""
                                  ? "Please Enter the Value"
                                  : null
                              );
                              setFilterValue("e");
                            }}
                            value={state.agentName}
                            invalid={error ? true : false}
                          />
                          {error ? (
                            <span
                              className="text-danger"
                              style={{ "font-size": "80%" }}
                            >
                              {error}
                            </span>
                          ) : null}
                        </div>
                      </Col>
                    ) : filterType === "Branch" ? (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">{`Select ${filterType}`}</Label>
                          <RequiredAsterisk />
                          <ReactSelect
                            options={tranBranch}
                            value={state.branchName}
                            styles={SelectStyle}
                            onChange={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                branchName: e,
                              }));
                              setFilterValue("e");
                            }}
                            placeholder="Select Branch..."
                          />
                        </div>
                      </Col>
                    ) : filterType === "Currency" ? (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">{`Select ${filterType} (From Currency)`}</Label>
                          <RequiredAsterisk />
                          <ReactSelect
                            options={currency}
                            value={state.currency}
                            styles={SelectStyle}
                            onChange={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                currency: e,
                              }));
                              setFilterValue("e");
                            }}
                            placeholder="Select Currency..."
                          />
                        </div>
                      </Col>
                    ) : filterType === "Status" ? (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">{`Select ${filterType}`}</Label>
                          <RequiredAsterisk />
                          <ReactSelect
                            options={statusOptions}
                            value={state.status}
                            styles={SelectStyle}
                            onChange={(e) => {
                              setState((prevState) => ({
                                ...prevState,
                                status: e,
                              }));
                              setFilterValue("e");
                            }}
                            placeholder="Select Status..."
                          />
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={validation.handleSubmit}
                    disabled={state.submitting || !filterValue || !filterType}
                  >
                    Apply Filters
                  </button>
                  {clearFilters()}
                </div>
              </Modal>
              <div className="mb-2 d-flex justify-content-start gap-1 flex-wrap">
                <Button
                  type="button"
                  onClick={tog_addFilters}
                  color="primary"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Add Filters
                </Button>

                {resetTransaction()}
              </div>

              <div className="mt-3 mb-3 w-100">
                {showFilterApplied ? (
                  <div className="card shadow-sm p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        {/* <FaFilter className="me-2" size={20} color="#007bff" /> */}
                        <span className="fw-bold">Filter By:</span>
                        <span className="ms-2">
                          {getFilterLabel(showFilterApplied.columnName)}
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="fw-bold">Filter Value:</span>
                        <span className="ms-2">
                          {getFilterValue(showFilterApplied)}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-muted p-3">
                    <span className="fw-bold">No filter applied</span>
                  </div>
                )}
              </div>

              {isLoading ? (
                <Loader />
              ) : (
                <TableContainer
                  columns={columns}
                  data={transactionHistoryData}
                  customPageSize={10}
                  isPageSelect={false}
                  customId={"transactionDate"}
                  desc={true}
                  onPageChange={handleChange}
                  totalPage={totalPages}
                  totalRecord={totalRecord}
                  currentPage={state.currentPage}
                  goToPage={true}
                  clearFilters={state.clearFiltersClicked}
                  changePageOnResult={modal_filter}
                />
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default React.memo(withRouter(TransactionHistory));
