export const formatDateToUserTimeZone = (utcDateString) => {
  // Ensure the UTC string is parsed correctly
  const date = new Date(
    utcDateString.endsWith("Z") ? utcDateString : `${utcDateString}Z`
  );

  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format");
  }

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    // Convert the date to the user's local timezone
    const localDate = new Date(
      date.toLocaleString("en-US", { timeZone: userTimeZone })
    );

    // Extract components for YYYY-MM-DD
    const year = String(localDate.getFullYear()); // Full year
    const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Month (1-based)
    const day = String(localDate.getDate()).padStart(2, "0"); // Day

    // Extract components for HH:MM:SS
    const hours = String(localDate.getHours()).padStart(2, "0"); // Hours
    const minutes = String(localDate.getMinutes()).padStart(2, "0"); // Minutes
    const seconds = String(localDate.getSeconds()).padStart(2, "0"); // Seconds

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // Format: YYYY-MM-DD HH:MM:SS
  } catch (error) {
    console.error("Error formatting date:", error);
    return null; // Return null as a fallback value
  }
};
