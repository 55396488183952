import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Loader background
const LoaderBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  // z-index: 9999;
`;

// Loader container
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Rotating dot circle
const DotCircle = styled.div`
  position: relative;
  width: 40px; /* Smaller size */
  height: 40px; /* Smaller size */
  animation: ${rotate} 1.5s linear infinite;
`;

// Individual dot
const Dot = styled.div`
  position: absolute;
  width: 6px; /* Smaller dots */
  height: 6px; /* Smaller dots */
  background-color: ${(props) => props.color || "black"};
  border-radius: 50%; /* Perfectly round */
  transform: translate(-50%, -50%);

  &:nth-child(1) {
    top: 0;
    left: 50%;
  }
  &:nth-child(2) {
    top: 15%;
    left: 85%;
  }
  &:nth-child(3) {
    top: 50%;
    left: 100%;
  }
  &:nth-child(4) {
    top: 85%;
    left: 85%;
  }
  &:nth-child(5) {
    top: 100%;
    left: 50%;
  }
  &:nth-child(6) {
    top: 85%;
    left: 15%;
  }
  &:nth-child(7) {
    top: 50%;
    left: 0;
  }
  &:nth-child(8) {
    top: 15%;
    left: 15%;
  }
`;

const LoaderText = styled.span`
  margin-top: 15px;
  font-size: 18px;
  color: ${(props) => props.textColor || "black"};
  text-align: center;
`;

const CustomLoader = ({
  text = "Loading...",
  textColor = "black",
  color = "black",
}) => {
  return (
    <LoaderBackground>
      <LoaderContainer>
        <DotCircle>
          <Dot color={color} />
          <Dot color={"gray"} />
          <Dot color={color} />
          <Dot color={"gray"} />
          <Dot color={color} />
          <Dot color={"gray"} />
          <Dot color={color} />
          <Dot color={"gray"} />
        </DotCircle>
        {text && <LoaderText textColor={textColor}>{text}</LoaderText>}
      </LoaderContainer>
    </LoaderBackground>
  );
};

export default CustomLoader;
