import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Input,
  Label,
  Row,
  CardTitle,
  Form,
  TabContent,
  TabPane,
  Container,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getApiData, postApiData } from "../../../helpers/axiosHelper";
import { useLatestTransactions } from "../../../common/data/latest-transaction-context";
import ReactSelect from "react-select";
import SelectStyle from "../../../common/data/SelectStyle";
import BeneficiaryModal from "../../Beneficiary/BeneficiaryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { SelectStyleForMenu } from "../../../common/data/SelectStyleForMenu";
import Help from "../../../components/Common/Help";
import { baseCurrency, roundOff } from "../../../common/data/currency";
import { TransactionType } from "../../../common/data/commonFunction";
import { BaseCurrencyContext } from "../../../common/data/context";
import { ClipLoader } from "react-spinners";
import RequiredAsterisk from "../../../components/Common/RequiredAsterisk";
import Loader from "../../../components/Common/Loader";
import CustomLoader from "../../../components/Common/Spinner";
import { formatDateToUserTimeZone } from "../../../common/date";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-between">
        <div>
          <div>{props.data.label}</div>
          <div className="text-light" style={{ fontSize: "0.8em" }}>
            {props.data.address}
          </div>
        </div>
        <div style={{ fontSize: "0.9em" }}>{props.data.availableAmount}</div>
      </div>
    </components.Option>
  );
};

const AddTransaction = ({ reload }) => {
  //   let wireFee = parseFloat(wireTransferFee || 0);
  const navigate = useNavigate();

  const [optime, setOptime] = useState({});
  const [pickupDate, setPickupDate] = useState(null);
  const [currencyIcons, setCurrencyIcons] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [conValue, setConValue] = useState(false);
  const [customActiveTab, setCustomActiveTab] = useState("1");

  const [branch, setBranch] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [currentRateType, setCurrentRateType] = useState(2);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
  const [agentCharges, setAgentCharges] = useState(0);
  const [commissionCharges, setCommissionCharges] = useState(0);
  const [wireTransferFee, setWireTransferFee] = useState(0);
  const [totalFees, setTotalFees] = useState(0);

  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [availableAmount, setAvailableAmount] = useState(0);

  const [buyingCurrency, setBuyingCurrency] = useState([]);
  const [sellingCurrency, setSellingCurrency] = useState([]);
  const [transferCurrency, setTransferCurrency] = useState([]);

  const [convertedAmountBuy, setConvertedAmountBuy] = useState(0);
  const [convertedAmountBuyWithDecimals, setConvertedAmountBuyWithDecimals] =
    useState(0);
  const [convertedAmountSell, setConvertedAmountSell] = useState("");
  const [convertedAmountSellWithDecimals, setConvertedAmountSellWithDecimals] =
    useState("");

  const [convertedAmountTransfer, setConvertedAmountTransfer] = useState();

  const [fromCurrencyValueBuying, setFromCurrencyValueBuying] = useState("");
  const [
    fromCurrencyValueBuyingWithDecimals,
    setFromCurrencyValueBuyingWithDecimals,
  ] = useState(0);
  const [fromCurrencyValueSelling, setFromCurrencyValueSelling] = useState("");
  const [
    fromCurrencyValueSellingWithDecimals,
    setFromCurrencyValueSellingWithDecimals,
  ] = useState("");
  const [buyRateHistoryID, setBuyRateHistoryID] = useState(0);

  const [totalConvertibleAmount, setTotalConvertibleAmount] = useState(0);
  const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);
  const [
    fromCurrencyValueTransferWithDecimals,
    setFromCurrencyValueTransferWithDecimals,
  ] = useState(0);
  const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);
  const [
    toCurrencyValueTransferWithDecimals,
    setToCurrencyValueTransferWithDecimals,
  ] = useState(0);
  const [selectedToCurrencySelling, setSelectedToCurrencySelling] =
    useState("");
  const [selectedFromCurrencyBuying, setSelectedFromCurrencyBuying] =
    useState(null);
  const [selectedToCurrencyTransfer, setSelectedToCurrencyTransfer] =
    useState(null);
  const [enableTransfer, setEnableTransfer] = useState(true);

  const { branchId } = useLatestTransactions();
  const [startDate, setStartDate] = useState(null);
  const currentDate = new Date();
  const placeholderDate = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(
    2,
    "0"
  )} ${String(currentDate.getHours()).padStart(2, "0")}:${String(
    currentDate.getMinutes()
  ).padStart(2, "0")}`;

  const [currencies, setCurrencies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

  const [showBeneficiary, setShowBeneficiary] = useState(false);
  const selectedToCurrencyBuying = "SGD";
  const selectedFromCurrencySelling = "SGD";
  const [errors, setErrors] = useState({});
  const [documentData, setDocumentData] = useState([]);
  const { selectedContextCurrency, currencyOptions, transferCurrencies } =
    useContext(BaseCurrencyContext);
  const isSGD =
    selectedContextCurrency && selectedContextCurrency.value === "SGD";
  const [isNoTransfer, setIsNoTransfer] = useState(false);

  const [transferBranch, setTransferBranch] = useState(null);
  const [beneficiaryError, setBeneficiaryError] = useState("");
  const fromCurrencyTransfer = selectedContextCurrency?.value;
  const [rateHistoryId, setRateHistoryId] = useState(null);
  const [showCharges, setShowCharges] = useState(false);

  const toggleCustom = (tab) => {
    // if (customActiveTab !== tab) {
    setCustomActiveTab(tab);
    resetTransaction();
    getAvaCurrency(tab);
    if (tab === "3") {
      getAvaBeneficiary();
      // resetTransaction();
      setSelectedBeneficiary(null);
    }
    // }
  };

  useEffect(() => {
    if (customActiveTab === "1") {
      toggleCustom("1");
    }
  }, [customActiveTab]);

  // useEffect(() => {
  //   // if (
  //   //   selectedContextCurrency === null ||
  //   //   branchId === null ||
  //   //   selectedContextCurrency === "undefined"
  //   // ) {

  //   //   setIsLoading(true);
  //   // } else setIsLoading(false);
  //   if (selectedContextCurrency?.value !== "SGD") {
  //     toggleCustom("3");
  //   }
  //   console.log(isLoading);
  // }, [selectedContextCurrency]);

  useEffect(() => {
    if (selectedContextCurrency?.value !== "SGD") {
      toggleCustom("3");
    }
  }, [selectedContextCurrency]);

  const handlePrecision = (value, decimals = 9) => {
    const multiplier = Math.pow(10, decimals);
    return Math.round(value * multiplier) / multiplier;
  };

  const getCurrencyFlag = (currencyCode, currencyName) => {
    if (!currencyCode) return "";
    const currency = currencies.find(
      (c) => c.currencyCode && c.currencyCode.trim() === currencyCode.trim()
    );
    return currency ? `data:image/png;base64,${currency.currencyFlag}` : "";
  };

  const validateAmountLength = (amount) => {
    return amount.length <= 8;
  };

  const toggleAddModal = () => {
    setShowBeneficiary(!showBeneficiary);

    setSearchTerm("");
  };

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await getApiData("api/RateMaster/GetAllCurrencies");
        const icons = {};
        response.data.forEach((currency) => {
          icons[
            currency.currencyCode.trim()
          ] = `data:image/png;base64,${currency.currencyFlag}`;
        });
        setCurrencyIcons(icons);
        setCurrencies(response.data);
        if (response.success === true) {
          setCurrencies(response.data);
        } else {
          setCurrencies([]);
        }
      } catch (error) {
        setCurrencies([]);
        console.error("Failed to fetch currencies:", error);
      }
    };

    const fetchGlobalTransferRates = async () => {
      try {
        const response = await getApiData(
          "api/RateMaster/GetGlobalTransferRate"
        );
        if (response.success === true) {
          const mappedTransferRates = response.data.map((rate) => ({
            rateHistoryId: rate.rateHistoryId,
            currencyCode: rate.currencyCode,
            currencyName: rate.currencyName,
            rate: rate.value,
            worldRate: parseFloat(rate.worldRate),
            transferRate: parseFloat(rate.transferRate),
            wireTransferFee: parseFloat(rate.wireTransferFee),
            agentCharges: parseFloat(rate.agentCharges),
            commissionCharges: parseFloat(rate.commissionCharges),
            totalFeesAdmin: parseFloat(rate.totalFeesAdmin),
          }));

          setGlobalTransferData(mappedTransferRates);
        }
      } catch (error) {
        toast.error(
          "Ratesheet not available for transfer operation, please contact Admin",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
      }
    };

    fetchCurrencies();
    fetchGlobalTransferRates();
  }, []);

  const [buyRate, setBuyRate] = useState(null);
  const toCurrencyTransfer = selectedBeneficiary?.currencyName;
  const [globalTransferData, setGlobalTransferData] = useState(null);
  const transferData = globalTransferData?.find((x) => {
    const targetCurrency = toCurrencyTransfer;
    return x.currencyCode === targetCurrency;
  });

  const transferRate = transferData ? parseFloat(transferData.rate) : 0;

  const calculateFee = (fee, fromCurrency, toCurrency, buyRate) => {
    if (fromCurrency === "SGD") {
      return fee;
    } else if (toCurrency === "SGD") {
      return fee / buyRate;
    } else {
      return fee / buyRate;
    }
  };

  let wireFee = parseFloat(transferData ? transferData.wireTransferFee : 0);
  let commissionFee = parseFloat(
    transferData ? transferData.commissionCharges : 0
  );
  let agentFee = parseFloat(transferData ? transferData.agentCharges : 0);

  const convertedWireFee = calculateFee(
    wireFee,
    fromCurrencyTransfer,
    toCurrencyTransfer,
    buyRate
  );
  console.log("Wire fee " + convertedWireFee);
  const convertedCommissionFee = calculateFee(
    commissionFee,
    fromCurrencyTransfer,
    toCurrencyTransfer,
    buyRate
  );
  const convertedAgentFee = calculateFee(
    agentFee,
    fromCurrencyTransfer,
    toCurrencyTransfer,
    buyRate
  );
  const validateForm = () => {
    const errors = {};
    const today = new Date();
    // Validate 'from' amount input
    const fromAmount = parseFloat(fromCurrencyValueBuying);
    if (isNaN(fromAmount) || fromAmount <= 0) {
      errors.fromAmount = "Please enter a valid positive amount.";
    }

    // Validate 'converted' amount calculation
    const convertedAmount = parseFloat(convertedAmountBuy);
    if (isNaN(convertedAmount) || convertedAmount <= 0) {
      errors.convertedAmount =
        "Converted amount cannot be a negative, please enter a higher value of SGD amount";
    }

    // Check for selected currencies
    if (!selectedFromCurrencyBuying) {
      errors.selectedFromCurrency =
        "Please select a currency to exchange from.";
    }
    if (!selectedToCurrencyBuying) {
      errors.selectedToCurrency = "Please select a currency to exchange to.";
    }

    // Prevent exchanging the same currency
    if (selectedFromCurrencyBuying?.value === selectedToCurrencyBuying) {
      errors.selectedFromCurrencyBuying = "Cannot exchange the same currency.";
    }

    // Validate branch selection

    if (!selectedBranch) {
      errors.branch = "Please select a branch.";
    }

    // Date validation
    if (!startDate) {
      errors.date = "Please select a pickup date";
    }
    if (startDate && startDate < today) {
      errors.date = "Please select a future date.";
    }

    return errors; // Return all collected errors
  };

  const validateSellForm = () => {
    const errors = {};
    const today = new Date();

    // Validate 'from' amount input
    const fromAmount = parseFloat(fromCurrencyValueSelling);
    if (isNaN(fromAmount) || fromAmount <= 0) {
      errors.fromAmount = "Please enter a valid positive amount.";
    }

    // Validate 'converted' amount calculation
    const convertedAmount = parseFloat(convertedAmountSell);
    if (isNaN(convertedAmount) || convertedAmount <= 0) {
      errors.convertedAmount =
        "Converted amount cannot be a negative, please enter a higher value of SGD amount";
    }

    // Check for selected currencies
    if (!selectedFromCurrencySelling) {
      errors.selectedFromCurrencySelling =
        "Please select a currency to exchange from.";
    }
    if (!selectedToCurrencySelling) {
      errors.selectedToCurrencySelling =
        "Please select a currency to exchange to.";
    }

    // Prevent exchanging the same currency
    if (selectedFromCurrencySelling === selectedToCurrencySelling?.value) {
      errors.selectedToCurrencySelling = "Cannot exchange the same currency.";
    }

    // Validate branch selection
    if (!selectedBranch) {
      errors.branch = "Please select a branch.";
    }

    // Date validation
    if (!startDate) {
      errors.date = "Please select a pickup date";
    }
    if (startDate && startDate < today) {
      errors.date = "Please select a future date.";
    }

    return errors; // Return all collected errors
  };

  function findTransactionType() {
    if (customActiveTab === "1") {
      return 2;
    } else if (customActiveTab === "2") {
      return 1;
    } else if (customActiveTab === "3") {
      return 3;
    }
  }

  const setExchangeRate = () => {
    if (fromCurrencyTransfer === "SGD") {
      return transferRate;
    } else if (toCurrencyTransfer === "SGD") {
      return buyRate;
    } else {
      return (buyRate / transferRate).toFixed(10);
    }
  };
  const Days = {
    0: "AlL Days",
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
  };

  const resetTransaction = () => {
    setIsLoading(false);
    setBranch([]);
    setSelectedBranch(null);
    setSelectedFromCurrencyBuying(null);
    setFromCurrencyValueTransfer(null);
    setFromCurrencyValueTransferWithDecimals(null);
    setToCurrencyValueTransferWithDecimals(null);
    setFromCurrencyValueBuying("");
    setFromCurrencyValueBuyingWithDecimals(0);
    setSelectedToCurrencySelling(null);
    setSelectedToCurrencyTransfer(null);
    setToCurrencyValueTransfer("");
    setFromCurrencyValueSelling("");
    setFromCurrencyValueTransfer("");
    setSelectedBeneficiary("");
    setConvertedAmountBuy(0);
    setConvertedAmountSell("");
    setConvertedAmountTransfer("");
    setErrors([]);
    setShowCharges(false);
  };
  const getAvaCurrency = async (tab) => {
    try {
      let apiUrl = "";
      const rateTypes = { 1: 2, 2: 1, 3: 3 };
      const rateType = rateTypes[tab];

      if (!rateType) return;

      apiUrl =
        tab === "3"
          ? `api/RateMaster/GetGlobalTransferRate`
          : `api/RateMaster/GetCurrencyForBST?rateType=${rateType}`;

      // Fetch API data
      const apiResponse = await getApiData(apiUrl);
      if (!apiResponse || !apiResponse.data)
        throw new Error("Invalid API response");
      if (apiResponse.sucesss === false) {
        setIsNoTransfer(true);
        toast.error(
          "Ratesheet not available for transfer operation, please contact Admin",
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
        return;
      }

      // Map and filter currency data
      const formattedCurrency = apiResponse.data.map(
        ({
          currencyName,
          currencyCode,
          value, // Value: rate for rateType 3, currency code for rateType 1 and 2
          wireTransferFee,
          agentCharges,
          commissionCharges,
          totalFeesAdmin,
          transferRate,
          buyRateMainBranch,
          buyRateHistoryIdMainBranch,
        }) => {
          const currencyObj = {
            label: currencyName,
            value: currencyCode, // Default to currency code
          };

          // For rateType 3, set 'value' as the rate
          if (rateType === 3) {
            return {
              ...currencyObj,
              rate: parseFloat(value), // Set rate as value for rateType 3
              wireTransferFee: parseFloat(wireTransferFee),
              agentCharges: parseFloat(agentCharges),
              commissionCharges: parseFloat(commissionCharges),
              totalFeesAdmin: parseFloat(totalFeesAdmin),
              transferRate: parseFloat(transferRate),
              buyRateMainBranch: parseFloat(buyRateMainBranch),
              buyRateHistoryIdMainBranch: buyRateHistoryIdMainBranch,
            };
          } else {
            // For rateType 1 and 2, set 'value' as the currency code
            return {
              ...currencyObj,
              value: currencyCode, // Currency code for rateType 1 and 2
            };
          }
        }
      );

      // Filter unique currencies
      const seen = new Set();
      const uniqueCurrencies = formattedCurrency.filter(({ label, value }) => {
        if (!label || !value) return false;
        if ((rateType === 1 || rateType === 2) && value === "SGD") return false; // Exclude SGD for rateType 1 and 2
        if (seen.has(label)) return false;
        seen.add(label);
        return true;
      });

      // Set state based on the tab
      if (tab === "1") {
        setBuyingCurrency(uniqueCurrencies);
      } else if (tab === "2") {
        setSellingCurrency(uniqueCurrencies);
      } else if (tab === "3") {
        setTransferCurrency(uniqueCurrencies);
        console.log("transferCurrency " + transferCurrency);
        setConValue(apiResponse.data.value); // Additional processing if required
      }
    } catch (error) {
      console.error("Error fetching currencies:", error);
      setIsNoTransfer(true);
      toast.error("Transfer rates not available, please contact Admin", {
        position: "top-right",
        autoClose: 1500,
      });
      // toggleCustom("1");
    }
  };

  // Example: Display charges when a currency is selected
  const handleCurrencySelectForTransfer = () => {
    const fromCurr = selectedToCurrencyTransfer?.value;
    const toCurr = selectedBeneficiary?.currencyName;

    const buyRateMainBranch = transferCurrency.find(
      (x) => x.value === fromCurr
    )?.rate;

    const foreignCurrencyObj = transferCurrency.find((x) => x.value === toCurr);

    const transfer = "3";

    const transfer2ndScenario =
      customActiveTab === transfer &&
      fromCurr !== baseCurrency &&
      toCurr === baseCurrency;

    const transfer3rdScenario =
      customActiveTab === transfer &&
      fromCurr !== baseCurrency &&
      toCurr !== baseCurrency;

    const { agentCharges, commissionCharges, wireTransferFee, totalFeesAdmin } =
      transfer2ndScenario || transfer3rdScenario
        ? selectedToCurrencyTransfer
        : foreignCurrencyObj;

    if (transfer2ndScenario || transfer3rdScenario) {
      setAgentCharges(agentCharges / buyRateMainBranch);
      setCommissionCharges(commissionCharges / buyRateMainBranch);
      setWireTransferFee(wireTransferFee / buyRateMainBranch);
      setTotalFees(totalFeesAdmin / buyRateMainBranch);
    } else {
      setAgentCharges(agentCharges);
      setCommissionCharges(commissionCharges);
      setWireTransferFee(wireTransferFee);
      setTotalFees(totalFeesAdmin);
    }
  };
  const getBranchForSelling = async (currencyCode, rateType) => {
    try {
      let avaBranch = null;

      avaBranch = await getApiData(
        `api/BranchMaster/GetSellBranchesByCurrency?currencyCode=${currencyCode}`
      );

      if (!avaBranch || !avaBranch.data || avaBranch.data.length === 0) {
        console.warn("No branches found for the given criteria.");
        return;
      }

      //filter the active branches only
      if (avaBranch.success === true) {
        const formattedSellBranch = avaBranch.data.map((branch) => ({
          active: branch.branch.active,
          value: branch.branch.branchId,
          label: branch.branch.branchName,
          conversionValue: branch.rateValue,
          agentCharges: branch.agentCharges || 0,
          rateHistoryId: branch.rateHistoryId,
          address: `${branch.branch.street}, ${branch.branch.city}, ${branch.branch.postalCode}`,
        }));

        setBranch(formattedSellBranch);
        setCurrentRateType(rateType);
        setSelectedCurrencyCode(currencyCode);
        setConValue(formattedSellBranch.conversionValue);
        // if (formattedBranch.length > 0) {
        //   // getBranchOpTime(formattedBranch[0].value);
        //   // getCharges(formattedBranch[0].value, rateType, currencyCode);
        // }
      }
    } catch (error) {
      console.error("Error fetching branches", error);
    }
  };

  const getBranchByCurrCode = async (currencyCode, rateType) => {
    try {
      let avaBranch = null;
      if (rateType === 2) {
        avaBranch = await getApiData(
          `api/RateMaster/GetBranchForCurrency?currencyCode=${currencyCode}&rateType=${rateType}`
        );
      } else if (rateType === 1) {
        avaBranch = await getApiData(
          `api/BranchMaster/GetSellBranchesByCurrency?currencyCode=${currencyCode}`
        );
      } else {
        avaBranch = await getApiData(`api/BranchMaster/GetAllBranch`);
        const branchForTransfer = avaBranch.data.filter(
          (branch) => branch.isMainBranch === true
        );
        setTransferBranch(branchForTransfer[0].branchId);
        console.log("Main " + transferBranch);
      }

      if (!avaBranch || !avaBranch.data || avaBranch.data.length === 0) {
        console.warn("No branches found for the given criteria.");
        return;
      }

      //filter the active branches only
      const activeBranches = avaBranch.data.filter(
        (branch) => branch.active === true
      );
      // Create a Map to store the most appropriate branch for each branchName
      const branchMap = new Map();

      activeBranches.forEach((branch) => {
        const existingBranch = branchMap.get(branch.branchName);

        // If the current branch has allBranch = 0, or if there's no existing branch
        // with this name or if the existing branch has allBranch = 1, replace it.
        if (
          !existingBranch ||
          (existingBranch.allBranch === 1 && branch.allBranch === 0)
        ) {
          branchMap.set(branch.branchName, branch);
        }
      });
      const uniqueBranches = Array.from(branchMap.values());

      const formattedBranch = uniqueBranches.map((branch) => ({
        label: branch.branchName,
        value: branch.branchId,
        address: `${branch.street}, ${branch.city}, ${branch.postalcode}`,
        availableAmount: rateType === 1 ? undefined : branch.availableAmount,
        conversionValue: branch.value,
        rateHistoryId: branch.rateHistoryId,
        buyRateMainBranch: parseFloat(branch.buyRateMainBranch),
        buyRateHistoryIdMainBranch: branch.buyRateHistoryIdMainBranch,
      }));

      setBranch(formattedBranch);
      setCurrentRateType(rateType);
      setSelectedCurrencyCode(currencyCode);
      setConValue(formattedBranch.conversionValue);
      if (formattedBranch.length > 0) {
        // getBranchOpTime(formattedBranch[0].value);
        // getCharges(formattedBranch[0].value, rateType, currencyCode);
      }
    } catch (error) {
      console.error("Error fetching branches", error);
    }
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const meridiem = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${meridiem}`;
  };

  const getBranchOpTime = async (branchId) => {
    try {
      const branchResponse = await getApiData(
        `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
      );

      const operationalTime = branchResponse.data.operationalTime[0];
      if (operationalTime) {
        const startTime12 = convertTo12HourFormat(operationalTime.startTime);
        const endTime12 = convertTo12HourFormat(operationalTime.endTime);

        setOptime({
          startTime: startTime12,
          endTime: endTime12,
        });
      }
    } catch (error) {
      toast.error("Error fetching branch operational time", {
        autoClose: 3000,
      });
    }
  };

  const getCharges = async (currentRateHistoryId) => {
    try {
      const chargesResponse = await getApiData(
        `api/RateMaster/GetTransactionCharges?rateHistoryId=${currentRateHistoryId}`
      );
      setAgentCharges(chargesResponse.data.agentCharges);
    } catch (error) {
      toast.error("Error fetching the Charges", {
        autoClose: 3000,
      });
    }
  };

  const getAvaBeneficiary = async () => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/AgentBeneficiary/GetAllByAgentBeneficiary`
      );
      setIsLoading(false);

      console.log("Currencies from transfer sheet " + transferCurrencies);

      const filteredResponse = response.data.filter(
        (item) =>
          item.status === 2 &&
          item.active === 1 &&
          item.currencyCode !== fromCurrencyTransfer
        // &&
        // transferCurrencies.some(
        //   (option) => option.value === item.currencyCode
        // )
      );

      const mappedResponse = filteredResponse.map((item) => ({
        label: item.beneficiaryName,
        value: item.companyBenId,
        currencyName: item.currencyName.split("(")[1]?.replace(")", "") || "",
      }));
      // const filteredByCurrency = mappedResponse.filter((item) => {
      //   return transferCurrency.some(
      //     (currency) => currency.value === item.currencyName
      //   );
      // });

      setBeneficiary(mappedResponse);
    } catch (error) {
      toast.error("Error fetching beneficiaries", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleSubmitBuying = async (e) => {
    e.preventDefault();
    if (parseFloat(convertedAmountBuy) > availableAmount) {
      {
        toast.error("Insufficient stock", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
        });
        return;
      }
    } else {
      setIsLoading(true); // Start loading

      const errors = validateForm();

      // Check if there are any errors
      if (Object.keys(errors).length > 0) {
        setErrors(errors); // Update error state
        setIsLoading(false);
        return; // Stop form submission if there are errors
      }

      // Clear errors if validation passes
      setErrors({});

      const buyingData = {
        fromCurrType: selectedToCurrencyBuying,
        toCurrType: selectedFromCurrencyBuying.value,
        toCurrValue: handlePrecision(convertedAmountBuyWithDecimals),
        fromCurrValue: handlePrecision(fromCurrencyValueBuyingWithDecimals),
        fromBranchId: selectedBranch.value,
        transactionType: findTransactionType(),
        pickup: startDate ? startDate : "",
        agentBranchID: branchId,
        exchangeRate: selectedBranch.conversionValue,
        toRateHistoryId: buyRateHistoryID,
      };

      try {
        const response = await postApiData(
          "api/Transaction/InsertTransaction",
          buyingData
        );
        if (response.success === true) {
          toast.success("Transaction request has been completed ", {
            position: "top-right",
            autoClose: 3000,
            onClose: () => {
              setIsLoading(false);
            },
          });
          reload();
        } else {
          Swal.fire({
            text: response.message + "! Please contact the Admin",
            icon: "info",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Ok",
            confirmButtonColor: "#556ee6",
          });
          setIsLoading(false);
        }
        setSelectedFromCurrencyBuying("");
        setFromCurrencyValueBuying("");
        setFromCurrencyValueBuyingWithDecimals(0);
        setBranch(null);
      } catch (error) {
        toast.error(error, {
          position: "top-right",
          autoClose: 3000,
        });
        resetTransaction();
      }
    }
  };

  const handleSubmitSelling = async (e) => {
    e.preventDefault();

    const errors = validateSellForm();

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      setErrors(errors); // Update error state
      setIsLoading(false);
      return; // Stop form submission if there are errors
    }

    // Clear errors if validation passes
    setErrors({});

    const sellingData = {
      fromCurrType: selectedToCurrencySelling.value,
      toCurrType: selectedFromCurrencySelling,
      toCurrValue: handlePrecision(convertedAmountSellWithDecimals),
      fromCurrValue: handlePrecision(fromCurrencyValueSellingWithDecimals),
      fromBranchId: selectedBranch.value,
      transactionType: findTransactionType(),
      pickup: startDate ? startDate : "",
      agentBranchID: branchId,
      exchangeRate: selectedBranch.conversionValue,
      fromRateHistoryId: buyRateHistoryID,
    };
    try {
      setIsLoading(true);
      const response = await postApiData(
        "api/Transaction/InsertTransaction",
        sellingData
      );
      if (response.success === true) {
        toast.success("Transaction request has been completed", {
          position: "top-right",
          autoClose: 3000,
          onClose: () => {
            setIsLoading(false);
          },
        });
        reload();
      } else {
        Swal.fire({
          text: response.message + "! Please contact the admin ",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Ok",
          confirmButtonColor: "#556ee6",
        });
        setIsLoading(false);
      }
      setSelectedToCurrencySelling("");
      setFromCurrencyValueSelling("");
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
      resetTransaction();
    }
  };

  const handleSubmitTransfer = async (e) => {
    e.preventDefault();

    const fromRateHistoryId =
      fromCurrencyTransfer === "SGD"
        ? globalTransferData?.find(
            (x) => x.currencyCode === fromCurrencyTransfer
          )?.rateHistoryId
        : rateHistoryId;

    if (!fromRateHistoryId) {
      console.error("Rate history ID is missing.");
    }

    const toRateHistory = globalTransferData?.find(
      (x) => x.currencyCode === toCurrencyTransfer
    );

    if (selectedBeneficiary === null || selectedBeneficiary === "") {
      toast.error("Please select a Beneficiary.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (!toCurrencyTransfer) {
      toast.error("Please select the currencies you want to exchange.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (!fromCurrencyValueTransfer) {
      toast.error("Please Enter Amount you want to Exchange.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    // if (!validateAmountLength(fromCurrencyValueTransfer)) {
    //   toast.error("Exchange Amount cannot exeed 5 digit", {
    //     position: "top-right",
    //     autoClose: 3000,
    //   });
    //   setFromCurrencyValueTransfer("");
    //   return;
    // }
    if (selectedBeneficiary?.currencyName === toCurrencyTransfer.value) {
      toast.error("Cannot exchange same currency.", {
        position: "top-right",
        autoClose: 3000,
      });
      setFromCurrencyValueTransfer("");
      return;
    }
    const transferData = {
      toCompanyBenId: selectedBeneficiary?.value,
      fromCurrType: fromCurrencyTransfer,
      fromCurrValue: handlePrecision(fromCurrencyValueTransfer),
      exchangeRate: setExchangeRate(),
      toCurrType: toCurrencyTransfer,
      toCurrValue: handlePrecision(toCurrencyValueTransferWithDecimals),

      fromBranchId: transferBranch,
      transactionType: findTransactionType(),
      // pickup: startDate ? startDate : "",
      agentBranchID: branchId,
      //   ...(selectedToCurrencyTransfer.value === "SGD"
      //     ? { toRateHistoryId: buyRateHistoryID }
      //     : { fromRateHistoryId: buyRateHistoryID }),
      // };
      fromRateHistoryId: fromRateHistoryId,
      toRateHistoryId: toRateHistory?.rateHistoryId,
      buyRateHistoryIdMainBranch:
        fromCurrencyTransfer !== "SGD" ? fromRateHistoryId : null,
    };

    try {
      setIsLoading(true);
      const response = await postApiData(
        "api/Transaction/InsertTransaction",
        transferData
      );
      if (response.success === true) {
        toast.success("Transaction request has been completed", {
          position: "top-right",
          autoClose: 3000,
          onClose: () => {
            setIsLoading(false);
          },
        });
        reload();
      } else {
        Swal.fire({
          text: response.message + "! Please contact the admin ",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Ok",
          confirmButtonColor: "#556ee6",
        });
        setIsLoading(false);
      }
      setSelectedToCurrencyTransfer("");
      setSelectedBeneficiary("");
      setToCurrencyValueTransfer("");
      setFromCurrencyValueTransfer("");
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
      resetTransaction();
    }
  };

  const convertFromCurrency = (e) => {
    const inputValue = e.target.value.trim();

    // Clear states and errors when input is empty
    if (inputValue === "") {
      setFromCurrencyValueSelling("");
      setConvertedAmountSell("");
      setErrors({ fromAmount: "", convertedAmount: "" });
      return;
    }

    // Validate if the input is a valid number
    const amount = parseFloat(inputValue);
    if (isNaN(amount)) {
      setErrors((prev) => ({
        ...prev,
        fromAmount: "Please enter a valid number.",
      }));
      return;
    }

    // Check for negative amounts
    if (amount < 0) {
      setErrors((prev) => ({
        ...prev,
        fromAmount: "Amount cannot be negative.",
      }));
      setConvertedAmountSell(""); // Clear the conversion result
      return;
    }

    setFromCurrencyValueSelling(amount);
    setFromCurrencyValueSellingWithDecimals(amount);
    setErrors((prev) => ({ ...prev, fromAmount: "" })); // Clear previous errors

    if (selectedBranch.conversionValue) {
      const agentChargesValue = parseFloat(agentCharges) || 0; // Default to 0 if invalid

      // Validate if amount is greater than agent charges
      if (amount <= agentChargesValue) {
        setErrors((prev) => ({
          ...prev,
          fromAmount: `Amount must be greater than agent charges (${agentChargesValue.toFixed(
            2
          )}).`,
        }));
        setConvertedAmountSell("");
        return;
      }

      // Calculate conversion
      const convertedAmount =
        amount * selectedBranch.conversionValue - agentChargesValue;

      // Ensure converted amount is positive
      if (convertedAmount <= 0) {
        setErrors((prev) => ({
          ...prev,
          convertedAmount:
            "Converted amount must be greater than zero. Please enter a higher value.",
        }));
        setConvertedAmountSell("");
        return;
      }

      // Update successful state values
      setConvertedAmountSell(convertedAmount.toFixed(2));
      setConvertedAmountSellWithDecimals(convertedAmount);
      setTotalConvertibleAmount(
        (amount * selectedBranch.conversionValue).toFixed(2)
      );
      setErrors((prev) => ({ ...prev, convertedAmount: "" }));
    }
  };

  const convertToCurrency = (e) => {
    const inputValue = e.target.value.trim();

    // Clear input state and errors if input is empty
    if (inputValue === "") {
      setFromCurrencyValueSelling("");
      setConvertedAmountSell("");
      setErrors((prev) => ({
        ...prev,
        fromAmount: "",
        convertedAmount: "",
      }));
      return;
    }

    const amount = parseFloat(inputValue);

    // Check if amount is not a number or negative
    if (isNaN(amount) || amount < 0) {
      setErrors((prev) => ({
        ...prev,
        convertedAmount: "Amount cannot be negative.",
        fromAmount: "",
      }));
      return;
    }

    // Clear errors if the input is valid
    setErrors((prev) => ({ ...prev, convertedAmount: "", fromAmount: "" }));

    // Update state with valid amount
    setConvertedAmountSell(amount);
    setConvertedAmountSellWithDecimals(amount);

    if (selectedBranch.conversionValue) {
      const agentChargesValue =
        parseFloat(agentCharges) > 0 ? parseFloat(agentCharges) : 0;
      const convertedFromCurrency =
        (amount + agentChargesValue) / selectedBranch.conversionValue;

      setFromCurrencyValueSellingWithDecimals(convertedFromCurrency);
      setFromCurrencyValueSelling(convertedFromCurrency.toFixed(2));
      setTotalConvertibleAmount(
        (convertedFromCurrency * selectedBranch.conversionValue).toFixed(2)
      );

      // Validate the final converted amount
      if (convertedFromCurrency <= 0) {
        setErrors((prev) => ({
          ...prev,
          fromAmount: "Amount must be greater than zero after conversion.",
        }));
      }
    }
  };

  useEffect(() => {
    if (customActiveTab === "3" && isNoTransfer === false) {
      getAvaBeneficiary();
      // resetTransaction();
      setSelectedBeneficiary(null);
      getAvaCurrency(customActiveTab);
    }
  }, [fromCurrencyTransfer]);

  useEffect(() => {
    if (selectedFromCurrencyBuying) {
      getBranchByCurrCode(
        selectedFromCurrencyBuying.value,
        TransactionType.Buy
      );
    }
  }, [selectedFromCurrencyBuying]);

  useEffect(() => {
    if (selectedToCurrencySelling) {
      getBranchForSelling(
        selectedToCurrencySelling.value,
        TransactionType.Sell
      );
    }
  }, [selectedToCurrencySelling]);

  // useEffect(() => {
  //   if (selectedBranch) getBranchOpTime(selectedBranch.value);
  // }, [selectedBranch]);

  useEffect(() => {
    if (selectedBeneficiary?.currencyName && customActiveTab === "3") {
      const currencyToPass =
        selectedBeneficiary.currencyName === baseCurrency
          ? selectedToCurrencyTransfer?.value
          : selectedBeneficiary?.currencyName;

      getBranchByCurrCode(currencyToPass, TransactionType.Transfer);
    }
  }, [selectedBeneficiary, selectedToCurrencyTransfer]);

  useEffect(() => {
    if (customActiveTab === "3" && fromCurrencyTransfer !== undefined) {
      getAvaBeneficiary();
    }
    if (toCurrencyTransfer === "SGD" && buyRate === 0) {
      setEnableTransfer(false);
      Swal.fire({
        text: "Transfer not available for this Currency!",
        icon: "info",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          resetTransaction();
        }
      });
    }
    if (
      toCurrencyTransfer !== undefined &&
      toCurrencyTransfer !== "SGD" &&
      transferRate === 0
    ) {
      setEnableTransfer(false);
      Swal.fire({
        text: "Transfer not available for this Beneficiary!",
        icon: "info",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          resetTransaction();
        }
      });
    } else {
      setEnableTransfer(true);
    }
    if (fromCurrencyTransfer === toCurrencyTransfer) {
      resetTransaction();
    }
    if (fromCurrencyTransfer !== "SGD" && fromCurrencyTransfer !== undefined) {
      fetchTransfer(fromCurrencyTransfer);
    }
  }, [fromCurrencyTransfer, toCurrencyTransfer, customActiveTab, transferRate]);

  // useEffect(() => {
  //   const fetchCurrencies = async () => {
  //     try {
  //       const response = await getApiData("api/RateMaster/GetAllCurrencies");
  //       const icons = {};
  //       response.data.forEach((currency) => {
  //         icons[
  //           currency.currencyCode.trim()
  //         ] = `data:image/png;base64,${currency.currencyFlag}`;
  //       });
  //       setCurrencyIcons(icons);
  //       setCurrencies(response.data);
  //     } catch (error) {
  //       toast.error("Error fetching currencies");
  //     }
  //   };
  //   fetchCurrencies();
  // }, []);

  const fetchTransfer = async (fromCurr) => {
    setIsLoading(true);
    const resp = await getApiData(
      `api/RateMaster/GetTransactionRate?fromCurr=${fromCurr}`
    );
    if (resp.success === true) {
      setRateHistoryId(resp.data.rateHistoryId);
      setBuyRate(parseFloat(resp.data.buyRate));
    } else {
      setRateHistoryId(null);
      setBuyRate(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (fromCurrencyTransfer !== "SGD" && fromCurrencyTransfer !== undefined) {
      fetchTransfer(fromCurrencyTransfer);
    } else {
      console.log("not");
    }
    setFromCurrencyValueTransfer(0);
    setToCurrencyValueTransfer(0);
  }, [fromCurrencyTransfer]);

  const handleFromCurrencyConversion = (e) => {
    const inputValue = e.target.value.trim();
    if (inputValue === "") {
      setFromCurrencyValueBuying("");
      // Clear the input state
      setFromCurrencyValueBuyingWithDecimals(0);
      setConvertedAmountBuy(0); // Clear the conversion result
      setConvertedAmountBuyWithDecimals(0);
      setErrors((prev) => ({
        ...prev,
        fromAmount: "",
        convertedAmount: "",
      }));
      return;
    }
    const amount = parseFloat(inputValue);

    if (amount < 0) {
      setErrors((prev) => ({
        ...prev,
        fromAmount: "Amount cannot be negative.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, fromAmount: "" }));
    }

    setFromCurrencyValueBuying(amount);
    setFromCurrencyValueBuyingWithDecimals(amount);
    const agentChargesValue = parseFloat(agentCharges) || 0;
    if (selectedBranch?.conversionValue) {
      const convertedAmtBuy =
        (amount - agentChargesValue) / selectedBranch.conversionValue;

      const formattedAmount = convertedAmtBuy.toFixed(2);
      setConvertedAmountBuy(parseFloat(formattedAmount));
      setConvertedAmountBuyWithDecimals(convertedAmtBuy);

      // Prevent negative converted values
      if (convertedAmtBuy <= 0) {
        setErrors((prev) => ({
          ...prev,
          convertedAmount:
            "Converted amount cannot be negative, please enter a higher value of SGD",
        }));
        return;
      } else setErrors((prev) => ({ ...prev, convertedAmount: "" })); // Clear any prior errors
    }
  };

  const handleToCurrencyConversion = (e) => {
    const inputValue = e.target.value.trim();
    if (inputValue === "") {
      setFromCurrencyValueBuying(""); // Clear the input state
      setFromCurrencyValueBuyingWithDecimals(0);
      setConvertedAmountBuy(""); // Clear the conversion result
      setConvertedAmountBuyWithDecimals(0);

      setErrors((prev) => ({
        ...prev,
        fromAmount: "",
        convertedAmount: "",
      }));
      return;
    }
    const amount = parseFloat(inputValue);

    if (amount < 0) {
      setErrors((prev) => ({
        ...prev,
        convertedAmount: "Amount cannot be negative.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, convertedAmount: "" }));
    }

    setConvertedAmountBuy(amount);
    setConvertedAmountBuyWithDecimals(amount);

    if (selectedBranch?.conversionValue) {
      const originalAmount = parseFloat(
        amount * selectedBranch.conversionValue + agentCharges
      );

      setFromCurrencyValueBuying(originalAmount.toFixed(2));
      setFromCurrencyValueBuyingWithDecimals(parseFloat(originalAmount));
    }
  };

  const convertFromTransferCurrency = (e) => {
    const inputValue = e.target.value;

    // setFromCurrencyDisplay(inputValue);

    if (inputValue === "") {
      setFromCurrencyValueTransfer("");
      setToCurrencyValueTransfer("");
      setErrors({
        fromCurrency: "",
        toCurrency: "",
      });
      return;
    }

    if (inputValue.length > 10) return;

    let convertedValue = 0;
    const amount = parseFloat(inputValue) || 0;
    const totalFee =
      (convertedWireFee || 0) +
      (convertedCommissionFee || 0) +
      (convertedAgentFee || 0);

    setFromCurrencyValueTransfer(inputValue);

    if (amount <= 10 || amount < totalFee) {
      setErrors({
        fromCurrency: `Amount must be greater than 10, total fees (${totalFee.toFixed(
          2
        )}), and cannot be negative.`,
        toCurrency: "",
      });
      setToCurrencyValueTransfer(0);
      // setToCurrencyDisplay("0.00");
      return;
    }

    if (fromCurrencyTransfer === "SGD") {
      // Scenario 1: From currency is SGD
      if (transferRate > 0) {
        convertedValue = (amount - totalFee) / transferRate;
      } else {
        setErrors({
          fromCurrency: "Transfer rate is invalid.",
          toCurrency: "",
        });
        setToCurrencyValueTransfer(0);
        return;
      }
    } else if (toCurrencyValueTransfer === "SGD") {
      // Scenario 2: To currency is SGD
      if (buyRate > 0) {
        convertedValue = amount * buyRate - totalFee;

        if (convertedValue < 1) {
          setErrors({
            fromCurrency: "",
            toCurrency: "Converted SGD cannot be less than 1",
          });
          setToCurrencyValueTransfer(0);
          // setToCurrencyDisplay("0.00");
          return;
        }
      } else {
        setErrors({
          fromCurrency: "Invalid buy rate for conversion.",
          toCurrency: "",
        });
        setToCurrencyValueTransfer(0);
        // setToCurrencyDisplay("0.00");
        return;
      }
    } else {
      // Scenario 3: Neither currency is SGD
      if (buyRate <= 0 || transferRate <= 0) {
        setErrors({
          fromCurrency: "Invalid buy or transfer rates.",
          toCurrency: "",
        });
        setToCurrencyValueTransfer(0);
        // setToCurrencyDisplay("0.00");
        return;
      }

      const subAmount = amount - totalFee;
      const convertedSGD = subAmount * buyRate;
      convertedValue = convertedSGD / transferRate;
    }

    setErrors({
      fromCurrency: "",
      toCurrency: "",
    });

    setToCurrencyValueTransfer(convertedValue.toFixed(2));
    setToCurrencyValueTransferWithDecimals(convertedValue);
  };

  const convertToTransferCurrency = (e) => {
    const inputValue = e.target.value;

    // setToCurrencyDisplay(inputValue);

    if (inputValue === "") {
      setToCurrencyValueTransfer("");
      // setToCurrencyDisplay("");
      setErrors({
        fromCurrency: "",
        toCurrency: "",
      });
      return;
    }

    if (inputValue.length > 10) return;

    let convertedValue = 0;
    const amount = parseFloat(inputValue) || 0;
    const totalFee =
      (convertedWireFee || 0) +
      (convertedCommissionFee || 0) +
      (convertedAgentFee || 0);

    setToCurrencyValueTransfer(inputValue);
    setToCurrencyValueTransferWithDecimals(inputValue);

    if (amount <= 0 || amount < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        toCurrency: `Amount must be greater than 10 and cannot be negative.`,
      }));
      setFromCurrencyValueTransfer(0);
      // setFromCurrencyDisplay("0.00");
      return;
    }

    if (fromCurrencyTransfer === "SGD") {
      // Scenario 1: From currency is SGD
      if (transferRate > 0) {
        convertedValue = amount * transferRate + totalFee;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          toCurrency: "Invalid transfer rate.",
        }));
        setFromCurrencyValueTransfer(0);
        // setFromCurrencyDisplay("0.00");
        return;
      }
    } else if (toCurrencyValueTransfer === "SGD") {
      // Scenario 2: To currency is SGD
      if (buyRate > 0) {
        convertedValue = (amount + totalFee) / buyRate;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          toCurrency: "Invalid buy rate for conversion.",
        }));
        setFromCurrencyValueTransfer(0);
        // setFromCurrencyDisplay("0.00");
        return;
      }
    } else {
      // Scenario 3: Neither currency is SGD
      if (buyRate <= 0 || transferRate <= 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          toCurrency: "Invalid buy or transfer rates.",
        }));
        setFromCurrencyValueTransfer(0);
        // setFromCurrencyDisplay("0.00");
        return;
      }

      const convertedSGD = amount * transferRate;
      convertedValue = convertedSGD / buyRate + totalFee;
    }

    setErrors({
      fromCurrency: "",
      toCurrency: "",
    });

    setFromCurrencyValueTransfer(convertedValue.toFixed(2));
    setFromCurrencyValueTransferWithDecimals(convertedValue);
  };

  const filteredBeneficiaries = beneficiary.filter((b) =>
    b.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (filteredBeneficiaries.length === 0 && typedBeneficiaryName) {
    filteredBeneficiaries.push({
      label: `Create "${typedBeneficiaryName}" Beneficiary`,
      value: "create-new",
    });
  }

  if (filteredBeneficiaries.length === 0) {
    filteredBeneficiaries.push({
      label: `Create New Beneficiary`,
      value: "create-new",
    });
  }

  const openADDBeneficiary = () => {
    toggleAddModal();
    setSearchTerm("");
  };

  const getDocument = async (compBenId) => {
    const docResponse = await getApiData(
      `api/Agentbeneficiary/GetBeneficiaryDocByCompanyBenId?CompanyBenId=${compBenId}`
    );
    const document = await docResponse.data;
    setDocumentData(document);
  };

  const handleBranchChange = (selectedOption) => {
    setSelectedBranch(selectedOption);
    if (currentRateType === 2) {
      setFromCurrencyValueBuying("");
      setConvertedAmountBuy("");
    }
    if (currentRateType === 1) {
      setFromCurrencyValueSelling("");
      setConvertedAmountSell("");
    }
    if (currentRateType === 1 || currentRateType === 2) {
      setAvailableAmount(selectedOption?.availableAmount);
      setConValue(selectedOption?.conversionValue);
      setBuyRateHistoryID(selectedOption?.rateHistoryId);
      // getCharges(selectedOption.value, currentRateType, selectedCurrencyCode);
      getCharges(selectedOption?.rateHistoryId);
    } else {
      handleCurrencySelectForTransfer(selectedOption);
    }
    getBranchOpTime(selectedOption?.value);
  };

  const handleCurrencyChangeForBuying = (selectedOption) => {
    setSelectedFromCurrencyBuying(selectedOption);
    setSelectedBranch(null);
    setAvailableAmount(null);
    setStartDate(null);
    setFromCurrencyValueBuying(null);
    setFromCurrencyValueBuyingWithDecimals(0);
  };

  const handleCurrencyChangeForSelling = (selectedOption) => {
    setSelectedToCurrencySelling(selectedOption);
    setSelectedBranch(null);
    setFromCurrencyValueSelling(null);
    setFromCurrencyValueSellingWithDecimals(0);
    setConvertedAmountSell(0);
    setConvertedAmountSellWithDecimals(0);
  };

  const handleDateChange = (date) => {
    // Check if the selected date is valid (for example, after the current date)
    if (date && date < new Date()) {
      setErrors({ ...errors, date: "Please select a future date." }); // Invalid date, show error
    } else {
      setErrors({ ...errors, date: "" }); // Reset error if date is valid
    }
    setStartDate(date); // Update selected date
  };

  if (!selectedContextCurrency || !selectedContextCurrency.value) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#3498db" size={50} />
        <p className="loading-text">Loading...</p> {/* The loading message */}
      </div>
    );
  }

  return (
    <React.Fragment>
      <BeneficiaryModal
        isOpen={showBeneficiary}
        currencies={currencies}
        toggle={toggleAddModal}
        createBenFor={toCurrencyTransfer}
        getAvaBeneficiary={getAvaBeneficiary}
        editMode={3}
      />

      <ToastContainer closeButton={false} limit={1} />
      {isLoading ? (
        <div className="loader-container">
          <CustomLoader />
        </div>
      ) : (
        <Container
          style={{
            marginTop: "10rem",
            marginBottom: "40px",
            minHeight: "900px",
          }}
        >
          <Card>
            <CardBody>
              <h4 className="card-title mb-4 d-flex justify-content-between">
                Add Transaction
                <Help to={"HELP DOCUMENTATION FOR ADD TRANSACTION"} />{" "}
              </h4>
              {/* {isLoading ? (
                <h6>Loading...</h6>
              ) : (
                <> */}
              {isSGD ? (
                <>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: isSGD ? "pointer" : "not-allowed", // Only apply pointer cursor if selectedContextCurrency is "SGD"
                          opacity: isSGD ? 1 : 0.5, // Only apply opacity dimming if not SGD
                        }}
                        className={classnames({
                          active: customActiveTab === "1",
                          disabled: !isSGD,
                          // Add the 'disabled' class only if it's not SGD
                        })}
                        onClick={(e) => {
                          if (!isSGD) {
                            e.preventDefault(); // Prevent click if the currency is not SGD
                          } else {
                            toggleCustom("1"); // Allow tab toggle if the currency is SGD
                          }
                        }}
                      >
                        <span className="d-block d-sm-none">
                          {/* <i className="fas fa-home">Buying</i> */}
                          Buying
                        </span>
                        <span className="d-none d-sm-block">Buying</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: isSGD ? "pointer" : "not-allowed", // Only apply pointer cursor if selectedContextCurrency is "SGD"
                          opacity: isSGD ? 1 : 0.5, // Only apply opacity dimming if not SGD
                        }}
                        className={classnames({
                          active: customActiveTab === "2",
                          disabled: !isSGD, // Add the 'disabled' class only if it's not SGD
                        })}
                        onClick={(e) => {
                          if (!isSGD) {
                            e.preventDefault(); // Prevent click if the currency is not SGD
                          } else {
                            toggleCustom("2"); // Allow tab toggle if the currency is SGD
                          }
                        }}
                      >
                        <span className="d-block d-sm-none">
                          {/* <i className="far fa-user">Selling</i> */}
                          Selling
                        </span>
                        <span className="d-none d-sm-block">Selling</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleCustom("3");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          {/* <i className="far fa-envelope">Transfer</i> */}
                          Transfer
                        </span>
                        <span className="d-none d-sm-block">Transfer</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </>
              ) : (
                <Row>
                  <Label className="text-center text-primary font-size-20">
                    Transfer Form
                  </Label>
                </Row>
              )}

              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <Form onSubmit={handleSubmitBuying}>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>You Give</Label>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #ced4da",
                              background: "#F8F8F8",
                              padding: "1px",
                              marginRight: "2px",
                              marginLeft: "3px",
                              borderRadius: "3px",
                            }}
                          >
                            <img
                              src={getCurrencyFlag(selectedToCurrencyBuying)}
                              alt={`${selectedToCurrencyBuying} flag`}
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "2px",
                                marginLeft: "2px",
                              }}
                            />

                            <Input
                              type="text"
                              style={{
                                border: "1px",
                                fontWeight: "450",
                                fontSize: "0.8rem",
                              }}
                              disabled
                              placeholder="You Give"
                              value={selectedToCurrencyBuying || ""}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>You Get</Label>

                          <ReactSelect
                            value={selectedFromCurrencyBuying}
                            onChange={handleCurrencyChangeForBuying}
                            options={buyingCurrency}
                            formatOptionLabel={({ value, label }) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={currencyIcons[value]}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    marginRight: "5px",
                                  }}
                                />
                                {label}
                              </div>
                            )}
                            placeholder="Select a Currency"
                            isClearable
                          />
                          {errors.selectedFromCurrencyBuying && (
                            <div
                              className="text-danger"
                              style={{ fontSize: "0.8em" }}
                            >
                              {errors.selectedFromCurrencyBuying}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-1">
                      <Label>Branch</Label>

                      <ReactSelect
                        minMenuHeight={500}
                        id="branch"
                        styles={SelectStyle}
                        value={selectedBranch}
                        // isDisabled={fromCurrencyValueBuying}
                        onChange={handleBranchChange}
                        options={branch}
                        components={{ Option: CustomOption }}
                      />
                    </div>
                    {errors.branch && (
                      <div className="error-message">{errors.branch}</div>
                    )}

                    <div className="mb-2">
                      {selectedBranch && (
                        <div>
                          <span
                            style={{
                              color: "red",
                              fontSize: "10px",
                            }}
                          >
                            {" "}
                            Available Stock: {availableAmount}
                          </span>
                        </div>
                      )}
                    </div>

                    {selectedBranch && (
                      <div className="mb-3">
                        <Row>
                          <Col lg={12}>
                            <Label>
                              Choose Pickup date - {" (Operating hours: "}
                              {optime
                                ? `${optime.startTime} - ${optime.endTime})`
                                : ""}
                            </Label>
                            <DatePicker
                              selected={startDate}
                              onChange={handleDateChange}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={30}
                              dateFormat="yyyy-MM-dd - h:mm aa"
                              timeCaption="Time"
                              className="form-control"
                              placeholderText={
                                "Choose a pickup data here - -(YYYY-MM-DD)"
                              }
                            />
                          </Col>
                        </Row>
                        {errors.date && (
                          <div
                            className="text-danger"
                            style={{ fontSize: "0.8em" }}
                          >
                            {errors.date}
                          </div>
                        )}
                      </div>
                    )}

                    {selectedBranch && (
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label>
                              SGD Amount ( Exchange Rate : {conValue}){" "}
                            </Label>
                            <Input
                              type="number"
                              // step="0.01"
                              className="form-control"
                              placeholder="Enter Amount"
                              value={fromCurrencyValueBuying}
                              onFocus={(e) => {
                                e.currentTarget.onwheel = (event) =>
                                  event.preventDefault();
                              }}
                              onChange={handleFromCurrencyConversion}
                            />
                            {errors.fromAmount && (
                              <div
                                className="text-danger"
                                style={{ fontSize: "0.8em" }}
                              >
                                {errors.fromAmount}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {/* hide scrollbar */}
                    <style>{`
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
  }
`}</style>
                    {selectedBranch &&
                      agentCharges !== "" &&
                      agentCharges > 0 && (
                        <Row className="mb-3 text-end">
                          <Col lg={6} className="text-start">
                            <span>Agent Charges</span>
                          </Col>
                          <Col lg={6}>
                            <span>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="me-1"
                                style={{
                                  fontSize: "9px",
                                  color: "#d3d3d3",
                                }}
                              />
                              {agentCharges || 0} SGD
                            </span>
                          </Col>
                        </Row>
                      )}
                    {selectedBranch &&
                      agentCharges !== "" &&
                      agentCharges > 0 && (
                        <Row className="mb-3 text-end">
                          <Col lg={6} className="text-start">
                            <span>Total Convertible Amount</span>
                          </Col>
                          <Col lg={6}>
                            <span>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="me-1"
                                style={{
                                  fontSize: "9px",
                                  color: "#d3d3d3",
                                }}
                              />
                              {fromCurrencyValueBuying
                                ? fromCurrencyValueBuying - agentCharges
                                : 0}{" "}
                              SGD
                            </span>
                          </Col>
                        </Row>
                      )}
                    {selectedBranch && (
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label>
                              {selectedFromCurrencyBuying?.value} Amount
                            </Label>
                            <Input
                              type="number"
                              // step="0.01"
                              value={convertedAmountBuy}
                              placeholder="Enter Amount"
                              onFocus={(e) => {
                                e.currentTarget.onwheel = (event) =>
                                  event.preventDefault();
                              }}
                              onChange={handleToCurrencyConversion}
                            />
                            {errors.convertedAmount && (
                              <div
                                className="text-danger"
                                style={{ fontSize: "0.8em" }}
                              >
                                {errors.convertedAmount}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="d-flex justify-content-between mt-3 mb-3">
                      <Button
                        disabled={isLoading}
                        onClick={resetTransaction}
                        className="btn btn-danger w-md"
                      >
                        Cancel
                      </Button>

                      <Button
                        disabled={isLoading}
                        onClick={handleSubmitBuying}
                        className="btn bg-primary w-md"
                      >
                        {isLoading ? "Submitting..." : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </TabPane>
                <TabPane tabId="2">
                  <Form onSubmit={handleSubmitSelling}>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>You Give</Label>
                          <ReactSelect
                            value={selectedToCurrencySelling}
                            // onChange={setSelectedToCurrencySelling}
                            onChange={handleCurrencyChangeForSelling}
                            options={sellingCurrency}
                            formatOptionLabel={({ value, label }) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={currencyIcons[value]}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    marginRight: "5px",
                                  }}
                                />
                                {label}
                              </div>
                            )}
                            placeholder="Select a Currency"
                            isClearable
                          />
                          {errors.selectedToCurrencySelling && (
                            <div
                              className="text-danger"
                              style={{ fontSize: "0.8em" }}
                            >
                              {errors.selectedToCurrencySelling}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label>You Get</Label>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #ced4da",
                              background: "#F8F8F8",
                              padding: "1px",
                              marginRight: "2px",
                              marginLeft: "3px",
                              borderRadius: "3px",
                            }}
                          >
                            <img
                              src={getCurrencyFlag(selectedFromCurrencySelling)}
                              alt={`${selectedFromCurrencySelling} flag`}
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "2px",
                                marginLeft: "2px",
                              }}
                            />

                            <Input
                              type="text"
                              style={{
                                border: "1px",
                                fontWeight: "450",
                                fontSize: "0.8rem",
                              }}
                              disabled
                              placeholder="You Get"
                              value={selectedFromCurrencySelling || ""}
                            />
                            {errors.selectedFromCurrencySelling && (
                              <div
                                className="text-danger"
                                style={{ fontSize: "0.8em" }}
                              >
                                {errors.selectedFromCurrencySelling}
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <Label>Branch</Label>
                      <Select
                        id="branch"
                        value={selectedBranch}
                        onChange={handleBranchChange}
                        options={branch}
                        components={{ Option: CustomOption }}
                      />
                    </div>
                    {selectedBranch && (
                      <div className="mb-3">
                        <Row>
                          <Col lg={12}>
                            <Label>
                              Choose Pickup Date - {" (Operating hours: "}
                              {optime
                                ? `${optime.startTime} - ${optime.endTime})`
                                : ""}
                            </Label>
                            <DatePicker
                              selected={startDate}
                              onChange={handleDateChange}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={30}
                              dateFormat="yyyy-MM-dd - h:mm aa"
                              timeCaption="Time"
                              className="form-control"
                              placeholderText={
                                "Choose a pickup date here - -(YYYY-MM-DD)"
                              }
                            />
                          </Col>
                        </Row>
                        {errors.date && (
                          <div
                            className="text-danger"
                            style={{ fontSize: "0.8em" }}
                          >
                            {errors.date}
                          </div>
                        )}
                      </div>
                    )}
                    {selectedBranch && (
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label>
                              {/* Enter{" "}
                            {selectedToCurrencySelling?.value
                              ? selectedToCurrencySelling.value
                              : selectedToCurrencySelling}{" "}
                            Amount ( Exchange Rate : {conValue}){" "} */}
                              <p>
                                Enter {""}
                                {selectedToCurrencySelling?.value ||
                                  "Default Currency"}{" "}
                                Amount (Exchange Rate: {conValue})
                              </p>
                            </Label>
                            <Input
                              type="number"
                              // step="0.01"
                              className="form-control"
                              placeholder="Enter Amount"
                              onFocus={(e) => {
                                e.currentTarget.onwheel = (event) =>
                                  event.preventDefault();
                              }}
                              value={fromCurrencyValueSelling}
                              onChange={convertFromCurrency}
                            />
                            {errors.fromAmount && (
                              <div
                                className="text-danger"
                                style={{ fontSize: "0.8em" }}
                              >
                                {errors.fromAmount}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    {selectedBranch &&
                      agentCharges !== "" &&
                      agentCharges > 0 && (
                        <Row className="mb-3 text-end">
                          <Col lg={6} className="text-start">
                            <span>Agent Charges</span>
                          </Col>
                          <Col lg={6}>
                            <span>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="me-1"
                                style={{
                                  fontSize: "9px",
                                  color: "#d3d3d3",
                                }}
                              />
                              {agentCharges > 0 ? agentCharges : 0}{" "}
                              {selectedToCurrencySelling
                                ? selectedToCurrencySelling.value
                                : ""}
                            </span>
                          </Col>
                        </Row>
                      )}
                    {selectedBranch &&
                      agentCharges !== "" &&
                      agentCharges > 0 && (
                        <Row className="mb-3 text-end">
                          <Col lg={6} className="text-start">
                            <span>Total Converted Amount</span>
                          </Col>
                          <Col lg={6}>
                            <span>
                              <FontAwesomeIcon
                                icon={faCircle}
                                className="me-1"
                                style={{
                                  fontSize: "9px",
                                  color: "#d3d3d3",
                                }}
                              />
                              {totalConvertibleAmount}{" "}
                              {selectedToCurrencySelling
                                ? selectedToCurrencySelling.value
                                : ""}
                            </span>
                          </Col>
                        </Row>
                      )}

                    {selectedBranch && (
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label>SGD Amount</Label>
                            <Input
                              type="number"
                              // step="0.01"
                              value={convertedAmountSell}
                              onFocus={(e) => {
                                e.currentTarget.onwheel = (event) =>
                                  event.preventDefault();
                              }}
                              onChange={convertToCurrency}
                            />
                            {errors.convertedAmount && (
                              <div
                                className="text-danger"
                                style={{ fontSize: "0.8em" }}
                              >
                                {errors.convertedAmount}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        disabled={isLoading}
                        onClick={resetTransaction}
                        className="btn btn-danger w-md"
                      >
                        Cancel
                      </Button>

                      <Button
                        disabled={isLoading}
                        className="btn bg-primary w-md"
                        onClick={handleSubmitSelling}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </TabPane>
                <TabPane tabId="3">
                  <Form onSubmit={handleSubmitTransfer}>
                    <Row>
                      <Col md={12}>
                        <div className="mb-2">
                          <Label>Select Beneficiary</Label>
                          <RequiredAsterisk></RequiredAsterisk>
                          <ReactSelect
                            styles={SelectStyle}
                            value={selectedBeneficiary}
                            placeholder={
                              beneficiary.length
                                ? "Select Beneficiary"
                                : "No beneficiaries available"
                            }
                            onInputChange={(inputValue) => {
                              setSearchTerm(inputValue);
                              setTypedBeneficiaryName(inputValue);
                            }}
                            onChange={(selectedOption) => {
                              if (selectedOption?.value === "create-new") {
                                openADDBeneficiary(typedBeneficiaryName); // Pass typed name to the modal
                              } else {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  fromCurrency: "",
                                  toCurrency: "",
                                }));
                                resetTransaction();
                                setBeneficiaryError("");
                                setSelectedBeneficiary(selectedOption);
                              }
                            }}
                            options={[
                              ...beneficiary.map((option) => ({
                                value: option.value,
                                label: option.label,
                                currencyName: option.currencyName,
                                icon: getCurrencyFlag(option.currencyName),
                              })),
                              ...(typedBeneficiaryName &&
                              !beneficiary.some(
                                (b) =>
                                  b.label.toLowerCase() ===
                                  typedBeneficiaryName.toLowerCase()
                              )
                                ? [
                                    {
                                      value: "create-new",
                                      label: `Create New "${typedBeneficiaryName}"`,
                                      currencyName: "",
                                      icon: null,
                                    },
                                  ]
                                : []),
                            ]}
                            formatOptionLabel={({
                              label,
                              icon,
                              currencyName,
                            }) => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {/* Beneficiary Name */}
                                <span>{label}</span>

                                {/* Flag and Currency Code */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {icon && (
                                    <img
                                      src={icon}
                                      alt={`${currencyName} flag`}
                                      style={{
                                        width: 20,
                                        height: 20,
                                        marginRight: 5,
                                      }}
                                    />
                                  )}
                                  {currencyName && (
                                    <span
                                      style={{ fontSize: 12, color: "#666" }}
                                    >
                                      {currencyName}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                            isClearable
                          />

                          {beneficiaryError && (
                            <div
                              className="text-danger mt-1"
                              style={{ fontSize: "0.8em" }}
                            >
                              {beneficiaryError}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={6}>
                        <Label>You Give</Label>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            background: "#F8F8F8",
                            padding: "2px",
                          }}
                        >
                          <img
                            src={getCurrencyFlag(fromCurrencyTransfer)}
                            alt={`${fromCurrencyTransfer} flag`}
                          />
                          <Input
                            value={fromCurrencyTransfer}
                            disabled
                            style={{ border: "none" }}
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <Label>{fromCurrencyTransfer} Amount </Label>
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Enter Amount"
                          value={fromCurrencyValueTransfer}
                          disabled={!selectedBeneficiary}
                          onChange={convertFromTransferCurrency}
                          onFocus={(e) =>
                            (e.target.style.border = "2px solid #556ee6")
                          }
                        />
                        {errors.fromCurrency && (
                          <div
                            className="text-danger"
                            style={{ fontSize: "0.8em" }}
                          >
                            {errors.fromCurrency}
                          </div>
                        )}
                      </Col>
                    </Row>

                    {/* Updated rendering logic for the UI */}
                    {
                      selectedBeneficiary !== null &&
                      enableTransfer &&
                      showCharges ? (
                        <>
                          <Row className="mb-3 text-end mt-2">
                            <Col lg={6} className="text-start">
                              <span>Wire Transfer Fee</span>
                            </Col>

                            <Col lg={6}>
                              <span>
                                {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                                {isNaN(convertedWireFee)
                                  ? 0
                                  : convertedWireFee.toFixed(3)}{" "}
                                {fromCurrencyTransfer}
                              </span>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-end">
                            <Col lg={6} className="text-start">
                              <span>Commission Fee</span>
                            </Col>
                            <Col lg={6}>
                              <span>
                                {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                                {isNaN(convertedCommissionFee)
                                  ? 0
                                  : convertedCommissionFee.toFixed(3)}{" "}
                                {fromCurrencyTransfer}
                              </span>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-end">
                            <Col lg={6} className="text-start">
                              <span>Agent Fee</span>
                            </Col>
                            <Col lg={6}>
                              <span>
                                {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                                {isNaN(convertedAgentFee)
                                  ? 0
                                  : convertedAgentFee.toFixed(3)}{" "}
                                {fromCurrencyTransfer}
                              </span>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-end">
                            <Col lg={6} className="text-start">
                              <span>Total {fromCurrencyTransfer}</span>
                            </Col>
                            <Col lg={6}>
                              <span>
                                {isNaN(
                                  fromCurrencyValueTransfer -
                                    (convertedWireFee +
                                      convertedCommissionFee +
                                      convertedAgentFee)
                                )
                                  ? 0
                                  : (
                                      fromCurrencyValueTransfer -
                                      (convertedWireFee +
                                        convertedCommissionFee +
                                        convertedAgentFee)
                                    ).toFixed(2)}
                                {fromCurrencyTransfer}
                              </span>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-end">
                            <Col lg={6} className="text-start">
                              <span>Transfer Rate</span>
                            </Col>
                            <Col lg={6}>
                              <span>
                                {toCurrencyTransfer === "SGD" && !isNaN(buyRate)
                                  ? buyRate.toFixed(4)
                                  : fromCurrencyTransfer === "SGD" &&
                                    !isNaN(transferRate)
                                  ? transferRate.toFixed(4)
                                  : fromCurrencyValueTransfer > 0 &&
                                    !isNaN(buyRate) &&
                                    !isNaN(transferRate)
                                  ? (buyRate / transferRate).toFixed(4)
                                  : 0}
                              </span>
                            </Col>
                          </Row>
                        </>
                      ) : null
                      //  (
                      //   <div className="text-center mt-3 mb-3">
                      //     <h6>Please Select a Beneficiary</h6>
                      //   </div>
                      // )
                    }

                    <Row className="mb-3">
                      <Col md={6}>
                        <Label>Beneficiary Receives</Label>
                        {selectedBeneficiary ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "16px",
                            }}
                          >
                            <img
                              src={getCurrencyFlag(toCurrencyTransfer)}
                              alt={"Currency flag"}
                              style={{ width: "20px", marginRight: "5px" }}
                            />
                            <span>{selectedBeneficiary.currencyName}</span>
                          </div>
                        ) : (
                          <Input
                            type="text"
                            className="form-control"
                            disabled
                            value="Please select Beneficiary"
                          />
                        )}
                      </Col>

                      <Col md={6}>
                        <Label>{toCurrencyTransfer || "Currency"} Amount</Label>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="Enter Amount"
                          value={toCurrencyValueTransfer}
                          disabled={!selectedBeneficiary}
                          onChange={convertToTransferCurrency}
                          onFocus={(e) =>
                            (e.target.style.border = "2px solid #556ee6")
                          }
                        />
                        {errors.toCurrency && (
                          <div
                            className="text-danger"
                            style={{ fontSize: "0.8em" }}
                          >
                            {errors.toCurrency}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        disabled={isLoading}
                        onClick={resetTransaction}
                        className="btn btn-danger w-md"
                      >
                        Cancel
                      </Button>

                      <Button
                        disabled={
                          isLoading ||
                          fromCurrencyTransfer === toCurrencyTransfer
                        }
                        className="btn bg-primary w-md"
                        onClick={handleSubmitTransfer}
                      >
                        {isLoading ? "Loading..." : "Submit"}
                      </Button>
                    </div>
                  </Form>
                </TabPane>
              </TabContent>
              {/* </>
              )} */}
            </CardBody>
          </Card>
        </Container>
      )}
    </React.Fragment>
  );
};

export default AddTransaction;
