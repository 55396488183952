import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../Common/withRouter";
import Notifications from "./Notifications";
// users
import user1 from "../../../../images/users/avatar-1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleUser,
  faBell,
  faExclamationCircle,
  faInfoCircle,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [bellMenu, setBellMenu] = useState(false);
  const [agentName, setagentName] = useState("");
  const [authType, setAuthType] = useState(null);
  const [notificationCount] = useState(3);
  const [activeTab, setActiveTab] = useState("1");
  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (obj) {
      setagentName(obj.agentName);
      setAuthType(obj.authType);
    }
  }, []);

  const toggleBellMenu = () => setBellMenu(!bellMenu);
  const toggleTab = (tab) => setActiveTab(tab);
  return (
    <React.Fragment>
      <header className="header d-flex justify-content-between align-items-center">
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item "
            id="page-header-user-dropdown"
            tag="button"
          >
            {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          /> */}
            <FontAwesomeIcon
              style={{
                width: "40px",
                height: "20px",
                marginTop: "5px",
              }}
              icon={faCircleUser}
            />
            <span className="d-none d-xl-inline-block mt-3">{agentName}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/profile">
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {props.t("Profile")}{" "}
            </DropdownItem>
            {/* <DropdownItem tag="a" href="#">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem> */}
            {authType === 1 && (
              <DropdownItem tag="a" href="/changepassword">
                <i className="bx bx-wallet font-size-16 align-middle me-1" />
                {props.t("Change Password")}
              </DropdownItem>
            )}
            {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
        {/* Notification Bell Dropdown */}
        <Notifications />
      </header>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ProfileMenu));
