import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Collapse,
  Label,
  Badge,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormFeedback,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  postApiData,
  deleteApiData,
  getApiData,
  putApiData,
} from "../../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import { mapStatus } from "../../../common/data/statusLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faDownload,
  faEye,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { formatDateToUserTimeZone } from "../../../common/date";
import * as Yup from "yup";
import { useFormik } from "formik";

const ViewLatestTransactionModel = (props) => {
  const {
    isOpen,
    toggle,
    optime,
    branchData,
    commentData,
    sendTransactionDetails,
    reload,
    showAction,
    isBranchActive,
    beneficiaryDetails,
    chargesDetails,
    buyRateForCharges,
    documentData,
  } = props;

  console.log("document Data" + documentData);

  const [col_1, setCol_1] = useState(true);
  const [col_2, setCol_2] = useState(false);
  const [col_3, setCol_3] = useState(false);
  const [col_4, setCol_4] = useState(false);
  const [col_5, setCol_5] = useState(false);

  const [commentText, setCommentText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [viewDocName, setViewDocName] = useState("");
  const [docIsValid, setDocIsValid] = useState(true);
  const [viewDocument, setViewDocument] = useState("");
  const [docFormat, setDocFormat] = useState("");
  const [fileData, setFileData] = useState(null);
  const [isAddingDoc, setIsAddingDoc] = useState(false);
  const [docData, setDocData] = useState([]);

  console.log("doc Data" + docData);

  const toggleViewModal = () => setModal1(!modal1);

  const UserTypes = {
    1: "System User",
    2: "Customer",
    3: "Agent",
  };
  const BenAccountType = {
    1: "Personal",
    2: "Business",
  };

  const openCommentPopup = (status) => {
    setSelectedStatus(status);
    setIsCommentOpen(true);
  };

  const closeCommentPopup = () => {
    setIsCommentOpen(false);
    setCommentText("");
  };

  const handleCommentSubmit = async () => {
    const response = await postApiData("api/Transaction/AcknowledgeOrReject", {
      transactionId: sendTransactionDetails?.transactionId,
      comment: commentText,
      status: selectedStatus,
    });
    if (response.success === true) {
      toast.success("Comment submitted successfully.", {
        position: "top-right",
        autoClose: 2000,
      });
      toggle();
      closeCommentPopup();
      reload();
    } else {
      toast.error(response.message, {
        position: "top-right",
        autoClose: 2000,
      });
      toggle();
      closeCommentPopup();
    }
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function handleDocTypeChanged(e) {
    documentValidation.handleChange(e);
  }

  function getDocumentType(obj) {
    if (obj === "I") {
      return "Identity Proof";
    } else if (obj === "A") {
      return "Address Proof";
    } else if (obj === "O") {
      return "Others";
    } else {
      return "";
    }
  }

  function handleFileChange(e) {
    setDocIsValid(true);
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > 5000 * 1024) {
      toast.error("File Size Should be less than 5MB", {
        position: "top-right",
        autoClose: 2000,
      });
      documentValidation.resetForm();
      tog_standard();
      setDocIsValid(false);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const type = reader.result.split(";")[0];
      const docType = type.split("/")[1];
      let base64String = "";
      const indexOfComma = reader.result.indexOf(",");
      if (indexOfComma !== -1) {
        base64String = reader.result.substring(indexOfComma + 1);
      }
      setDocFormat(docType);
      setFileData(base64String);
    };
    reader.readAsDataURL(file);
  }

  const getFileType = (contentType) => {
    switch (contentType) {
      case "png":
        return "image/png";
      case "jpg":
        return "image/jpg";
      case "jpeg":
        return "image/jpeg";
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      default:
        return "application/octet-stream";
    }
  };

  const viewDoc = (index) => {
    if (!docData[index]) {
      console.error("Invalid document index.");
      return;
    }

    const {
      document: data,
      documentName: name,
      contentType: docFormat,
    } = docData[index];

    setViewDocument(data);
    setViewDocName(name);

    const lowerDocFormat = docFormat?.toLowerCase();

    if (["png", "jpg", "jpeg"].includes(lowerDocFormat)) {
      // View image in modal
      toggleViewModal();
    } else if (lowerDocFormat === "pdf") {
      // Open PDF in a new tab
      handleOpenPdf(index);
    } else {
      // Download other file types
      handleDownloadFile(index);
    }
  };
  const handleOpenPdf = (index) => {
    if (!docData[index]) {
      console.error("Invalid document index.");
      return;
    }

    const { document, documentFormat } = docData[index];

    // Decode Base64
    const byteChars = atob(document);
    const byteNumbers = Array.from(byteChars, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    // Create Blob with correct MIME type
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create object URL
    const url = URL.createObjectURL(blob);

    // Open PDF in a new tab
    window.open(url, "_blank");
  };

  const handleDownloadFile = (index) => {
    const { document, documentName, documentFormat } = docData[index];

    const byteChars = atob(document);
    const byteNumbers = Array.from(byteChars, (char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: getFileType(documentFormat) });
    const url = URL.createObjectURL(blob);

    const extension = documentFormat?.toLowerCase() || "bin"; // Default extension if missing
    const link = document.createElement("a");
    link.href = url;
    link.download = `${documentName || "document"}.${extension}`;
    link.click();

    URL.revokeObjectURL(url);
  };

  const deleteDoc = async (index) => {
    const docId = docData[index].docID;
    const response = await putApiData(
      `api/TransactionDocs/SoftDeleteDoc?DocID=${docId}&Active=false`
    );
    if (response.success === true) {
      toast.success("Document Deleted Successfully", {
        position: "top-right",
        autoClose: 1500,
      });
      getDocument(sendTransactionDetails?.transactionId);
    } else {
      toast.error("Error Deleting Document. Contact Admin", {
        position: "top-right",
        autoClose: 1500,
      });
    }
  };
  const getDocument = async (transID) => {
    const docResponse = await getApiData(
      `api/TransactionDocs/GetByTransactionId?TransactionId=${transID}`
    ).catch(() => {});

    const document = await docResponse.data.filter((x) => x.active === true);
    // const documents = (document && document.data) || [];
    setDocData(document);
  };

  const documentValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      // documentType: "",
      documentName: "",
      document: null,
      contentType: null,
    },

    validationSchema: Yup.object({
      // documentType: Yup.string().required("Please Select the Document Type"),
      documentName: Yup.string().required("Please Enter the Document Name"),
      document: Yup.string().required("Please Upload the Document"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsAddingDoc(true);
      console.log("Form Values Submitted:", values);
      console.log("Is File Valid:", docIsValid);
      const transID = sendTransactionDetails?.transactionId;

      if (docIsValid) {
        const combinedValues = {
          ...values,
          transactionID: transID,
          document: fileData,
          contentType: docFormat,
        };
        const response = await postApiData(
          `api/TransactionDocs/AddDoc`,
          combinedValues
        );
        if (response.success === true) {
          toast.success("Document Added Successfully", {
            position: "top-right",
            autoClose: 1500,
          });
          resetForm();
          tog_standard();
          getDocument(transID);
          // ben();

          setIsAddingDoc(false);
        } else {
          toast.error("Error Uploading Doc. Contact Admin", {
            position: "top-right",
            autoClose: 1500,
          });
          setIsAddingDoc(false);
        }
      } else {
        toast.warning("File Size Should be less than 5MB", {
          position: "top-right",
          autoClose: 2000,
        });
        setIsAddingDoc(false);
      }
      // } else {
      //   toast.info("Max Doc space has been reached.", {
      //     position: "top-right",
      //     autoClose: 3000,
      //   });
      //   setIsAddingDoc(false);
      // }
      resetForm();
      tog_standard();
    },
  });
  useEffect(() => {
    if (sendTransactionDetails?.status === 2) {
      setCol_3(true);
      setCol_1(false); // Open the collapse if transaction status is 2
    }
  }, [sendTransactionDetails?.status]);

  useEffect(() => {
    if (documentData !== null) {
      setDocData(documentData);
    }
  }, [documentData]);

  const calculateDateDifference = () => {
    const approvedDate = new Date(sendTransactionDetails?.approvedDate);
    const currentDate = new Date();

    // Extract the date part only
    const approvedDateOnly = approvedDate.toISOString().split("T")[0];
    const currentDateOnly = currentDate.toISOString().split("T")[0];

    // Compare the dates
    const diffInDays = Math.floor(
      (currentDate - approvedDate) / (1000 * 60 * 60 * 24)
    );

    return approvedDateOnly === currentDateOnly ? 0 : diffInDays;
  };

  return (
    <>
      {/*Main Modal*/}
      <Modal isOpen={isOpen} toggle={toggle} centered={true} autoClose={true}>
        <ModalHeader toggle={toggle}>
          {sendTransactionDetails?.transactionType === 2
            ? "(BUY)"
            : sendTransactionDetails?.transactionType === 1
            ? "(SELL)"
            : sendTransactionDetails?.transactionType === 3
            ? "(TRANSFER)"
            : ""}{" "}
          Transaction ID - {sendTransactionDetails?.transactionId}
        </ModalHeader>

        <ModalBody style={{ maxHeight: "600px" }}>
          <div className="mb-2">
            <Button
              className="w-100 bg-white border-0 shadow-none  text-dark fs-6 text-start d-flex justify-content-between align-items-center"
              onClick={() => {
                setCol_1(!col_1);
                setCol_2(false);
                setCol_3(false);
                setCol_4(false);
                setCol_5(false);
              }}
            >
              <span>Currency</span>
              <i className="fa fa-chevron-down" />
            </Button>

            <Collapse isOpen={col_1}>
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                <Table className="table-bordered table-nowrap border border-2 mt-2">
                  <tbody>
                    {sendTransactionDetails &&
                      sendTransactionDetails !== null && (
                        <>
                          {/*console.log(sendTransactionDetails)*/}
                          <tr>
                            <th scope="row">From currency :</th>
                            <td>
                              {sendTransactionDetails?.fromCurrType} -{" "}
                              {parseFloat(
                                sendTransactionDetails?.fromCurrValue
                              ).toFixed(2)}
                            </td>
                          </tr>
                          {chargesDetails?.agentCharges > 0 && (
                            <tr>
                              <th scope="row">Agent Charges :</th>
                              <td>
                                {sendTransactionDetails.fromCurrType !==
                                  "SGD" &&
                                sendTransactionDetails.transactionType === 1
                                  ? `${chargesDetails.agentCharges} ${sendTransactionDetails.toCurrType}`
                                  : sendTransactionDetails.fromCurrType !==
                                    "SGD"
                                  ? `${(
                                      chargesDetails.agentCharges /
                                      buyRateForCharges
                                    ).toFixed(2)} ${
                                      sendTransactionDetails.fromCurrType
                                    }`
                                  : `${chargesDetails.agentCharges} ${sendTransactionDetails.fromCurrType}`}
                              </td>
                            </tr>
                          )}
                          {chargesDetails?.wireTransferFee > 0 && (
                            <tr>
                              <th scope="row">WireTransfer Fee :</th>
                              <td>
                                {sendTransactionDetails.fromCurrType !== "SGD"
                                  ? `${(
                                      chargesDetails.wireTransferFee /
                                      buyRateForCharges
                                    ).toFixed(2)} ${
                                      sendTransactionDetails.fromCurrType
                                    }`
                                  : `${chargesDetails.wireTransferFee} ${sendTransactionDetails.fromCurrType}`}
                              </td>
                            </tr>
                          )}
                          {chargesDetails?.commissionCharges > 0 && (
                            <tr>
                              <th scope="row">Commission Charges :</th>
                              <td>
                                {sendTransactionDetails.fromCurrType !== "SGD"
                                  ? `${(
                                      chargesDetails.commissionCharges /
                                      buyRateForCharges
                                    ).toFixed(2)} ${
                                      sendTransactionDetails.fromCurrType
                                    }`
                                  : `${chargesDetails.commissionCharges} ${sendTransactionDetails.fromCurrType}`}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">To currency :</th>
                            <td>
                              {sendTransactionDetails?.toCurrType} -{" "}
                              {parseFloat(
                                sendTransactionDetails?.toCurrValue
                              ).toFixed(2)}
                            </td>
                          </tr>

                          <tr>
                            <th scope="row">Conversion rate :</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                <div>
                                  {sendTransactionDetails?.transactionType ===
                                  2 ? (
                                    <span>
                                      1 {sendTransactionDetails?.toCurrType} ={" "}
                                      {sendTransactionDetails?.exchangeRate}{" "}
                                      {sendTransactionDetails?.fromCurrType}
                                    </span>
                                  ) : sendTransactionDetails?.transactionType ===
                                    1 ? (
                                    <span>
                                      1 {sendTransactionDetails?.fromCurrType} ={" "}
                                      {sendTransactionDetails?.exchangeRate}{" "}
                                      {sendTransactionDetails?.toCurrType}
                                    </span>
                                  ) : (
                                    <span>
                                      {sendTransactionDetails?.exchangeRate}
                                    </span>
                                  )}
                                </div>
                              </Label>
                            </td>
                          </tr>
                        </>
                      )}
                  </tbody>
                </Table>
              </div>
            </Collapse>
          </div>

          <div className="mb-2">
            <Button
              className="w-100 bg-white border-0 shadow-none  text-dark fs-6 text-start d-flex justify-content-between align-items-center"
              onClick={() => {
                setCol_2(!col_2);
                setCol_1(false);
                setCol_3(false);
                setCol_4(false);
                setCol_5(false);
              }}
            >
              Branch <i className="fa fa-chevron-down " />
            </Button>

            <Collapse isOpen={col_2}>
              <div style={{ maxHeight: "225px", overflowY: "auto" }}>
                <Table
                  className="table-bordered table-nowrap border border-2 mt-2"
                  style={{ maxWidth: "200px" }}
                >
                  <tbody>
                    {branchData && branchData !== null && (
                      <tr>
                        <th>Branch :</th>
                        <td>{branchData.branchName}</td>
                      </tr>
                    )}
                  </tbody>

                  <tbody>
                    {sendTransactionDetails && (
                      <tr>
                        <th scope="row">Booked Date :</th>
                        <td>
                          {formatDateToUserTimeZone(
                            sendTransactionDetails?.transactionDate
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <Label>Working Hours:</Label>

                <Table
                  className="table-bordered table-nowrap border border-2"
                  style={{ maxWidth: "200px" }}
                >
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {optime && optime.length > 0 ? (
                      optime.map((operationalTime, index) => (
                        <tr key={index}>
                          <td>{operationalTime.day}</td>
                          <td>{operationalTime.startTime}</td>
                          <td>{operationalTime.endTime}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No operational time available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <Label>Branch Address:</Label>

                <Table
                  className="table-bordered table-nowrap border border-2"
                  style={{ maxWidth: "200px" }}
                >
                  <tbody>
                    {branchData && branchData !== null ? (
                      <>
                        <tr>
                          <th>Street :</th>
                          <td>{branchData.street}</td>
                        </tr>
                        <tr>
                          <th>City :</th>
                          <td>{branchData.city}</td>
                        </tr>
                        <tr>
                          <th>State :</th>
                          <td>{branchData.state}</td>
                        </tr>
                        <tr>
                          <th>Country :</th>
                          <td>{branchData.country}</td>
                        </tr>
                        <tr>
                          <th>Postal Code :</th>
                          <td>{branchData.postalCode}</td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <th className="text-center">No Address Added.</th>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Collapse>
          </div>
          {sendTransactionDetails?.transactionType === 3 && (
            <div className="mb-2">
              <Button
                className="w-100 bg-white border-0 shadow-none  text-dark fs-6 text-start d-flex justify-content-between align-items-center"
                onClick={() => {
                  setCol_4(!col_4);
                  setCol_2(false);
                  setCol_1(false);
                  setCol_3(false);
                  setCol_5(false);
                }}
              >
                Beneficiary <i className="fa fa-chevron-down " />
              </Button>

              <Collapse isOpen={col_4}>
                <div style={{ maxHeight: "225px", overflowY: "auto" }}>
                  <Table className="table-bordered table-nowrap border border-2 mt-2">
                    <tbody>
                      {beneficiaryDetails && beneficiaryDetails !== null && (
                        <>
                          {/*console.log(sendTransactionDetails)*/}
                          <tr>
                            <th scope="row">Name :</th>
                            <td>{beneficiaryDetails[0].beneficiaryName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Currency :</th>
                            <td>{beneficiaryDetails[0].currencyName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Account Type</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {
                                  BenAccountType[
                                    beneficiaryDetails[0].accountType
                                  ]
                                }
                              </Label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Account Number</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {beneficiaryDetails[0].accountNumber}
                              </Label>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Bank Name</th>
                            <td>
                              <Label
                                className="form-check-Label"
                                htmlFor="formrow-customCheck"
                              >
                                {beneficiaryDetails[0].bankName}
                              </Label>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
          )}

          {/* Supporting docs changes */}
          <div className="mb-2">
            <Button
              className="w-100 bg-white border-0 shadow-none  text-dark fs-6 text-start d-flex justify-content-between align-items-center"
              onClick={() => {
                setCol_4(false);
                setCol_2(false);
                setCol_1(false);
                setCol_3(false);
                setCol_5(!col_5);
              }}
            >
              Supporting Docs <i className="fa fa-chevron-down " />
            </Button>

            <Collapse isOpen={col_5}>
              <div
                style={{
                  maxHeight: "225px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* Scrollable Table Container */}
                <div style={{ flexGrow: 1, overflowY: "auto" }}>
                  <div className="mt-1">
                    {docData?.length > 0 ? (
                      <Table className="table table-striped table-bordered mt-0 mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>No</th>
                            <th>Document Name</th>
                            <th>View Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {docData.map((arr, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{arr.documentName}</td>
                              <td>
                                <Button
                                  type="button"
                                  color="primary"
                                  className="btn-sm btn-rounded me-2"
                                  onClick={() => viewDoc(index)}
                                >
                                  <FontAwesomeIcon icon={faEye} size="xs" />
                                </Button>

                                <Button
                                  type="button"
                                  color="danger"
                                  className="btn-sm btn-rounded"
                                  onClick={() => deleteDoc(index)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    size="xs"
                                  />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <div
                        className="text-muted d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        <h4>No documents available</h4>
                      </div>
                    )}
                  </div>
                </div>

                {/* Always Visible Add Document Button */}
                {showAction === true &&
                  sendTransactionDetails?.status < 4 &&
                  docData.length < 5 && (
                    <div className="mt-2 text-start">
                      <Button
                        type="button"
                        color="primary"
                        className="btn-primary"
                        onClick={() => {
                          tog_standard();
                        }}
                      >
                        Add Document
                      </Button>
                    </div>
                  )}
              </div>
            </Collapse>
          </div>
          {/* Supporting Docs Changes end */}

          <div className="mb-2">
            <Button
              className="w-100 bg-white border-0 shadow-none text-dark fs-6 text-start d-flex justify-content-between align-items-center"
              onClick={() => {
                setCol_3(!col_3);
                setCol_1(false);
                setCol_2(false);
                setCol_4(false);
                setCol_5(false);
              }}
            >
              Comment <i className="fa fa-chevron-down" />
            </Button>

            <Collapse isOpen={col_3}>
              <div>
                <div
                  className="mt-1"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {commentData?.length > 0 ? (
                    commentData?.map((comment, index) => (
                      <div key={index}>
                        <div>
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faUser} className="me-2" />
                              <span>{UserTypes[comment?.userType]}</span>
                            </div>
                            <span
                              className="me-2"
                              style={{ fontSize: "0.6rem" }}
                            >
                              {" "}
                              {formatDateToUserTimeZone(comment?.createdOn)}
                              {/* {formatDate(comment.createdOn)} */}
                              {/* {comment.createdOn.split("T")} */}
                            </span>
                          </div>
                          <p>
                            Status:{" "}
                            <Badge
                              className={
                                "font-size-11 badge-soft-" +
                                mapStatus(comment?.transactionStatus).color
                              }
                            >
                              {mapStatus(comment?.transactionStatus).label}
                            </Badge>
                          </p>
                        </div>
                        <div>
                          <h6>
                            Comment: <b>{comment?.comments}</b>
                          </h6>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div
                      className="text-muted d-flex justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <h4>No comments to show</h4>
                    </div>
                  )}
                </div>
                {showAction === true &&
                  isBranchActive === true &&
                  sendTransactionDetails?.status < 13 && (
                    <Button
                      type="button"
                      color="primary"
                      className="btn-primary text-start mt-2"
                      onClick={() =>
                        openCommentPopup(sendTransactionDetails.status)
                      }
                    >
                      Comment
                    </Button>
                  )}

                {isBranchActive === false && (
                  <div className="text-muted d-flex justify-content-center align-items-center">
                    <h6 className="text-danger">
                      The transacting branch has been deactivated. Please
                      contact Admin for further details.
                    </h6>
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        </ModalBody>
        {showAction === true && isBranchActive === true && (
          <ModalFooter>
            {sendTransactionDetails != null &&
              sendTransactionDetails.status === 2 && (
                <>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-primary"
                    onClick={() => openCommentPopup(8)}
                  >
                    Acknowledge
                  </Button>
                  <Button
                    type="button"
                    color="warning"
                    className="btn-primary"
                    onClick={() => openCommentPopup(3)}
                  >
                    Review
                  </Button>
                </>
              )}

            {sendTransactionDetails && sendTransactionDetails.status === 4 && (
              <Button
                type="button"
                color="danger"
                className="btn-primary"
                disabled={calculateDateDifference() === 0 ? false : true}
                onClick={() => openCommentPopup(9)}
              >
                Decline
              </Button>
            )}

            {sendTransactionDetails != null &&
              (sendTransactionDetails.status === 1 ||
                sendTransactionDetails.status === 2 ||
                sendTransactionDetails.status === 3 ||
                sendTransactionDetails.status === 8) && (
                <>
                  <Button
                    type="button"
                    color="danger"
                    className="btn-primary"
                    onClick={() => openCommentPopup(6)}
                  >
                    Decline
                  </Button>
                </>
              )}

            {sendTransactionDetails != null &&
              sendTransactionDetails.status === 10 && (
                <>
                  <Button
                    type="button"
                    color="danger"
                    className="btn-primary"
                    onClick={() => openCommentPopup(9)}
                  >
                    Decline
                  </Button>
                </>
              )}
          </ModalFooter>
        )}
      </Modal>

      {/*Comment Modal*/}
      <Modal isOpen={isCommentOpen} toggle={closeCommentPopup}>
        <ModalHeader toggle={closeCommentPopup}>Add Comment</ModalHeader>
        <ModalBody>
          <textarea
            className="form-control"
            rows="5"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            placeholder="Write your comment here..."
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCommentSubmit}>
            Submit
          </Button>
          <Button color="secondary" onClick={closeCommentPopup}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* View Document Modal */}
      <Modal
        size="lg"
        isOpen={modal1}
        toggle={toggleViewModal}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="myModalLabel">
            {viewDocName}
          </h5>
          <button
            type="button"
            onClick={() => {
              setModal1(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Row>
          <Col>
            <Card>
              <CardBody style={{ padding: "0" }}>
                <img
                  src={`data:image/png;base64,${viewDocument}`}
                  alt="Document"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "50vh",
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ModalFooter>
          <Button
            type="button"
            color="success"
            className="btn-sm btn-rounded mb-2 me-1"
            onClick={() =>
              handleDownloadFile(
                docData?.findIndex((doc) => doc.documentName === viewDocName)
              )
            }
          >
            <FontAwesomeIcon icon={faDownload} /> Download
          </Button>
        </ModalFooter> */}
      </Modal>

      {/* Add Document Modal */}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Manage Documents
          </h5>
          <button
            type="button"
            onClick={() => {
              documentValidation.resetForm();
              setmodal_standard(false);
              return false;
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="mb-4">
                  <Col sm={6}>
                    <div className="mt-3">
                      <Label for="documentName">Document Name</Label>
                      <div className="col-md-12">
                        <Input
                          type="text"
                          placeholder="Document Name"
                          id="documentName"
                          name="documentName"
                          onChange={documentValidation.handleChange}
                          onBlur={documentValidation.handleBlur}
                          value={documentValidation.values.documentName}
                          invalid={
                            documentValidation.touched.documentName &&
                            documentValidation.errors.documentName
                              ? true
                              : false
                          }
                        />
                        {documentValidation.touched.documentName &&
                        documentValidation.errors.documentName ? (
                          <FormFeedback type="invalid">
                            {documentValidation.errors.documentName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="mt-1">
                      <Label for="document">Select Document</Label>
                      <div className="col-md-12">
                        <Input
                          type="file"
                          id="document"
                          aria-label="Upload"
                          accept=".png, .jpg, .jpeg, .pdf"
                          aria-describedby="inputGroupFileAddon04"
                          onChange={(e) => {
                            handleFileChange(e);
                            documentValidation.handleChange(e);
                          }}
                          onBlur={documentValidation.handleBlur}
                          invalid={
                            documentValidation.touched.document &&
                            documentValidation.errors.document
                              ? true
                              : false
                          }
                        />
                        {documentValidation.touched.document &&
                        documentValidation.errors.document ? (
                          <FormFeedback type="invalid">
                            {documentValidation.errors.document}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="mt-0 mb-4 me-3 text-end">
          <Button
            className="btn btn-primary"
            color="primary"
            disabled={isAddingDoc}
            onClick={documentValidation.handleSubmit}
          >
            {isAddingDoc ? "Submiting Document..." : "Submit"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

ViewLatestTransactionModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  sendTransactionDetails: PropTypes.object,
  commentData: PropTypes.array,
};

export default ViewLatestTransactionModel;
