import React from "react";
import ReactDOM from "react-dom/client";
import App from "./js/setup";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./js/i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store from "./js/store";
import { LatestTransactionsProvider } from "./js/common/data/latest-transaction-context";
import { CurrencyProvider, RegisterProvider } from "./js/common/data/context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <GoogleOAuthProvider clientId="483164320992-qbqbeqv8fthaa429lqaur63sbd009v2g.apps.googleusercontent.com">
          <LatestTransactionsProvider>
            <CurrencyProvider>
              <RegisterProvider>
              <App />
              </RegisterProvider>
            </CurrencyProvider>
          </LatestTransactionsProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
