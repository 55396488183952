import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import classname from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import withRouter from "./../Common/withRouter";

import {
  faBuilding,
  faFlagCheckered,
  faPeopleGroup,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = ({ leftMenu, t }) => {
  const [isTransSubmenuOpen, setIsTransSubmenuOpen] = useState(false);
  const [isStaffSubmenuOpen, setIsStaffSubmenuOpen] = useState(false);

  const [dropdownStates, setDropdownStates] = useState({
    dashboard: false,
    staff: false,
    transaction: false,
    branch: false,
  });

  const [submenuStates, setSubmenuStates] = useState({
    transaction: false,
    staff: false,
  });

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  // Update `isDesktop` on window resize
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSubmenu = (menuName) => {
    if (!isDesktop) {
      setSubmenuStates((prevState) => ({
        ...prevState,
        [menuName]: !prevState[menuName],
      }));
    }
  };

  const handleMouseEnter = (menuName) => {
    if (isDesktop) {
      setSubmenuStates((prevState) => ({
        ...prevState,
        [menuName]: true,
      }));
    }
  };

  // Hide submenu on hover leave (desktop only)
  const handleMouseLeave = (menuName) => {
    if (isDesktop) {
      setSubmenuStates((prevState) => ({
        ...prevState,
        [menuName]: false,
      }));
    }
  };

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const role = authUser?.role;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown-menu")) {
        setDropdownStates({
          dashboard: false,
          staff: false,
          transaction: false,
          branch: false,
        });
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/agentdashboard">
                    <i className="bx bx-home-circle me-1"></i>
                    {t("Dashboard")}
                  </Link>
                </li>

                {role === "4" || role === "5" ? (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/branch"
                    >
                      <FontAwesomeIcon icon={faBuilding} className="me-1" />
                      {t("Manage Branch")}
                    </Link>
                  </li>
                ) : null}
                <li
                  onMouseEnter={() => handleMouseEnter("transaction")}
                  onMouseLeave={() => handleMouseLeave("transaction")}
                  className="nav-item dropdown"
                >
                  <Link
                    to="#"
                    onClick={() => toggleSubmenu("transaction")} // Allow click to toggle submenu in mobile
                    className="nav-link dropdown-toggle"
                  >
                    <i className="bx bx-wallet me-1"></i>
                    {t("Transaction")}
                    <div className="arrow-down"></div>
                  </Link>

                  <div
                    className={classname("dropdown-menu", {
                      show: submenuStates.transaction,
                    })}
                    onClick={() => setIsTransSubmenuOpen(false)} // Close submenu on item click
                  >
                    <Link
                      to="/Add-Transaction"
                      className="dropdown-item"
                      onClick={() => setIsTransSubmenuOpen(false)}
                    >
                      {t("Add Transaction")}
                    </Link>
                    <Link
                      to="/Manage-Transaction"
                      className="dropdown-item"
                      onClick={() => setIsTransSubmenuOpen(false)}
                    >
                      {t("Manage Transaction")}
                    </Link>
                    <Link
                      to="/Transaction-History"
                      className="dropdown-item"
                      onClick={() => setIsTransSubmenuOpen(false)}
                    >
                      {t("Transaction History")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/Manage-Beneficiary"
                  >
                    <FontAwesomeIcon icon={faPerson} className="me-1" />
                    {t("Beneficiary")}
                  </Link>
                </li>

                {role === "4" || role === "5" ? (
                  <li
                    className="nav-item dropdown"
                    onMouseEnter={() => handleMouseEnter("staff")}
                    onMouseLeave={() => handleMouseLeave("staff")}
                  >
                    <Link
                      to="#"
                      onClick={() => toggleSubmenu("staff")} // Allow click to toggle submenu in mobile
                      className="nav-link dropdown-toggle"
                    >
                      {/* <i className="bx bx-window me-2"></i> */}
                      <FontAwesomeIcon icon={faPeopleGroup} className="me-1" />
                      {t("Staff")}

                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: submenuStates.staff,
                      })}
                      onClick={() => setIsStaffSubmenuOpen(false)} // Close submenu on item click
                    >
                      <Link
                        to="/manage-staff"
                        className="dropdown-item"
                        onClick={() => setIsStaffSubmenuOpen(false)}
                      >
                        {t("Manage Staff")}
                      </Link>
                    </div>
                  </li>
                ) : null}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/Manage-Assignment"
                  >
                    <FontAwesomeIcon icon={faFlagCheckered} className="me-1" />
                    {t("My Assignments")}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Navbar))
);
