import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios, { getApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import { BaseRegisterContext } from "../../common/data/context";
// import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../../../images/crescent_logo.png"
import logoLight from "../../../images/logo-light.png";
import logoLightSvg from "../../../images/logo-light.svg";
import logoDark from "../../../images/logo-dark.png";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";

const Callback = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const navigate = useNavigate();
    const singpass = sessionStorage.getItem("singpass");
    const isPage = sessionStorage.getItem("isPage");
    const { setSingPassData } = useContext(BaseRegisterContext);
    const [isloaded, setLoaded] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(null);
     const { setbranchId } = useLatestTransactions();
  
    useEffect(() => {
      const callback = async () => {
        if (!code || !state) {
          console.error("Missing required parameters: code or state");
          return; // Exit early if parameters are missing
        }
        if (isloaded) {
          return;
        }
        try {
          const response = await getApiData(`api/Singpass/callback?code=${code}&state=${state}&singpass=${singpass}&isPage=${isPage}`);
          console.log("API Response:", response);
          setLoaded(true);
          if (response) {
            setIsDataFetched(true);
            sessionStorage.removeItem("singpass");
            sessionStorage.setItem("forceLogout", "false");
             
                   
                      if(response?.data?.navigateUrl){
                      const logged_user = {
                        agentLogin: true,
                        username: response?.data?.login,
                        token: response?.data.token,
                        userType: response?.data?.userType,
                        role: response?.data?.roles,
                        branchId: response?.data?.branchId,
                        agentName: response?.data?.agentName,
                        uid: response?.data?.uid,
                        expiration: response?.data?.expiration,
                        authType: response?.data?.authenticationType,
                      };
                      localStorage.setItem("authUser", JSON.stringify(logged_user));
                      if (response?.data?.userType === "3") {
                        const isAgentSuperAdmin = response?.data?.roles === "4";
                        toast.success("Agent Login Successful", {
                          position: "top-right",
                          autoClose: 1000,
                          closeButton: false,
                          onClose: () => {
                            if (!isAgentSuperAdmin) {
                              setbranchId(response?.data?.branchId);
                            }
                            navigate(response.data.navigateUrl);
                                    },
                                  });
                                }  else {
                                  setIsDataFetched(false);
                                  toast.warning(response.message, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    onClose: () => {
                                      // navigate("/login");
                                    },
                                  });
                                }
                      
                              } else if (response.uinFin) {
                                // let data = {response};
                                setSingPassData(response);
                    
                                sessionStorage.setItem("RegisterSingpassValues", JSON.stringify(response));
                                toast.success("User Data Fetched Successfully", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  onClose: () => {
                                    navigate("/register");
                                  },
                                });
                              }else {
                                setIsDataFetched(false);
                      
                                toast.warning("Failed to fetch user details", {
                                  position: "top-right",
                                  autoClose: 3000,
                                  onClose: () => {
                                    // navigate("/login");
                                  },
                                });
                              }
                            
                          
        //     if (response?.data?.navigateUrl) {
        //       const logged_user = {
        //         agentLogin: true,
        //     username: response?.data?.login,
        //     token: response?.data.token,
        //     userType: response?.data?.userType,
        //     role: response?.data?.roles,
        //     branchId: response?.data?.branchId,
        //     agentName: response?.data?.agentName,
        //     uid: response?.data?.uid,
        //     expiration: response?.data?.expiration,
        //     authType: response?.data?.authenticationType,
        //       };
  
        //       const expirValidation = {
        //         uid: response.data.uid,
        //         expiration: response.data.expiration,
        //       };
        //       axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;
        //       sessionStorage.setItem("expirValidation", JSON.stringify(expirValidation));
        //       localStorage.setItem("authUser", JSON.stringify(logged_user));
        //       toast.success(" Login Successful", {
        //         position: "top-right",
        //         autoClose: 3000,

        //         onClose: () => {
        //           navigate(response.data.navigateUrl);
        //         },
        //       });
        //     } else if (response.uinFin) {
        //       // let data = {response};
        //       setSingPassData(response);
  
        //       sessionStorage.setItem("RegisterSingpassValues", JSON.stringify(response));
        //       toast.success("User Data Fetched Successfully", {
        //         position: "top-right",
        //         autoClose: 3000,
        //         onClose: () => {
        //           navigate("/register");
        //         },
        //       });
        //     } else {
        //       setIsDataFetched(false);
        //       toast.warning(response.message, {
        //         position: "top-right",
        //         autoClose: 3000,
        //         onClose: () => {
        //           // navigate("/login");
        //         },
        //       });
        //     }
  
        //   } else {
        //     setIsDataFetched(false);
  
        //     toast.warning("Failed to fetch user details", {
        //       position: "top-right",
        //       autoClose: 3000,
        //       onClose: () => {
        //         // navigate("/login");
        //       },
        //     });
  
           }
        } catch (error) {
          console.error("Error during callback:", error);
          // Optionally navigate to an error page
          navigate("/error");
        }
      };
  
      callback();
    }, [code, state, singpass, navigate, setSingPassData]);
  
    return (
      <><ToastContainer closeButton={false} limit={1} />
        <div >
          <header style={{background: "#007bff"}}>
            <div className="navbar-header">
              <div className="d-flex">
                <div className="navbar-brand-box">
                  <Link className="logo logo-dark">
                    <span className="logo-sm rounded-circle">
                      <img src={logo} alt="crescent.png" height="22" className="rounded-circle" />
                    </span>
                    <span className="logo-lg">
                      <img src={logoDark} alt="name.png" height="18" />
                    </span>
                  </Link>
  
                  <Link  className="logo logo-light">
                    <span className="logo-sm">
                      <img src={logoLightSvg} alt="crescent.png" height="22" />
                    </span>
                    <span className="logo-lg">
                      <img src={logoLight} alt="name.png" height="19" />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div style={{ textAlign: "center", padding: "20px" }}>
          {isDataFetched == null && (
            <div style={styles.container}>
              <div style={styles.iconWrapper}>
                {/* <i className="fas fa-spinner fa-spin" style={styles.spinnerIcon}></i> */}
              </div>
              <p style={styles.text}>
                <i className="fas fa-spinner fa-spin" style={styles.spinnerIcon}></i>
                Callback processing...
              </p>
            </div>
          )}
          {isDataFetched == false && (
            <div style={styles.container}>
              <div style={styles.iconWrapper}>
                {/* <i className="fas fa-spinner fa-spin" style={styles.spinnerIcon}></i> */}
  
                <div style={styles.warningText}>
                  {/*<FaExclamationTriangle size={50} color="orange" />*/}
                  <p style={{ color: "orange", fontSize: "18px", fontWeight: "bold" }}>
                    Failed to fetch user details
                  </p>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <button
                    onClick={() => navigate("/login")}
                    style={{
                      marginRight: "10px",
                      padding: "10px 20px",
                      backgroundColor: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Login
                  </button>
                  <button
                    onClick={() => navigate("/register")}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#28a745",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          )}
          {isDataFetched === true && (
            <div style={styles.container}>
              <p style={styles.successText}>User data is successfully fetched. Please wait...</p>
            </div>
          )}
        </div>
  
      </>
    ); // Optionally show a loading message
  };
  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      flexDirection: "column",
      backgroundColor: "#f9f9f9",
    },
    iconWrapper: {
      marginBottom: "10px",
    },
    spinnerIcon: {
      fontSize: "40px",
      color: "#007bff",
    },
    text: {
      fontSize: "18px",
      fontWeight: "500",
      color: "#007bff",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    infoIcon: {
      fontSize: "20px",
    },
    successText: {
      fontSize: "18px",
      fontWeight: "500",
      color: "green",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    warningText: {
      fontSize: "18px",
      fontWeight: "500",
      color: "orange",
      // display: "flex",
      alignItems: "center",
      gap: "10px",
    }
  };
  export default Callback;
  