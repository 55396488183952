import React, { useEffect, useState, useContext } from "react";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Label, Input, Card, Button } from "reactstrap";
import { toast } from "react-toastify";
import { BaseCurrencyContext } from "../../common/data/context";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import Loader from "../../components/Common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faEquals,
  faX,
  faDivide,
  faXmark,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "reactstrap";
import { SelectStyleForMenu } from "../../common/data/SelectStyleForMenu";
import BeneficiaryModal from "../Beneficiary/BeneficiaryModal";
import Swal from "sweetalert2";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";

const DashboardTransfer = () => {
  const navigate = useNavigate();

  const [mainBranch, setMainBranch] = useState(null);
  const { branchId } = useLatestTransactions();
  const [currency, setCurrency] = useState([]);
  const [currencyIcons, setCurrencyIcons] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const { currencyData, currencyOptions } = useContext(BaseCurrencyContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [beneficiary, setBeneficiary] = useState([]);
  const [beneficiaryError, setBeneficiaryError] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [typedBeneficiaryName, setTypedBeneficiaryName] = useState("");

  const [globalTransferData, setGlobalTransferData] = useState(null);
  const [documentData, setDocumentData] = useState([]);

  const fromCurrencyTransfer = currencyData?.value;
  const [fromCurrencyDisplay, setFromCurrencyDisplay] = useState("0");
  const [fromCurrencyValueTransfer, setFromCurrencyValueTransfer] = useState(0);

  const toCurrencyTransfer = selectedBeneficiary?.currencyCode;
  const [toCurrencyDisplay, setToCurrencyDisplay] = useState("0");
  const [toCurrencyValueTransfer, setToCurrencyValueTransfer] = useState(0);

  const [rateHistoryId, setRateHistoryId] = useState(null);

  const [buyRate, setBuyRate] = useState(0);
  const [contextBuyRate, setContextBuyRate] = useState(null);

  const [errors, setErrors] = useState({});
  const [modal1, setModal1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [enableTransfer, setEnableTransfer] = useState(true);

  const resetTransaction = () => {
    setIsLoading(false);
    setSelectedBeneficiary(null);
    setFromCurrencyDisplay("");
    setToCurrencyValueTransfer("");
    setToCurrencyDisplay("");
    setFromCurrencyValueTransfer("");
  };

  const transferData = globalTransferData?.find((x) => {
    const targetCurrency = toCurrencyTransfer;

    return x.currencyCode === targetCurrency;
  });

  const transferRate = transferData ? parseFloat(transferData.rate) : 0;

  const calculateFee = (fee, fromCurrency, toCurrency, buyRate) => {
    if (fromCurrency === "SGD") {
      return fee;
    } else if (toCurrency === "SGD") {
      return fee / buyRate;
    } else {
      return fee / buyRate;
    }
  };

  let wireFee = parseFloat(transferData ? transferData.wireTransferFee : 0);
  let commissionFee = parseFloat(
    transferData ? transferData.commissionCharges : 0
  );
  let agentFee = parseFloat(transferData ? transferData.agentCharges : 0);

  const convertedWireFee = calculateFee(
    wireFee,
    fromCurrencyTransfer,
    toCurrencyTransfer,
    buyRate
  );
  const convertedCommissionFee = calculateFee(
    commissionFee,
    fromCurrencyTransfer,
    toCurrencyTransfer,
    buyRate
  );
  const convertedAgentFee = calculateFee(
    agentFee,
    fromCurrencyTransfer,
    toCurrencyTransfer,
    buyRate
  );

  console.log({
    transferData,
    transferRate,
    fromCurrencyTransfer,
    toCurrencyTransfer,
    wireFee,
    commissionFee,
    buyRate,
    contextBuyRate,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch currencies
        const currencyResponse = await getApiData(
          "api/RateMaster/GetAllCurrencies"
        );
        if (currencyResponse.success) {
          const icons = {};
          currencyResponse.data.forEach((currency) => {
            const flag = `data:image/png;base64,${currency.currencyFlag}`;
            // const normalizedCurrencyName = currency.currencyName
            //   .split("(")[0]
            //   .trim();
            icons[currency.currencyCode.trim()] = flag;
            // icons[normalizedCurrencyName] = flag;
          });
          setCurrencyIcons(icons);
          setCurrencies(currencyResponse.data);
          const mappedResponse = currencyResponse.data.map((item, index) => ({
            index: index + 1,

            currencyId: item.currencyId,
            currencyName: item.currencyName,
            currencyCode: item.currencyCode,
          }));
          setCurrency(mappedResponse);
        } else {
          console.error("Error fetching currencies");
        }

        // Fetch transfer currencies
        //const avaCurrency = await getApiData(`api/RateMaster/GetBranchForCurrencyAll?rateType=3`);

        //const distinctCurrencies = Array.from(
        //    new Set(avaCurrency.data.map(currency => currency.currencyCode))
        //).map(currencyCode => avaCurrency.data.find(currency => currency.currencyCode === currencyCode));

        //const formattedCurrency = distinctCurrencies
        //    .map(currency => ({
        //        label: currency.currencyName,
        //        value: currency.currencyCode,
        //        currencyCode: currency.currencyCode,
        //    }))

        //setFromCurrencyTransfer(formattedCurrency);

        // Fetch transfer rates
        const rateResponse = await getApiData(
          "api/RateMaster/GetGlobalTransferRate"
        );
        if (rateResponse.success) {
          const mappedTransferRates = rateResponse.data.map((rate) => ({
            rateHistoryId: rate.rateHistoryId,
            currencyCode: rate.currencyCode,
            currencyName: rate.currencyName,
            rate: rate.value,
            worldRate: parseFloat(rate.worldRate),
            transferRate: parseFloat(rate.transferRate),
            wireTransferFee: parseFloat(rate.wireTransferFee),
            agentCharges: parseFloat(rate.agentCharges),
            commissionCharges: parseFloat(rate.commissionCharges),
            totalFeesAdmin: parseFloat(rate.totalFeesAdmin),
          }));
          setGlobalTransferData(mappedTransferRates);
        } else {
          console.error("Error fetching global transfer rates");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMainBranch();
    fetchData();
  }, []);

  //useEffect(() => {
  //    if (currencyData?.value && fromCurrencyTransfer.length > 0) {
  //        const initialCurrency = fromCurrencyTransfer.find(
  //            currency => currency.value === currencyData?.value
  //        );
  //        if (initialCurrency) {
  //            setSelectedFromCurrencyTransfer(initialCurrency);
  //        }
  //    }
  //}, [currencyData, fromCurrencyTransfer]);

  useEffect(() => {
    const fetchContextBuyRate = async () => {
      setIsLoading(true);
      const resp = await getApiData(
        `api/RateMaster/GetTransactionRate?fromCurr=${currencyData?.value}`
      );
      if (resp.success === true) {
        setContextBuyRate(parseFloat(resp.data.buyRate));
      } else {
        setContextBuyRate(null);
      }
      setIsLoading(false);
    };
    console.log(contextBuyRate);
    fetchContextBuyRate();
  }, [currencyData, contextBuyRate]);

  const fetchTransfer = async (fromCurr) => {
    setIsLoading(true);
    const resp = await getApiData(
      `api/RateMaster/GetTransactionRate?fromCurr=${fromCurr}`
    );
    if (resp.success === true) {
      setRateHistoryId(resp.data.rateHistoryId);
      setBuyRate(parseFloat(resp.data.buyRate));
    } else {
      setBuyRate(0);
      setRateHistoryId(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (fromCurrencyTransfer !== "SGD" && fromCurrencyTransfer !== undefined) {
      fetchTransfer(fromCurrencyTransfer);
    }
    setFromCurrencyValueTransfer(0);
    setToCurrencyValueTransfer(0);
    fetchBeneficiaries();
    setSelectedBeneficiary(null);
  }, [fromCurrencyTransfer]);

  useEffect(() => {
    if (toCurrencyTransfer === "SGD" && buyRate === 0) {
      setEnableTransfer(false);
      Swal.fire({
        text: "Transfer not available for this Currency!",
        icon: "info",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          resetTransaction();
        }
      });
    }
    if (
      toCurrencyTransfer !== undefined &&
      toCurrencyTransfer !== "SGD" &&
      transferRate === 0
    ) {
      setEnableTransfer(false);
      Swal.fire({
        text: "Transfer not available for this Beneficiary!",
        icon: "info",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        confirmButtonColor: "#556ee6",
      }).then(async (result) => {
        if (result.isConfirmed) {
          resetTransaction();
        }
      });
    } else {
      setEnableTransfer(true);
    }
    if (fromCurrencyTransfer === toCurrencyTransfer) {
      resetTransaction();
    }
    if (fromCurrencyTransfer !== "SGD" && fromCurrencyTransfer !== undefined) {
      fetchTransfer(fromCurrencyTransfer);
    }
  }, [fromCurrencyTransfer, toCurrencyTransfer, transferRate]);

  const fetchBeneficiaries = async () => {
    const response = await getApiData(
      "api/AgentBeneficiary/GetAllByAgentBeneficiary"
    );
    const filteredResponse = response.data.filter(
      (item) =>
        item.status === 2 &&
        item.active === 1 &&
        item.currencyCode !== fromCurrencyTransfer
      // &&
      // currencyOptions.some((option) => option.value === item.currencyCode)
    );
    const mappedBeneficiaries = filteredResponse.map((item) => ({
      label: item.beneficiaryName,
      value: item.companyBenId,
      // currencyName: item.currencyName.split("(")[0].trim(),
      currencyName: item.currencyName.split("(")[1]?.replace(")", "") || "",
    }));
    setBeneficiary(mappedBeneficiaries);
  };

  // useEffect(() => {
  //   fetchBeneficiaries();
  // }, []);

  const getMainBranch = async () => {
    const response = await getApiData("api/BranchMaster/GetAllBranch");
    if (response.success === true) {
      const branchForTransfer = response.data.filter(
        (branch) => branch.isMainBranch === true
      );

      const mapMainBranch = branchForTransfer.map((branch) => ({
        branchId: branch.branchId,
        branchName: branch.branchName,
      }));
      setMainBranch(mapMainBranch);
    } else {
      setMainBranch(null);
    }
  };

  const setExchangeRate = () => {
    if (fromCurrencyTransfer === "SGD") {
      return transferRate;
    } else if (toCurrencyTransfer === "SGD") {
      return buyRate;
    } else {
      return (buyRate / transferRate).toFixed(10);
    }
  };

  const handlePrecision = (value, decimals = 9) => {
    const multiplier = Math.pow(10, decimals);
    return Math.round(value * multiplier) / multiplier;
  };

  const handleSubmitTransfer = async (e) => {
    e.preventDefault();

    const fromRateHistoryId =
      fromCurrencyTransfer === "SGD"
        ? globalTransferData?.find(
            (x) => x.currencyCode === fromCurrencyTransfer
          )?.rateHistoryId
        : rateHistoryId;

    if (!fromRateHistoryId) {
      console.error("Rate history ID is missing.");
      return;
    }

    const toRateHistory = globalTransferData?.find(
      (x) => x.currencyCode === toCurrencyTransfer
    );

    const TransferData = {
      toCompanyBenId: selectedBeneficiary.value,

      fromCurrType: fromCurrencyTransfer,
      fromCurrValue: handlePrecision(fromCurrencyValueTransfer),

      toCurrType: toCurrencyTransfer,
      toCurrValue: handlePrecision(toCurrencyValueTransfer),

      exchangeRate: setExchangeRate(),

      fromBranchId: mainBranch[0]?.branchId,
      transactionType: 3,
      agentBranchID: branchId,
      fromRateHistoryId: fromRateHistoryId,
      toRateHistoryId: toRateHistory?.rateHistoryId,
      buyRateHistoryIdMainBranch:
        fromCurrencyTransfer !== "SGD" ? fromRateHistoryId : null,
    };

    try {
      //console.log(TransferData);
      //return;
      setIsLoading(true);
      const response = await postApiData(
        "api/Transaction/InsertTransaction",
        TransferData
      );
      if (response.success === true) {
        toast.success("Transaction request has been completed", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        Swal.fire({
          text: response.message + ". Please contact the admin",
          icon: "info",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#556ee6",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/agentdashboard");
          } else {
            return;
          }
        });
      }
    } catch (error) {
      console.error("Transaction Error:", error);
    } finally {
      setIsLoading(false);
      resetTransaction();
    }
  };

  const toggleAddModal = () => setModal1(!modal1);

  const openADDBeneficiary = () => {
    toggleAddModal();
    setSearchTerm("");
  };

  const filteredBeneficiaries = beneficiary.filter((b) =>
    b.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (typedBeneficiaryName) {
    filteredBeneficiaries.push({
      label: `Create "${typedBeneficiaryName}" Beneficiary`,
      value: "create-new",
    });
  }

  if (filteredBeneficiaries.length === 0) {
    filteredBeneficiaries.push({
      label: `Create New Beneficiary`,
      value: "create-new",
    });
  }
  // const getCurrencyFlag = (currencyCode, currencyName) => {
  //   if (!currencyCode) return "";
  //   const currencyDetails = currencies.find(
  //     (c) => c.currencyCode && c.currencyCode.trim() === currencyCode.trim()
  //   );
  //   return currencyDetails
  //     ? `data:image/png;base64,${currencyDetails.currencyFlag}`
  //     : "";
  // };

  const getCurrencyFlag = (currencyName) => {
    return currencyIcons[currencyName] || ""; // Fetch the flag from the icons object
  };

  const convertFromTransferCurrency = (e) => {
    const inputValue = e.target.value;

    setFromCurrencyDisplay(inputValue);

    if (inputValue === "") {
      setFromCurrencyValueTransfer("");
      setToCurrencyValueTransfer("");
      setErrors({
        fromCurrency: "",
        toCurrency: "",
      });
      return;
    }

    if (inputValue.length > 10) return;

    let convertedValue = 0;
    const amount = parseFloat(inputValue) || 0;
    const totalFee =
      (convertedWireFee || 0) +
      (convertedCommissionFee || 0) +
      (convertedAgentFee || 0);

    setFromCurrencyValueTransfer(inputValue);

    if (amount <= 10 || amount < totalFee) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromCurrency: `Amount must be greater than 10, total fees (${totalFee.toFixed(
          2
        )}), and cannot be negative.`,
        toCurrency: "",
      }));
      setToCurrencyDisplay("0");
      setToCurrencyValueTransfer(0);
      return;
    }

    if (fromCurrencyTransfer === "SGD") {
      if (transferRate > 0) {
        convertedValue = (amount - totalFee) / transferRate;
      } else {
        setErrors({
          fromCurrency: "Transfer rate is invalid.",
          toCurrency: "",
        });
        setToCurrencyValueTransfer(0);
        return;
      }
    } else if (toCurrencyTransfer === "SGD") {
      if (buyRate > 0) {
        convertedValue = (amount - totalFee) * buyRate;
        console.log({ amount, buyRate, totalFee, convertedValue });
        if (convertedValue < 1) {
          setErrors({
            fromCurrency: "",
            toCurrency: "Converted SGD cannot be less than 1",
          });
          setToCurrencyValueTransfer(0);
          setToCurrencyDisplay("0");
          return;
        }
      } else {
        setErrors({
          fromCurrency: "Invalid buy rate for conversion.",
          toCurrency: "",
        });
        setToCurrencyValueTransfer(0);
        setToCurrencyDisplay("0");
        return;
      }
    } else {
      if (buyRate <= 0 || transferRate <= 0) {
        setErrors({
          fromCurrency: "Invalid buy or transfer rates.",
          toCurrency: "",
        });
        setToCurrencyValueTransfer(0);
        setToCurrencyDisplay("0");
        return;
      }

      const subAmount = amount - totalFee;
      const convertedSGD = subAmount * buyRate;
      convertedValue = convertedSGD / transferRate;
    }

    setErrors({
      fromCurrency: "",
      toCurrency: "",
    });

    console.log(convertedValue);
    setToCurrencyValueTransfer(convertedValue);
    setToCurrencyDisplay(convertedValue.toFixed(2));
  };

  const convertToTransferCurrency = (e) => {
    const inputValue = e.target.value;

    setToCurrencyDisplay(inputValue);

    if (inputValue === "") {
      setFromCurrencyValueTransfer("");
      setToCurrencyValueTransfer("");
      setErrors({
        fromCurrency: "",
        toCurrency: "",
      });
      return;
    }

    if (inputValue.length > 10) return;

    let convertedValue = 0;
    const amount = parseFloat(inputValue) || 0;
    const totalFee =
      (convertedWireFee || 0) +
      (convertedCommissionFee || 0) +
      (convertedAgentFee || 0);

    setToCurrencyValueTransfer(inputValue);

    if (amount <= 0 || amount < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromCurrency: "",
        toCurrency: `Amount must be greater than 10 and cannot be negative.`,
      }));
      setFromCurrencyValueTransfer(0);
      setFromCurrencyDisplay("0");
      return;
    }

    if (fromCurrencyTransfer === "SGD") {
      if (transferRate > 0) {
        convertedValue = amount * transferRate + totalFee;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          toCurrency: "Invalid transfer rate.",
        }));
        setFromCurrencyValueTransfer(0);
        setFromCurrencyDisplay("0");
        return;
      }
    } else if (toCurrencyTransfer === "SGD") {
      if (buyRate > 0) {
        convertedValue = amount / buyRate + totalFee;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          toCurrency: "Invalid buy rate for conversion.",
        }));
        setFromCurrencyValueTransfer(0);
        setFromCurrencyDisplay("0");
        return;
      }
    } else {
      if (buyRate <= 0 || transferRate <= 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          toCurrency: "Invalid buy or transfer rates.",
        }));
        setFromCurrencyValueTransfer(0);
        setFromCurrencyDisplay("0");
        return;
      }

      const convertedSGD = amount * transferRate;
      convertedValue = convertedSGD / buyRate + totalFee;
    }

    setErrors({
      fromCurrency: "",
      toCurrency: "",
    });

    console.log(convertedValue);
    setFromCurrencyValueTransfer(convertedValue);
    setFromCurrencyDisplay(convertedValue.toFixed(2));
  };

  const getDocument = async (CompanyBenId) => {
    const docResponse = await getApiData(
      `api/AgentBeneficiary/GetBeneficiaryDocByCompanyBenId?CompanyBenId=${CompanyBenId}`
    );
    const document = await docResponse.data;

    setDocumentData(document);
  };

  return (
    <>
      <BeneficiaryModal
        editMode={3}
        isOpen={modal1}
        currencies={currency}
        toggle={toggleAddModal}
        createBenFor={toCurrencyTransfer}
        getAvaBeneficiary={fetchBeneficiaries}
        getDocument={getDocument}
        documentData={documentData}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Card className="shadow-sm p-4">
          {isLoading ? (
            <Loader />
          ) : (
            <Form onSubmit={handleSubmitTransfer}>
              <div className="mb-3">
                <Label>Select Beneficiary</Label>
                <RequiredAsterisk></RequiredAsterisk>
                <ReactSelect
                  styles={SelectStyleForMenu}
                  value={selectedBeneficiary}
                  placeholder={
                    beneficiary.length
                      ? "Select Beneficiary"
                      : "No beneficiaries available"
                  }
                  onInputChange={(inputValue) => {
                    setSearchTerm(inputValue);
                    setTypedBeneficiaryName(inputValue);
                  }}
                  onChange={(selectedOption) => {
                    if (selectedOption?.value === "create-new") {
                      openADDBeneficiary(typedBeneficiaryName); // Pass typed name to the modal
                    } else {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        fromCurrency: "",
                        toCurrency: "",
                      }));
                      resetTransaction();
                      setBeneficiaryError("");
                      setSelectedBeneficiary(selectedOption);
                    }
                  }}
                  options={[
                    ...beneficiary.map((option) => ({
                      value: option.value,
                      label: option.label,
                      currencyName: option.currencyName,
                      icon: getCurrencyFlag(option.currencyName),
                    })),
                    ...(typedBeneficiaryName &&
                    !beneficiary.some(
                      (b) =>
                        b.label.toLowerCase() ===
                        typedBeneficiaryName.toLowerCase()
                    )
                      ? [
                          {
                            value: "create-new",
                            label: `Create New "${typedBeneficiaryName}"`,
                            currencyName: "",
                            icon: null,
                          },
                        ]
                      : []),
                  ]}
                  formatOptionLabel={({ label, icon, currencyName }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{label}</span>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {icon && (
                          <img
                            src={icon}
                            alt={`${currencyName} flag`}
                            style={{
                              width: 20,
                              height: 20,
                              marginLeft: 8,
                            }}
                          />
                        )}
                        <span style={{ marginLeft: 5, fontSize: 12 }}>
                          {currencyName}
                        </span>
                      </div>
                    </div>
                  )}
                  isClearable
                />

                {beneficiaryError && (
                  <div className="text-danger mt-1" style={{ fontSize: "80%" }}>
                    {beneficiaryError}
                  </div>
                )}
              </div>

              <Row>
                <Col md={6}>
                  <Label>You Give</Label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "#F8F8F8",
                      padding: "2px",
                    }}
                  >
                    <img
                      src={currencyIcons[fromCurrencyTransfer]}
                      alt={`${fromCurrencyTransfer} flag`}
                    />
                    <Input
                      value={fromCurrencyTransfer}
                      disabled
                      style={{ border: "none" }}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <Label>{fromCurrencyTransfer} Amount </Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter Amount"
                    disabled={!selectedBeneficiary}
                    value={fromCurrencyDisplay}
                    onChange={convertFromTransferCurrency}
                    // onFocus={(e) =>
                    //   (e.target.style.border = "2px solid #556ee6")
                    // }
                  />
                  {errors.fromCurrency && (
                    <div className="text-danger" style={{ fontSize: "80%" }}>
                      {errors.fromCurrency}
                    </div>
                  )}
                </Col>
              </Row>

              {selectedBeneficiary !== null ? (
                <>
                  <Row className="mb-3 text-end mt-2">
                    <Col lg={6} className="text-start">
                      <span>Wire Transfer Fee</span>
                    </Col>

                    <Col lg={6}>
                      <span>
                        {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                        {isNaN(convertedWireFee) || convertedWireFee < 0
                          ? 0
                          : convertedWireFee.toFixed(3)}{" "}
                        {fromCurrencyTransfer}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Commission Fee</span>
                    </Col>
                    <Col lg={6}>
                      <span>
                        {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                        {isNaN(convertedCommissionFee) ||
                        convertedCommissionFee < 0
                          ? 0
                          : convertedCommissionFee.toFixed(3)}{" "}
                        {fromCurrencyTransfer}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Agent Fee</span>
                    </Col>
                    <Col lg={6}>
                      <span>
                        {/* <FontAwesomeIcon icon={faMinus} />{" "} */}
                        {isNaN(convertedAgentFee) || convertedAgentFee < 0
                          ? 0
                          : convertedAgentFee.toFixed(3)}{" "}
                        {fromCurrencyTransfer}
                      </span>
                    </Col>
                  </Row>

                  <hr />

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Total {fromCurrencyTransfer}</span>
                    </Col>
                    <Col lg={6}>
                      <span>
                        {/* <FontAwesomeIcon icon={faEquals} />{" "} */}
                        {fromCurrencyValueTransfer > 0
                          ? isNaN(
                              fromCurrencyValueTransfer -
                                (convertedWireFee +
                                  convertedCommissionFee +
                                  convertedAgentFee)
                            )
                            ? 0
                            : fromCurrencyValueTransfer -
                              (
                                convertedWireFee +
                                convertedCommissionFee +
                                convertedAgentFee
                              ).toFixed(3)
                          : 0}{" "}
                        {fromCurrencyTransfer}
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-3 text-end">
                    <Col lg={6} className="text-start">
                      <span>Transfer Rate</span>
                    </Col>
                    <Col lg={6}>
                      <span>
                        {/* {toCurrencyTransfer === "SGD" ? (
                          // <FontAwesomeIcon icon={faX} />
                        ) : (
                          // <FontAwesomeIcon icon={faDivide} />
                        )}{" "} */}
                        {toCurrencyTransfer === "SGD"
                          ? buyRate.toFixed(4)
                          : fromCurrencyValueTransfer > 0
                          ? (buyRate / transferRate).toFixed(4)
                          : 0}
                      </span>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="text-center mt-3 mb-3">
                  <h6>Please Select a Beneficiary</h6>
                </div>
              )}

              <Row>
                <Col md={6}>
                  <Label>Beneficiary Receive</Label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #adb5bd",
                      borderRadius: "2px",
                      background: "#fff",
                      padding: "2px",
                    }}
                  >
                    {(selectedBeneficiary?.currencyCode ||
                      selectedBeneficiary?.currencyName) &&
                      currencyIcons[
                        selectedBeneficiary.currencyCode ||
                          selectedBeneficiary.currencyName
                      ] && (
                        <img
                          src={
                            currencyIcons[selectedBeneficiary.currencyCode] ||
                            currencyIcons[selectedBeneficiary.currencyName]
                          }
                          alt="Currency flag"
                          style={{ width: "20px" }}
                        />
                      )}
                    <Input
                      type="text"
                      className="form-control"
                      value={selectedBeneficiary?.currencyName || ""}
                      disabled
                      style={{ border: "none", background: "#fff" }}
                      placeholder="Select a Beneficiary"
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <Label>{toCurrencyTransfer} Amount</Label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Enter Amount"
                    value={toCurrencyDisplay}
                    onChange={convertToTransferCurrency}
                    // onFocus={(e) =>
                    //   (e.target.style.border = "2px solid #556ee6")
                    // }
                    disabled={!selectedBeneficiary}
                  />
                  {errors.toCurrency && (
                    <div className="text-danger" style={{ fontSize: "80%" }}>
                      {errors.toCurrency}
                    </div>
                  )}
                </Col>
              </Row>

              <div className="d-flex justify-content-between mt-4">
                <Button type="button" color="danger" onClick={resetTransaction}>
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Card>
      )}
    </>
  );
};

export default DashboardTransfer;
