import React, { useEffect, useState, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Badge, Button, Card, CardBody, Row, Col, Container } from "reactstrap";
import { faEye, faDollar, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableContainer from "../../../components/Common/TableContainer";
import Loader from "../../../components/Common/Loader";
import ViewLatestTransactionModel from "../../Dashboard_Agent/Transaction/ViewLatestTransactionModel";
import { getApiData } from "../../../helpers/axiosHelper";
import { formatDateToUserTimeZone } from "../../../common/date";
import { mapStatus } from "../../../common/data/statusLabels";
import { useLatestTransactions } from "../../../common/data/latest-transaction-context";
import {
  isAgentSuperAdmin,
  isAgentBranchAdmin,
} from "../../../common/data/commonFunction";
import Help from "../../../components/Common/Help";
import { displayFlag, getCurrencyFlag } from "../../../common/data/currency";

const ManageTransaction = () => {
  const transactionTypeMap = {
    2: { label: "Buy", color: "success" },
    1: { label: "Sell", color: "danger" },
    3: { label: "Transfer", color: "primary" },
  };

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(":");
    const meridiem = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes} ${meridiem}`;
  };
  const [branchData, setBranchData] = useState();
  const [editMode, setEditMode] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [transactionData, setTransactionData] = useState([]);
  const [modal1, setModal1] = useState(false);
  const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [buyRateforCharges, setBuyRateForCharges] = useState(null);
  const [branchID, setBranchID] = useState("");
  const [optime, setOptime] = useState([]);
  const { branchId, currencyList } = useLatestTransactions();
  const [transactionID, setTransactionID] = useState("");
  const [isBranchActive, setIsBranchActive] = useState(true);

  const Days = {
    0: "All Days",
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday",
  };
  const reload = () => {
    getTransaction();
  };
  const ViewTransactionDetails = (cellProps) => {
    const { transactionId, branchId } = cellProps;
    setTransactionID(transactionId);
    setBranchID(branchId);

    const getTransactionbyId = async () => {
      try {
        const response = await getApiData(
          `api/Transaction/GetTransaction?TransactionID=${transactionId}`
        );
        const branchResponse = await getApiData(
          `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
        );

        let rateHistoryId = "";
        if (
          response.transactionType === 2 &&
          response.toRateHistoryId !== null
        ) {
          rateHistoryId = response.toRateHistoryId;
        } else if (
          response.transactionType === 1 &&
          response.fromRateHistoryId !== null
        ) {
          rateHistoryId = response.fromRateHistoryId;
        }
        if (response.transactionType === 1 || response.transactionType === 2) {
          const chargesResponse = await getApiData(
            `api/RateMaster/GetTransactionCharges?rateHistoryId=${rateHistoryId}`
          );
          setChargesDetails(chargesResponse.data);
        } else if (
          response.transactionType === 3 &&
          // response.toCurrType !== "SGD" &&
          response.toRateHistoryId !== null
        ) {
          const transfer2charges = await getApiData(
            `api/RateMaster/GetTransactionCharges?rateHistoryId=${response.toRateHistoryId}`
          );
          const rateResponse = await getApiData(
            `api/RateMaster/GetTransactionRate?fromCurr=${response.fromCurrType}`
          );
          setBuyRateForCharges(rateResponse.data.buyRate);
          setChargesDetails(transfer2charges.data);
        } else if (
          response.transactionType === 3 &&
          response.fromCurrType === "SGD" &&
          response.fromRateHistoryId !== null
        ) {
          const transfer1charges = await getApiData(
            `api/RateMaster/GetTransactionCharges?rateHistoryId=${response.fromRateHistoryId}`
          );
          setBuyRateForCharges(null);
          setChargesDetails(transfer1charges.data);
        } else setChargesDetails("");

        if (branchResponse.data.branch.active === 1) setIsBranchActive(true);
        else setIsBranchActive(false);

        const mappedResponse = branchResponse.data.operationalTime.map(
          (item) => {
            const startTime12 = convertTo12HourFormat(item.startTime);
            const endTime12 = convertTo12HourFormat(item.endTime);
            return {
              day: Days[item.day],
              startTime: startTime12,
              endTime: endTime12,
            };
          }
        );

        setBranchData(branchResponse.data.branch);
        setOptime(mappedResponse);
        setSendTransactiondetails(response);
        setCommentData(response.comments);
        setDocumentData(response.transactionDocs);
        setBeneficiaryDetails(response.beneficiaryDetails);
      } catch (error) {
        console.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    };

    getTransactionbyId();
    toggleViewModal();
  };

  useEffect(() => {
    if (branchId) {
      getTransaction();
    }
  }, [branchId]);

  const getTransaction = async () => {
    try {
      setIsLoading(true);
      const response = await getApiData(
        `api/Transaction/GetTransactionForPendingStatus?AgentBranchId=${branchId}`
      );
      const mappedResponse = response.data.map((item, index) => ({
        transactionId: item.transactionId,
        branchId: item.fromBranchId,
        iDandBranch: item.transactionId + "-" + item.branchName,
        index: index + 1,
        name: item.name,
        branch: item.branchName,
        transactionType: item.transactionType,
        date: formatDateToUserTimeZone(item.transactionDate),
        fromCurrType: item.fromCurrType,
        toCurrType: item.toCurrType,
        fromCurrValue: parseFloat(Number(item.fromCurrValue).toFixed(2)),
        toCurrValue: parseFloat(Number(item.toCurrValue).toFixed(2)),
        status: mapStatus(item.status),
        convRate: parseFloat(Number(item.exchangeRate).toFixed(8)),
        transactingCurrency: item.toCurrType,
        pickup: item.pickup
          ? formatDateToUserTimeZone(item.pickup)
          : "Not specified",
        currencyFlag1: getCurrencyFlag(currencyList, item.fromCurrType),
        currencyFlag2: getCurrencyFlag(currencyList, item.toCurrType),
      }));
      setTransactionData(mappedResponse);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to load transactions", {
        position: "top-right",
        autoClose: 3000,
      });
      setIsLoading(false);
    }
  };

  const toggleViewModal = () => setModal1(!modal1);

  const columns = useMemo(
    () => [
      {
        Header: "Id-Branch",
        accessor: "iDandBranch",
        filterable: false,
        disableFilters: true,
      },
      ...(isAgentSuperAdmin || isAgentBranchAdmin
        ? [
            {
              Header: "Agent",
              accessor: "name",
              filterable: false,
              disableFilters: true,
            },
          ]
        : []),
      {
        Header: "Currency",
        accessor: "transactingCurrency",
        disableFilters: true,
        Cell: (cellProps) => {
          const {
            transactionType,
            fromCurrType,
            toCurrType,
            currencyFlag1,
            currencyFlag2,
          } = cellProps.row.original;
          const transactingCurrency =
            transactionType === 1 ? fromCurrType : toCurrType;
          const { label, color } = transactionTypeMap[transactionType] || {};

          return (
            <div>
              {displayFlag(currencyFlag1)}
              <span>{` ${fromCurrType}`}&nbsp;</span>
              <Badge className={`font-size-11 badge-soft-${color}`}>
                {label}
              </Badge>

              <span>{displayFlag(currencyFlag2)}</span>
              <span>{` ${toCurrType}`}</span>
            </div>
          );
        },
      },
      {
        Header: "From Amount",
        accessor: "toCurrValue",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { fromCurrValue, fromCurrType } = cellProps.row.original;

          return (
            <span>
              {fromCurrValue} {fromCurrType}
            </span>
          );
        },
      },
      {
        Header: "Rate",
        accessor: "convRate",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Converted Amount",
        accessor: "fromCurrValue",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { toCurrValue, toCurrType } = cellProps.row.original;
          return (
            <span>
              {toCurrValue} {toCurrType}
            </span>
          );
        },
      },
      {
        Header: "Booked Date",
        accessor: "date",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Pickup Date",
        accessor: "pickup",
        disableFilters: true,
        Cell: ({ value }) => {
          return value ? value : "Not mentioned";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        Cell: ({ row }) => (
          <Badge
            className={`font-size-11 badge-soft-${row.original.status.color}`}
          >
            {row.original.status.label}
          </Badge>
        ),
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: (cellProps) => (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={(e) => {
              setEditMode(true);
              e.preventDefault();
              ViewTransactionDetails(cellProps.row.original);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        {modal1 && (
          <ViewLatestTransactionModel
            optime={optime}
            isOpen={modal1}
            showAction={true}
            branchID={branchID}
            editMode={editMode}
            branchData={branchData}
            toggle={toggleViewModal}
            commentData={commentData}
            transactionID={transactionID}
            sendTransactionDetails={sendTransactionDetails}
            isBranchActive={isBranchActive}
            reload={reload}
            beneficiaryDetails={beneficiaryDetails}
            chargesDetails={chargesDetails}
            buyRateForCharges={buyRateforCharges}
            documentData={documentData}
          />
        )}

        {isLoading ? (
          <Loader />
        ) : (
          <Container fluid>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4 d-flex justify-content-between">
                      Manage Transactions
                      <Help to={"HELP DOCUMENTATION FOR MANAGE TRANSACTIONS"} />
                    </h4>
                    {transactionData.length > 0 ? (
                      <div
                        className="table-container"
                        style={{ padding: "1rem" }}
                      >
                        <TableContainer
                          columns={columns}
                          customPageSize={transactionData.length}
                          data={transactionData}
                        />
                      </div>
                    ) : (
                      <div
                        className="text-muted d-flex justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        <h3>No Records to Show</h3>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default ManageTransaction;
