import React from "react";
import { Navigate } from "react-router-dom";
import { path } from "./Path";

const AuthProtected = (props) => {
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate
        to={{ pathname: path.login, state: { from: props.location } }}
      />
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthProtected;
