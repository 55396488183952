import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBellSlash,
  faExchangeAlt,
  faClock,
  faCalendarDay,
  faCalendarAlt,
  faTasks,
  faInfoCircle,
  faEllipsisV,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getApiData, putApiData } from "./../../../helpers/axiosHelper";
import { useNotificationService } from "./../../../helpers/NotificationService";
import ViewLatestTransactionModel from "../../../pages/Dashboard_Agent/Transaction/ViewLatestTransactionModel";
import { path } from "../../../routes/Path";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
    sync,
    loading
} from "../../../common/data/icons";
export const NotificationType = { Action: 1, Info: 2 };

const Notifications = () => {
  const [notification, setNotification] = useState({
    bellMenu: false,
    activeTab: 1,
    totalCount: 0,
    actionCount: 0,
    infoCount: 0,
    actionNotifications: { today: [], yesterday: [], older: [] },
    infoNotifications: { today: [], yesterday: [], older: [] },
  });
  const [modal1, setModal1] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);
  const [transactionId, setTransactionId] = useState(null);
  const navigate = useNavigate();
  const [Isloading, setLoading] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [fromBranchId, setBranchID] = useState("");
  const [branchData, setBranchData] = useState(null);
  const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
  const [optime, setOptime] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
 //const { branchId } = useLatestTransactions();
  const handleRefresh = async () => {
    try {
      setLoading(true);
      await getNotifications();
    } finally {
      setLoading(false);
    }
  };

  const toggleBellMenu = () =>
    setNotification((prev) => ({ ...prev, bellMenu: !prev.bellMenu }));

  const toggleTab = (tab) =>
    setNotification((prev) => ({ ...prev, activeTab: tab }));

  const categorizeNotifications = (notifications) => {
    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOfYesterday = new Date(today.setDate(today.getDate() - 1));

    return notifications.reduce(
      (acc, notification) => {
        const date = new Date(notification.date);
        if (date >= startOfToday) acc.today.push(notification);
        else if (date >= startOfYesterday) acc.yesterday.push(notification);
        else acc.older.push(notification);
        return acc;
      },
      { today: [], yesterday: [], older: [] }
    );
  };

  const getNotificationTitle = (id) =>
    ({
      1: "Transaction",
      2: "Profile",
    }[id] || `Title ${id}`);

  const getNotifications = async () => {
      const resp = await getApiData("api/AgentNotification/GetNotification");
    const notifications = resp?.map((x) => ({
      title: getNotificationTitle(x.title),
      desc: x.description,
      value: x.notificationID,
      date: x.createdAt,
      type: x.type,
      forUID: x.forUID,
      referenceID: x.referenceID,
      BranchID: x.branchID
    }));

    const actionNotifications = notifications.filter(
      (x) => x.type === NotificationType.Action
    );
    const infoNotifications = notifications.filter(
      (x) => x.type === NotificationType.Info
    );

    setNotification((prev) => ({
      ...prev,
      totalCount: notifications.length,
      actionCount: actionNotifications.length,
      infoCount: infoNotifications.length,
      actionNotifications: categorizeNotifications(actionNotifications),
      infoNotifications: categorizeNotifications(infoNotifications),
    }));
  };

  useEffect(() => {
    getNotifications();
  }, []);

    const handleMarkAllAsRead = async () => {
        try {
            const allUnreadNotifications = [
                ...notification.actionNotifications.today,
                ...notification.actionNotifications.yesterday,
                ...notification.actionNotifications.older,
                ...notification.infoNotifications.today,
                ...notification.infoNotifications.yesterday,
                ...notification.infoNotifications.older,
            ];

            // Process all notifications using the existing handleNotificationClick method
            const promises = allUnreadNotifications.map(notif =>
                handleNotificationClick(notif.value, notif.type)
            );

            // Wait for all notifications to be processed
            await Promise.all(promises);

        } catch (error) {
            console.error("Error marking all notifications as read:", error);
            // Refresh notifications to ensure correct state
            getNotifications();
        }
    };

    const handleNotificationReceived = (data) => {
        if (!data) return;

        const formattedData = {
            title: getNotificationTitle(data.title),
            desc: data.description,
            value: data.notificationID,
            date: data.createdAt,
            type: data.type,
            isRead: data.isRead ? 1 : 0,
            referenceID: data.referenceID,
            BranchID: data.branchID
        };

        const isAction = data.type === NotificationType.Action;
        const isInfo = data.type === NotificationType.Info;

        setNotification(prev => {
            const updateNotifications = (notifications, newNotification, shouldRemove) => {
                // Initialize if undefined
                if (!notifications) {
                    notifications = { today: [], yesterday: [], older: [] };
                }

                if (shouldRemove) {
                    // Remove notification if marked as read
                    const allNotifications = [
                        ...notifications.today,
                        ...notifications.yesterday,
                        ...notifications.older
                    ].filter(notif => notif.value !== newNotification.value);

                    return categorizeNotifications(allNotifications);
                } else {
                    // Merge new notification with existing ones
                    const allNotifications = [
                        ...notifications.today,
                        ...notifications.yesterday,
                        ...notifications.older,
                        newNotification
                    ];

                    return categorizeNotifications(allNotifications);
                }
            };

            const updatedNotification = {
                ...prev,
                totalCount: formattedData.isRead ? prev.totalCount - 1 : prev.totalCount + 1,
                actionCount: isAction
                    ? (formattedData.isRead ? prev.actionCount - 1 : prev.actionCount + 1)
                    : prev.actionCount,
                infoCount: isInfo
                    ? (formattedData.isRead ? prev.infoCount - 1 : prev.infoCount + 1)
                    : prev.infoCount,
            };

            if (isAction) {
                updatedNotification.actionNotifications = updateNotifications(
                    prev.actionNotifications,
                    formattedData,
                    formattedData.isRead
                );
            }

            if (isInfo) {
                updatedNotification.infoNotifications = updateNotifications(
                    prev.infoNotifications,
                    formattedData,
                    formattedData.isRead
                );
            }

            return updatedNotification;
        });
    };
    useNotificationService({
        onNotificationReceived: handleNotificationReceived
    });

  const formatTime = (date) =>
    new Date(date).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const handleNotificationClick = async (notificationID, type) => {
        try {
            await putApiData(
                `api/AgentNotification/ReadNotification?NotificationId=${notificationID}`
            );

            setNotification((prev) => {
                const updatedActionNotifications = removeNotification(
                    prev.actionNotifications,
                    notificationID
                );
                const updatedInfoNotifications = removeNotification(
                    prev.infoNotifications,
                    notificationID
                );

                return {
                    ...prev,
                    totalCount: prev.totalCount - 1,
                    actionNotifications: updatedActionNotifications,
                    infoNotifications: updatedInfoNotifications,
                    actionCount:
                        type === NotificationType.Action
                            ? prev.actionCount - 1
                            : prev.actionCount,
                    infoCount:
                        type === NotificationType.Info
                            ? prev.infoCount - 1
                            : prev.infoCount,
                };
            });
        } catch (error) {
            console.log("Error marking notification as read:", error);
            throw error; // Propagate error to handleMarkAllAsRead
        }
    };

  const removeNotification = (notifications, notificationID) => {
    return Object.fromEntries(
      Object.entries(notifications).map(([key, items]) => [
        key,
        items.filter((item) => item.value !== notificationID),
      ])
    );
  };
  const renderEmpty = () => (
    <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
      <FontAwesomeIcon
        icon={faBellSlash}
        shake
        size="2xl"
        className="mt-4"
        color="#FF5252"
      />
      <p>No unread notifications</p>
    </div>
  );

    const renderNotificationItems = (items, showDate = false) =>     
    items.map((x) => (
      <DropdownItem
        key={x.value}
        className="d-flex align-items-center justify-content-between"
        onClick={() => {
            if (x.type === NotificationType.Action) {
                console.log("referenceID", x.referenceID);
                console.log("items", x);
                // Passing referenceID and branchID to ViewTransactionDetails
                ViewTransactionDetails({
                    transactionId: x.referenceID,
                    fromBranchId: x.BranchID,  // Assuming x.branchID is available
                });
              setTransactionId(x.referenceID);
              toggleViewModal();
          } else if (x.type === NotificationType.Info) {
                return;
          }
          }}
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon={faExchangeAlt}
            size="sm"
            color={getCategoryColor(x.date)}
            className="me-3"
          />
          <div>
            <strong>{x.title}</strong>
            <p className="mb-0">{x.desc}</p>
          </div>
        </div>
        <small className="text-muted">
          {showDate ? formatDate(x.date) + " " : ""}
          {formatTime(x.date)}
        </small>
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="sm"
          color="#28a745"
          className="ms-2"
          onClick={(e) => {
            e.stopPropagation();
            handleNotificationClick(x.value, x.type);
          }}
          title="Mark as read"
        />
      </DropdownItem>
    ));

  const formatDate = (date) =>
    new Date(date).toLocaleDateString([], {
      month: "short",
      day: "numeric",
    });

  //const renderTabContent = (notifications) =>
  //  Object.entries(notifications)
  //    .filter(([_, items]) => items.length > 0)
  //    .map(([category, items]) => (
  //      <div key={category} className="mt-3">
  //        <h5 className="d-flex align-items-center">
  //          <FontAwesomeIcon
  //            icon={getCategoryIcon(category)}
  //            size="sm"
  //            color={getIconColor(category)}
  //            className="me-2"
  //          />
  //          {category.charAt(0).toUpperCase() + category.slice(1)}
  //        </h5>
  //        {renderNotificationItems(items, category === "older")}
  //      </div>
  //    ));

    const renderCategory = (category, items) => {
        console.log("itemssssssssssss", items);
        if (items?.length === 0) return null;
        return (
            <div key={category}>
                <h5 className="mt-3 d-flex align-items-center">
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                </h5>
                {renderNotificationItems(items)}
            </div>
        );
    };

    const renderTabContent = (notifications) =>
        Object.entries(notifications).map(([category, items]) =>
            renderCategory(category, items)
        );

  const getCategoryIcon = (category) =>
    ({
      today: faCalendarDay,
      yesterday: faClock,
      older: faCalendarAlt,
    }[category]);

  const getIconColor = (category) =>
    ({
      today: "#556ee6",
      yesterday: "#50a5f1",
      older: "#9E9E9E",
    }[category]);

  const getCategoryColor = (date) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const yesterday = new Date(today).setDate(new Date().getDate() - 1);
    const notificationDate = new Date(date);

    return notificationDate >= today
      ? "#34c38f"
      : notificationDate >= yesterday
      ? "#FFC107"
      : "#9E9E9E";
  };

  const { bellMenu, activeTab, actionNotifications, infoNotifications } =
    notification;


    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(":");
        const meridiem = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${meridiem}`;
    };

    const Days = {
        0: "All Days",
        1: "Sunday",
        2: "Monday",
        3: "Tuesday",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday",
    };

    const ViewTransactionDetails = (cellProps) => {
        const { transactionId, fromBranchId } = cellProps;
        setTransactionId(transactionId);
        setBranchID(fromBranchId);

        const getTransactionbyId = async () => {
            try {
                const response = await getApiData(
                    `api/Transaction/GetTransaction?TransactionID=${transactionId}`
                );

                const branchResponse = await getApiData(
                    `api/BranchMaster/GetBranchWithOperation?branchId=${fromBranchId}`
                );

                const mappedResponse = branchResponse?.data.operationalTime.map(
                    (item) => {
                        // Convert start time to 12-hour format
                        const startTime24 = item.startTime;
                        const startTime12 = convertTo12HourFormat(startTime24);

                        // Convert end time to 12-hour format
                        const endTime24 = item.endTime;
                        const endTime12 = convertTo12HourFormat(endTime24);

                        return {
                            day: Days[item.day],
                            startTime: startTime12,
                            endTime: endTime12,
                        };
                    }
                );

                // const branchInfo = branchResponse.data.branch;
                // console.log("BranchInfo " +branchInfo);
                setBranchData(branchResponse?.data.branch);
                setOptime(mappedResponse);

                const commentData = response.comments;
                setSendTransactiondetails(response);
                setCommentData(commentData);
            } catch (error) {
                toast.error(error, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        };

        getTransactionbyId();
        toggleViewModal();
    };


    return (
        <>
            <Dropdown
                isOpen={bellMenu}
                toggle={toggleBellMenu}
                className="ms-3"
            >
                <DropdownToggle
                    tag="button"
                    className="btn header-item position-relative"
                >
                    <FontAwesomeIcon icon={faBell} size="lg" />
                    {notification.totalCount > 0 && (
                        <span className="badge bg-danger rounded-circle position-absolute top-0 start-100 translate-middle mt-4 ms-n2">
                            {notification.totalCount}
                        </span>
                    )}
                </DropdownToggle>

                <DropdownMenu
                    className="dropdown-menu-end p-3"
                    style={{
                        // width: "500px",
                        width: window.innerWidth < 768 ? "340px" : "600px",
                        maxHeight: "400px",
                        overflowY: "scroll",
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>Notifications</h5>
                        {/*<FontAwesomeIcon*/}
                        {/*    icon={faSyncAlt}*/}
                        {/*    style={{*/}
                        {/*        cursor: "pointer",*/}
                        {/*        transition: "transform 0.5s linear",*/}
                        {/*        transform: loading ? "rotate(360deg)" : "rotate(0deg)",*/}
                        {/*    }}*/}
                        {/*    onClick={handleRefresh}*/}
                        {/*/>*/}

                        <div className="ms-auto d-flex align-items-center">
                            {Isloading ? loading() : sync(handleRefresh)}
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle tag="button" className="btn">
                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={handleMarkAllAsRead}>
                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            size="sm"
                                            className="me-2"
                                        />
                                        Mark All as Read
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>

                    <Nav tabs className="mb-3">
                        {[
                            { name: `Actions (${notification.actionCount})`, icon: faTasks },
                            { name: `Info (${notification.infoCount})`, icon: faInfoCircle },
                        ].map((tab, idx) => (
                            <NavItem key={idx}>
                                <NavLink
                                    className={`d-flex align-items-center ${activeTab === idx + 1 ? "active" : ""
                                        }`}
                                    style={{
                                        backgroundColor:
                                            activeTab === idx + 1 ? "#eff2f7" : "transparent",
                                    }}
                                    onClick={() => toggleTab(idx + 1)}
                                >
                                    <FontAwesomeIcon
                                        icon={tab.icon}
                                        size="lg"
                                        color={idx === 0 ? "#556ee6" : "#f46a6a"}
                                        className="me-2"
                                    />
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                            {Object.values(actionNotifications).some((arr) => arr.length)
                                ? renderTabContent(actionNotifications)
                                : renderEmpty()}
                        </TabPane>
                        <TabPane tabId={2}>
                            {Object.values(infoNotifications).some((arr) => arr.length)
                                ? renderTabContent(infoNotifications)
                                : renderEmpty()}
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
            {modal1 && (
                <ViewLatestTransactionModel
                    isOpen={modal1}
                    toggle={toggleViewModal}
                    transactionId={transactionId}
                   // optime={optime}
                    branchID={fromBranchId}
                    //editMode={editMode}
                    branchData={branchData}
                    commentData={commentData}
                    sendTransactionDetails={sendTransactionDetails}
                    //reload={reload}
                />
            )}
        </>
    );
};

export default Notifications;
