import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { roleList } from "../../common/data/roles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { getApiData } from "../../helpers/axiosHelper";

function CardUser(props) {
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");

  const roleMap = new Map(roleList.map((role) => [role.value, role.label]));

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      setUserName(authUser?.username);
      setRole(roleMap.get(authUser?.role));
    }
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const profileResponse = await getApiData(
      "api/AgentProfile/GetAgentProfileByLoggedUser"
    );

    const profile = await profileResponse.data;

    let uName = `${profile.firstName || ""}  ${profile.lastName || ""}`;
    setUserName(uName);
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="me-3">
                      <FontAwesomeIcon
                        style={{
                          width: "70px",
                          height: "40px",
                          marginTop: "10px",
                        }}
                        icon={faUser}
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2 text-primary">Welcome to Cresent</p>
                        <h5 className="mb-1">{userName}</h5>
                        <p className="mb-0">{role}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default CardUser;
