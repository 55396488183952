const config = {
    // apiUrl: "https://cexagentapi.jaffar.au/",
    apiUrl: "https://sitapi.crescentexchange.com/agent/",
    //apiUrl: "https://prodapi.crescentexchange.com/agent/",
    // apiUrl: "https://localhost:7298/",
    //notificationUrl: "https://localhost:7298/Agenthub",
    notificationUrl: "https://sitapi.crescentexchange.com/agent/Agenthub",
    // Add other configuration options as needed
};

export default config;
