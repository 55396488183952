import React, { useState, useContext } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Modal,
  Button,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
import profileImg from "../../../images/profile-img.png";
import logoImg from "../../../images/crescent_logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { GoogleLogin } from "@react-oauth/google";
import { path } from "../../routes/Path";
import ButtonSpinner from "../../components/Common/ButtonSpinner";
import singpassLogo from "../../../images/myinfo.png";
import { api } from "../../config";
import { BaseRegisterContext } from "../../common/data/context";

const Register = (props) => {
  document.title = "Crescent Exchange | Register";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [modal_filter, setmodal_filter] = useState(false);
  const [companyCode, setCompanyCode] = useState("");
  const [startGoogleSignIn, setStartGoogleSignIn] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { singPassData } = useContext(BaseRegisterContext);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const isChrome = (() => {
    const isChromium = !!window.chrome;
    const isGoogle = /Google Inc/.test(navigator.vendor);
    const isNotEdge = !/Edg/.test(navigator.userAgent);
    const isNotOpera = !/OPR/.test(navigator.userAgent);
    return isChromium && isGoogle && isNotEdge && isNotOpera;
  })();

  function tog_addFilters() {
    // resetForm();
    setmodal_filter((prevState) => !prevState);
    if (setmodal_filter === false) {
      setStartGoogleSignIn(true);
    } else {
      setStartGoogleSignIn(false);
    }
  }
  const handleOpenDialog = () => {
    setmodal_filter(true);
  };
  const handleLogin = async () => {
    try {
      const response = await getApiData("api/Singpass/Login");

      if (response && response.codeChallenge && response.State) {
        const client_id = api.singPass.clientId;
        const redirect = encodeURIComponent(api.singPass.redirectUri);
        // const scope = encodeURIComponent("openid aliasname dob email mobileno name sex");
        const scope = encodeURIComponent(
          "openid uinfin aliasname dob email mobileno name sex"
        );
        const code_challenge = response.codeChallenge;
        const state = response.State;
        const nonce = "bb5e1672-a460-4a9b-874e-c38d55ac3922";

        const url = `${api.singPass.authUrl}?scope=${scope}&response_type=code&redirect_uri=${redirect}&nonce=${nonce}&client_id=${client_id}&code_challenge=${code_challenge}&code_challenge_method=S256&state=${state}`;

        console.log("Redirecting to:", url);
        sessionStorage.setItem("singpass", JSON.stringify(response));
        sessionStorage.setItem("isPage", "Register");
        window.location.href = url;
      } else {
        console.error("Invalid API Response: Missing codeChallenge or State");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  const handleSuccess = (credentialResponse) => {
    // If you are using the authorization code flow, you will receive a code to be exchanged for an access token
    const authorizationCode = credentialResponse.credential;
    setToken(authorizationCode);
    setSubmitting(true);
    // Send the authorization code to your backend server
    postApiData(
      "api/User/RegisterWithGoogle",
      JSON.stringify({ idToken: authorizationCode, companyCode: companyCode })
    )
      .then((response) => {
        if (response?.success) {
          toast.success("Registered Successfully", {
            position: "top-right",
            autoClose: 3000,
            onClose: () => {
              navigate(path.login);
              setSubmitting(false);
            },
          });
        } else {
          toast.warning(response?.message || "Registration Failed", {
            position: "top-right",
            autoClose: 3000,
          });
          setStartGoogleSignIn(false);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error exchanging authorization code:", error);
        setSubmitting(false);
      });
  };

  const handleError = (errorResponse) => {
    console.error("Google login failed", errorResponse);
  };

  const handleCompanyCode = (e) => {
    const companyCode = e.target.value;
    setCompanyCode(companyCode);
    setStartGoogleSignIn(true);
  };

  const handleSubmit = (e) => {
    setStartGoogleSignIn(true);
    setmodal_filter(false);
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false, // Disable validation on change
    validateOnBlur: true,

    initialValues: {
      email: "" || singPassData?.email,
      password: "" || singPassData?.password,
      firstName: "" || singPassData?.name,
      lastName: "" || singPassData?.lastname,
      mobile: "" || singPassData?.mobileNo,
      companyCode: "" || singPassData?.companyCode,
      password_confirmation: "" || singPassData?.password,
      policy: false,
      uinFin: "" || singPassData?.uinFin,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(" Please provide a valid email address")
        .required("Please Enter Your Email ID"),
      firstName: Yup.string()
        .max(50, "Max character's reached.")
        .min(3, "Atleast 3 letter's required.")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9a-zA-Z!@#$%^&*(),.?":{}|<>])[^\s]+$/,
          "Must include at least one letter and can contain numbers and special characters."
        )
        .notOneOf(
          [/^[^a-zA-Z]*$/, /^[0-9!@#$%^&*(),.?":{}|<>]*$/],
          "Cannot consist of only numbers or special characters."
        )
        .required("Please provide the first name"),
      lastName: Yup.string()
        .max(50, "Max character's reached.")
        .min(3, "Atleast 3 letter's required.")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9a-zA-Z!@#$%^&*(),.?":{}|<>])[^\s]+$/,
          "Must include at least one letter and can contain numbers and special characters."
        )
        .notOneOf(
          [/^[^a-zA-Z]*$/, /^[0-9!@#$%^&*(),.?":{}|<>]*$/],
          "Cannot consist of only numbers or special characters."
        )
        .required("Please provide the first name"),

      mobile: Yup.string()
        .matches(/^\+?\d{8,14}$/, "Mobile Number must be 8-14 digits")
        .required("Please Enter Your Mobile number"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(8, "Password must be 8 characters long")
        .matches(/[^\w]/, "Password requires a symbol")
        .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter"),
      password_confirmation: Yup.string()
        .required("Please Enter Your Password to Confirm")
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      companyCode: Yup.string().required("Please enter the Company Code"),
      policy: Yup.boolean()
        .oneOf([true], "You must agree to the terms and conditions")
        .required("You must agree to the terms and conditions"),
    }),
    onSubmit: async (values, { resetForm }) => {
      // why combined values?
      setIsLoading(true);
      setSubmitting(true);
      const combinedValues = { ...values };
      const response = await postApiData(
        "api/User/RegisterAgent",
        JSON.stringify(combinedValues)
      );
      if (response.success === true) {
        if (response.data.uid) {
          values.uid = response.data.uid;
          values.email = response.data.email;
          toast.success(
            "User Registered Successfully, redirecting to verification",
            {
              position: "top-right",
              autoClose: 3000,
              onClose: () => {
                navigate(`/emailverification/${response.data.uid}`);
                setIsLoading(false);
                setSubmitting(false);
              },
            }
          );
        }
      } else {
        toast.error(response.message, {
          position: "top-right",
          autoClose: 3000,
        });
        // resetForm();
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div
        className="home-btn d-block"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "10px",
          zIndex: 1000,
        }}
      >
        <Link
          to="/home"
          className="text-dark"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          <i className="bx bx-home h2" style={{ marginRight: "5px" }} />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Registration</h5>
                        <p>
                          Get your Crescent Exchange Overseas Agent account now.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="avatar-md profile-user-wid mb-2">
                    <Link to="/">
                      <span className="avatar-title rounded-circle bg-light w-75 h-75 border border-dark border-2">
                        <img
                          src={logoImg}
                          alt="crescent.png"
                          className="rounded-circle w-75"
                        />
                      </span>
                    </Link>
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                      }}
                    >
                      {/* <Row>
                                                <Col>
                                                    <div className="mb-3">
                                                        <Label className="form-label">NRIC or FIN number</Label>
                                                        
                                                        <Input
                                                            id="uinFin"
                                                            name="uinFin"
                                                            className="form-control"
                                                            placeholder="NRIC/FIN"
                                                            type="input"
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.uinFin || ""}
                                                            onChange={validation.handleChange}
                                                            disabled={isLoading}
                                                        />
                                                        <div>
                                                       <small className="text-muted">If you have a Singpass ID, you can register here. You can also log in by clicking "Login with Singpass" or using the standard login form.</small>
                                                       </div>
                                                    </div>
                                                </Col>
                                            </Row> */}
                      <Row>
                        <Col lg="12">
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <RequiredAsterisk />
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="example@gmail.com"
                              type="input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={isLoading}
                              value={validation.values.email || ""}
                              invalid={
                                validation.errors.email &&
                                validation.touched.email
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <RequiredAsterisk />
                            <Input
                              id="firstName"
                              name="firstName"
                              className="form-control"
                              placeholder="Enter Your First Name"
                              type="input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={isLoading}
                              value={validation.values.firstName || ""}
                              invalid={
                                validation.errors.firstName &&
                                validation.touched.firstName
                              }
                            />
                            {validation.touched.firstName &&
                            validation.errors.firstName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.firstName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <RequiredAsterisk />
                            <Input
                              id="lastName"
                              name="lastName"
                              className="form-control"
                              placeholder="Enter Your Last Name"
                              type="input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={isLoading}
                              value={validation.values.lastName || ""}
                              invalid={
                                validation.errors.lastName &&
                                validation.touched.lastName
                              }
                            />
                            {validation.touched.lastName &&
                            validation.errors.lastName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.lastName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <RequiredAsterisk />
                              <div className="input-group">
                                <Input
                                  name="password"
                                  type={
                                    isNewPasswordVisible ? "text" : "password"
                                  }
                                  placeholder="Enter Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  disabled={isLoading}
                                  invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                      ? true
                                      : false
                                  }
                                />
                                {isChrome && (
                                  <button
                                    type="button"
                                    className="btn"
                                    style={{ border: "1px solid #ced4da" }}
                                    onClick={() =>
                                      setIsNewPasswordVisible(
                                        !isNewPasswordVisible
                                      )
                                    }
                                  >
                                    {isNewPasswordVisible ? (
                                      <i className="bx bx-hide"></i>
                                    ) : (
                                      <i className="bx bx-show"></i>
                                    )}
                                  </button>
                                )}
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </Col>

                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">
                                {" "}
                                Confirm Password
                              </Label>
                              <RequiredAsterisk />
                              <div className="input-group">
                                <Input
                                  name="confirmPassword"
                                  type={
                                    isConfirmPasswordVisible
                                      ? "text"
                                      : "password"
                                  }
                                  placeholder="Password"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.confirmPassword || ""
                                  }
                                  disabled={isLoading}
                                  invalid={
                                    validation.touched.confirmPassword &&
                                    validation.errors.confirmPassword
                                      ? true
                                      : false
                                  }
                                />
                                {isChrome && (
                                  <button
                                    type="button"
                                    className="btn"
                                    style={{ border: "1px solid #ced4da" }}
                                    onClick={() =>
                                      setIsConfirmPasswordVisible(
                                        !isConfirmPasswordVisible
                                      )
                                    }
                                  >
                                    {isConfirmPasswordVisible ? (
                                      <i className="bx bx-hide"></i>
                                    ) : (
                                      <i className="bx bx-show"></i>
                                    )}
                                  </button>
                                )}
                                {validation.touched.confirmPassword &&
                                validation.errors.confirmPassword ? (
                                  <FormFeedback type="invalid">
                                    <div>
                                      {validation.errors.confirmPassword}
                                    </div>
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">Mobile</Label>
                            <RequiredAsterisk />
                            <Input
                              id="mobile"
                              name="mobile"
                              className="form-control"
                              placeholder="Enter Your Mobile. no"
                              type="input"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={isLoading}
                              value={validation.values.mobile || ""}
                              invalid={
                                validation.errors.mobile &&
                                validation.touched.mobile
                              }
                            />
                            {validation.touched.mobile &&
                            validation.errors.mobile ? (
                              <FormFeedback type="invalid">
                                {validation.errors.mobile}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label className="form-label">Company Code</Label>
                            <RequiredAsterisk />
                            <Input
                              id="companyCode"
                              name="companyCode"
                              className="form-control"
                              placeholder="Enter Company Code"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={isLoading}
                              value={validation.values.companyCode || ""}
                              invalid={
                                validation.errors.companyCode &&
                                validation.touched.companyCode
                              }
                            />
                            {validation.touched.companyCode &&
                            validation.errors.companyCode ? (
                              <FormFeedback type="invalid">
                                {validation.errors.companyCode}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <div className="mb-3">
                          <p className="mb-0">
                            <Input
                              name="policy"
                              type="checkbox"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              disabled={isLoading}
                              // checked={validation.values.policy}
                              invalid={
                                validation.touched.policy &&
                                validation.errors.policy
                              }
                            />{" "}
                            Click here to agree to the terms and conditions{" "}
                            <br /> of Crescent Exchange{" "}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                          {validation.touched.policy &&
                          validation.errors.policy ? (
                            <div
                              className="invalid-feedback h-5"
                              style={{ display: "block" }}
                            >
                              {validation.errors.policy}
                            </div>
                          ) : null}
                        </div>
                        <div className="mt-4 text-center">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={submitting}
                          >
                            {submitting ? <ButtonSpinner /> : "Register"}
                          </button>
                        </div>
                      </Row>
                      <hr />
                      <div className="mt-2 text-center">
                        <h5 className="font-size-14 mb-2">Register with</h5>
                        {modal_filter && (
                          <Col>
                            <div className="mb-3">
                              <Input
                                id="companyCode"
                                name="companyCode"
                                className="form-control"
                                placeholder="Enter Company Code"
                                type="text"
                                onChange={handleCompanyCode}
                                value={companyCode}
                                invalid={companyCode === ""}
                              />
                              {companyCode === "" ? (
                                <FormFeedback type="invalid">
                                  Please Enter the Company Code
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}
                        <Row className="mb-3">
                          <Col className="lg-6 sm-6 d-flex justify-content-center">
                            {!startGoogleSignIn && (
                              // <Button
                              //   variant="contained"
                              //   color="primary"
                              //   onClick={handleOpenDialog}
                              // >
                              //   Sign in with Google
                              // </Button>
                              <GoogleLogin
                                onSuccess={handleSuccess}
                                onError={handleError}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="lg-6 sm-6 mt-2 mt-sm-0">
                            <img
                              src={singpassLogo}
                              onClick={handleLogin}
                              style={{
                                cursor: "pointer",
                                width: "220px",
                                height: "40px",
                                padding: "0px 10px",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                  {startGoogleSignIn && (
                    <div className="text-center">
                      <Row>
                        <Col className="lg-6 sm-6">
                          <GoogleLogin
                            onSuccess={handleSuccess}
                            onError={handleError}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account?{" "}
                  <Link
                    to={path.login}
                    className="font-weight-medium text-primary"
                  >
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
